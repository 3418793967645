/*eslint-disable*/
import React from 'react'
import styles from './Quiz.module.css'
import { Chip, TextField } from '@mui/material'
import jaroWinkler from 'jaro-winkler';

const Result = ({question, questionsCount, questionIndex, setQuestionIndex, results, setResults}) => {
	const handleOnPrevClick = () => {
		setQuestionIndex((prevQuestionIndex) => (
			Math.max(0, prevQuestionIndex - 1)
		))
	}

	const handleOnNextClick = () => {
		setQuestionIndex((prevQuestionIndex) => (
			Math.min(questionsCount - 1, prevQuestionIndex + 1)
		))
	}

	const htmlDecode = (content) => {
		let e = document.createElement('div');
		e.innerHTML = content;
		return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
	}

	const checkIfBlankIsCorrect = (correctAnswer) => {
		const selectedAnswer = (results[questionIndex].selectedAnswers.filter(selectedAnswer => selectedAnswer.id === correctAnswer.id))[0].selectedAnswer
		var isNumeric = true;
		correctAnswer.correctAnswers.forEach((correctAnswerStatement) => {
			if (!parseFloat(correctAnswerStatement)) {
				isNumeric = false;
			}
		})
		const isBlankCorrect = isNumeric ? (
			(correctAnswer.correctAnswers.filter(correctAnswerStatement => ((Math.abs(parseFloat(correctAnswerStatement) - parseFloat(selectedAnswer))) < 0.01))).length !== 0
		) : (
			(correctAnswer.correctAnswers.filter(correctAnswerStatement => (jaroWinkler(correctAnswerStatement.toLowerCase(), (selectedAnswer).toLowerCase()) > 0.97))).length !== 0
		)
		if (isBlankCorrect) {
			return 'success'
		} else {
			return 'error'
		}
	}

    return (
		<div className={`${styles['result']}`}>
			<div className={`${styles['result-header']}`}>
				<h5 
					className={`${styles['question-nav-btn']}`}
					onClick={handleOnPrevClick}
				> 
					&lt; PREV
				</h5>
				<h3 className={`${styles['question-index']}`}>QUESTION {questionIndex + 1}</h3>
				<h5 
					className={`${styles['question-nav-btn']}`}
					onClick={handleOnNextClick}
				> 
					NEXT &gt;
				</h5>
			</div>
			<div className={`${styles['result-header']}`}>
				<h4 
					className={`${styles['result-instruction']}`}
				> 
					{
						question.type === "MCQ" ? (
							"Choose the correct options"
						) : (
							question.type === "TF" ? (
								"Choose true or false"
							) : question.type === "SAT" ? (
								"Short answer"
							) : (
								"Fill in the blanks"
							)
						)
					}
				</h4>
				
				<h4
					className={`${styles['result-points']}`}
				> 
					Points: {results[questionIndex].score} / {question.points}
				</h4>
			</div>
			<div dangerouslySetInnerHTML={{__html: (question.statement)}}></div>
			{
				question.type === "FITB" ? (
					<div className={`${styles['blank-inputs']}`}>
						{
							question.correctAnswers.map((correctAnswer, i) => {
								var isNumeric = true;
								correctAnswer.correctAnswers.forEach((correctAnswerStatement) => {
									if (!parseFloat(correctAnswerStatement)) {
										isNumeric = false;
									}
								})
								return(
									<>
										<TextField
											id={'input-' + correctAnswer.id.substring(6)}
											label={correctAnswer.id}
											onClick={()=>{}}
											value={(results[questionIndex].selectedAnswers.filter(selectedAnswer => selectedAnswer.id === correctAnswer.id))[0].selectedAnswer}
											multiline = {!isNumeric}
											type={isNumeric ? 'number' : 'text'}
											fullWidth
											rows={2}
											className={styles['result-blank-input']}
											color={
												checkIfBlankIsCorrect(correctAnswer)
											}
											focused
											spellCheck={'false'}
										/>

										<div 
											style = {{
												display: 'flex',
												gap: '0.2em',
												flexWrap: 'wrap'
											}}
										>
											Correct Answers: 
											{
												correctAnswer.correctAnswers.map(correctAnswerStatement => (
													<Chip 
														label={correctAnswerStatement}
														color='success'
													/>
												))
											}
										</div>
									</>
								)
							})
						}
					</div>
				) : question.type === "SAT" ? (
					<div className={`${styles['blank-inputs']}`}>
						<TextField
							label={"Answer"}
							onClick={()=>{}}
							value={(results[questionIndex].selectedAnswers[0])}
							multiline
							type='text'
							fullWidth
							rows={3}
							className={styles['result-blank-input']}
							color={
								results[questionIndex].isCorrect ? 'success' : 'error'
							}
							focused
							spellCheck={'false'}
						/>
					</div>
				) : (
					<div className={`${styles['result-options']}`}>
						{
							question.options.map((option, i) => (
								<div 
									key={i} 
									className={`
										${styles['result-option']}
										${results.filter(selectedAnswersObj => selectedAnswersObj.id === question.id)[0].selectedAnswers.includes(option) ? styles['selected'] : ''}
										${(question.correctAnswers.filter(correctAnswer => option.id === correctAnswer.id)).length  ?  styles['correct'] : (results.filter(selectedAnswersObj => selectedAnswersObj.id === question.id))[0].selectedAnswers.includes(option) ? styles['incorrect'] : ''}
									`}
								>
									<div 
										className={`${styles['result-option-front']}`}
									>
										<div> {String.fromCharCode('A'.charCodeAt(0) + i)} </div>
										<div> {option.optionStatement} </div>
									</div>
								</div>
							))
						}
					</div>
				)
			}
			{
				question.explanation !== '' &&
				<div className={`${styles['explanation']}`}>
					<h5>Explanation:</h5> 
					{question.explanation}
				</div>
			}
		</div>
	)
}

export default Result