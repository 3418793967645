import axios from 'axios'
// import openai from './helpers/openai';
import {
    VIRTUAL_TA_ASK_QUESTION_REQUEST,
    VIRTUAL_TA_ASK_QUESTION_SUCCESS,
    VIRTUAL_TA_ASK_QUESTION_FAILURE,
    VIRTUAL_TA_GET_ALL_QUESTIONS_REQUEST,
    VIRTUAL_TA_GET_ALL_QUESTIONS_SUCCESS,
    VIRTUAL_TA_GET_ALL_QUESTIONS_FAILURE,
    SET_QUESTION,
    SET_CONVERSATION_HISTORY,
    SET_WEAK_CONCEPTS,
} from './virtualTAActionTypes'

export const askQuestionRequest = () => ({
    type: VIRTUAL_TA_ASK_QUESTION_REQUEST,
})

export const askQuestionSuccess = (question, courseId) => ({
    type: VIRTUAL_TA_ASK_QUESTION_SUCCESS,
    payload: { question, courseId },
})

export const askQuestionFailure = (errorMsg) => ({
    type: VIRTUAL_TA_ASK_QUESTION_FAILURE,
    payload: errorMsg,
})

export const getAllQuestionsRequest = () => ({
    type: VIRTUAL_TA_GET_ALL_QUESTIONS_REQUEST,
})

export const getAllQuestionsSuccess = (questions) => ({
    type: VIRTUAL_TA_GET_ALL_QUESTIONS_SUCCESS,
    payload: questions,
})

export const getAllQuestionsFailure = (errorMsg) => ({
    type: VIRTUAL_TA_GET_ALL_QUESTIONS_FAILURE,
    payload: errorMsg,
})

export const setQuestion = (question) => ({
    type: SET_QUESTION,
    payload: question,
})

export const setWeakConcepts = (weakConcepts) => ({
    type: SET_WEAK_CONCEPTS,
    payload: weakConcepts,
})

export const setConversationHistory = (history) => ({
    type: SET_CONVERSATION_HISTORY,
    payload: history,
})

export const askQuestion =
    (
        userId,
        courseId,
        questionData,
        answer,
        videoLinks,
        videoTitles,
        videoDescriptions
    ) =>
    async (dispatch, getState) => {
        dispatch(askQuestionRequest())

        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/virtual-ta/ask-question`,
                {
                    userId,
                    courseId,
                    question: questionData,
                    answer,
                    videoLinks,
                    videoTitles,
                    videoDescriptions,
                }
            )

            const { question } = data
            const {
                virtualTA: { conversationHistory },
            } = getState()

            dispatch(
                setConversationHistory([
                    ...conversationHistory,
                    {
                        id: `question-${conversationHistory.length}`,
                        text: questionData,
                    },
                    {
                        id: `answer-${conversationHistory.length}`,
                        text: answer,
                    },
                ])
            )

            dispatch(askQuestionSuccess(question, courseId))
        } catch (error) {
            dispatch(
                askQuestionFailure(
                    (error.response && error.response.data) ||
                        'Asking question to Virtual TA failed'
                )
            )
        }
    }

export const getAllQuestionsAndAnswers = (courseId) => async (dispatch) => {
    dispatch(getAllQuestionsRequest())
    console.log('Test')
    // console.log(str(courseId))
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/virtual-ta/questions-answers`,
            {
                params: { courseId },
            }
        )
        const questions = response.data
        console.log('Abc')
        console.log(questions)
        dispatch(getAllQuestionsSuccess(questions))
    } catch (error) {
        dispatch(
            getAllQuestionsFailure(
                (error.response && error.response.data) ||
                    'Fetching all questions and answers from Virtual TA failed'
            )
        )
    }
}

export default {
    askQuestionRequest,
    askQuestionSuccess,
    askQuestionFailure,
    getAllQuestionsRequest,
    getAllQuestionsSuccess,
    getAllQuestionsFailure,
    setQuestion,
    setConversationHistory,
    askQuestion,
    getAllQuestionsAndAnswers,
}
