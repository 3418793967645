/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Divider, Fab, Skeleton, Tab, Tabs, Tooltip } from '@mui/material';
import globalStyles from '../../css/Page.module.css';
import styles from './Courses.module.css';
import Announcements from './tabs/announcements/Announcements';
import Modules from './tabs/Modules';
import Quizzes from './tabs/Quizzes';
import Assignments from './tabs/Assignments';
import People from './tabs/People';
import Syllabus from './tabs/syllabus/Syllabus';
import GradeAnalyzer from './tabs/GradeAnalyzer';
import SettingsIcon from '@mui/icons-material/Settings';

const Course = () => {
    const [course, setCourse] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const tabs = ["Announcements", "Syllabus", "Modules", "Assignments", "Quizzes", "Grade Analyzer", "People", "Settings"];
    const tabComponents = [
        <Announcements isLoading={isLoading} />, 
        <Syllabus isLoading={isLoading} />, 
        <Modules isLoading={isLoading} />, 
        <Assignments isLoading={isLoading} />, 
        <Quizzes isLoading={isLoading} />, 
        <GradeAnalyzer isLoading={isLoading} />, 
        <People isLoading={isLoading} />, 
    ];
    const [activeTab, setActiveTab] = useState(JSON.parse(localStorage.getItem("activeTab")) || 0);

    const handleChange = (event, value) => {
        setActiveTab(value);
        localStorage.setItem("activeTab", JSON.stringify(value));
    };

    const getCourse = () => {
        setTimeout(() => {
            // GET API Call
            setCourse({
                id: 1,
                name: "Web Development"
            })
            setIsLoading(false);
        }, 5000);
    }

    useEffect(() => {
        getCourse();
    }, [])

    return (    
		<div className={`${globalStyles['main-container']} ${styles['main-container']}`}>
			<div className={`${globalStyles['main-heading']}`}>
				{isLoading ? <Skeleton className={`${globalStyles['main-heading-skeleton']}`} variant='text' /> : course.name}
                <Tooltip title="Settings">
					<Fab aria-label="add" color="primary" disabled={isLoading}>
						<SettingsIcon />
					</Fab>
				</Tooltip>
			</div>
            <div className={`${styles['tabs']}`}>
                <Tabs variant="scrollable" scrollButtons="auto" value={activeTab} onChange={handleChange} aria-label="basic tabs example">
                    {
                        tabs.map((tab, ind) => (
                            <Tab className={`${styles['tab']}`} key={ind} label={tab} />
                        ))
                    }
                </Tabs>
            </div>
			<Divider />
			<div className={`${styles['course-tab-container']}`}>
                {
                    tabComponents[activeTab]
                }
			</div>
		</div>
	)
}

export default Course