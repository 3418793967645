import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { clearErrorForKey, resetPassword, setErrorForKey } from '../../redux'

function GenerateResetPasswordForm({ token }) {
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const resetPasswordData = useSelector((state) => state.resetPasswordData)
    const errorData = useSelector((state) => state.errorData)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    useEffect(
        () => () => {
            dispatch(clearErrorForKey('password'))
            dispatch(clearErrorForKey('confirmPassword'))
        },
        [dispatch]
    )

    const onPasswordChange = (updatedPassword) => {
        let errorMessage = null
        if (updatedPassword.length < 6)
            errorMessage = 'Password length should be atleast 6'
        if (errorData.confirmPassword !== undefined)
            dispatch(
                setErrorForKey(
                    'confirmPassword',
                    confirmPassword === updatedPassword
                        ? null
                        : "Passwords don't match"
                )
            )
        dispatch(setErrorForKey('password', errorMessage))
        setPassword(updatedPassword)
    }

    const onConfirmPasswordChange = (updatedConfirmPassword) => {
        let errorMessage = null
        if (updatedConfirmPassword !== password)
            errorMessage = "Passwords don't match"
        dispatch(setErrorForKey('confirmPassword', errorMessage))
        setConfirmPassword(updatedConfirmPassword)
    }

    const isFormValid = () =>
        errorData.password === null && errorData.confirmPassword === null

    const passwordInputDomId = 'password'
    const confirmPasswordInputDomId = 'confirm-password'
    return (
        <form>
            <div className="form-group">
                <label className="input-max-400" htmlFor={passwordInputDomId}>
                    Password
                    <input
                        className={`form-control ${
                            // eslint-disable-next-line no-nested-ternary
                            errorData.password === undefined
                                ? ''
                                : errorData.password
                                ? 'form-input-invalid'
                                : 'form-input-valid'
                        }`}
                        id={passwordInputDomId}
                        maxLength={20}
                        onChange={(e) => onPasswordChange(e.target.value)}
                        type="password"
                        value={password}
                    />
                </label>
            </div>
            <div className="form-group">
                <label
                    className="input-max-400"
                    htmlFor={confirmPasswordInputDomId}
                >
                    Confirm Password
                    <input
                        className={`form-control ${
                            // eslint-disable-next-line no-nested-ternary
                            errorData.confirmPassword === undefined
                                ? ''
                                : errorData.confirmPassword
                                ? 'form-input-invalid'
                                : 'form-input-valid'
                        }`}
                        id={confirmPasswordInputDomId}
                        maxLength={20}
                        onChange={(e) =>
                            onConfirmPasswordChange(e.target.value)
                        }
                        type="password"
                        value={confirmPassword}
                    />
                </label>
            </div>
            <button
                className="btn btn-primary"
                disabled={
                    userData.loading ||
                    resetPasswordData.loading ||
                    !isFormValid()
                }
                onClick={() => dispatch(resetPassword(token || '', password))}
                type="submit"
            >
                Update Password
            </button>
        </form>
    )
}

GenerateResetPasswordForm.propTypes = {
    token: PropTypes.string,
}

GenerateResetPasswordForm.defaultProps = {
    token: '',
}

export default GenerateResetPasswordForm
