import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import RegisterForm from './forms/RegisterForm'
import {
    userRegisterInit as userRegisternitRedux,
    userRegisterExit as userRegisterExitRedux,
} from '../redux'
import TopLoader from './commons/TopLoader'

function Register({ userData, userRegisterInit, userRegisterExit }) {
    useEffect(() => {
        window.scrollTo(0, 0)
        userRegisterInit()
        return userRegisterExit
    }, [userRegisterInit, userRegisterExit])
    if (userData && userData.user && userData.user.id !== undefined) {
        return <Redirect to="/" />
    }
    return (
        <>
            {userData.loading ? (
                <TopLoader message={userData.loadingMessage} />
            ) : (
                ''
            )}
            <div className="container main-container mt-4 mb-5">
                <div className="card">
                    <div className="card-body">
                        <RegisterForm />
                    </div>
                </div>
                {userData.error ? (
                    <h4 className="alert alert-danger mt-4">
                        {userData.error}
                    </h4>
                ) : (
                    ''
                )}
                {userData.message ? (
                    <h4 className="alert alert-success mt-4">
                        {userData.message}
                    </h4>
                ) : (
                    ''
                )}
            </div>
        </>
    )
}

Register.propTypes = {
    userData: PropTypes.shape({
        loading: PropTypes.bool,
        loadingMessage: PropTypes.string,
        error: PropTypes.string,
        message: PropTypes.string,
        user: PropTypes.shape({
            id: PropTypes.string,
        }),
    }).isRequired,
    userRegisterInit: PropTypes.func.isRequired,
    userRegisterExit: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    userData: state.userData,
})

const mapDispatchToProps = (dispatch) => ({
    userRegisterInit: () => dispatch(userRegisternitRedux()),
    userRegisterExit: () => dispatch(userRegisterExitRedux()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Register)
