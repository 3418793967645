/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'
import { Stack, Menu, MenuItem, Box, Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import TopLoader from '../commons/TopLoader'
import { fetchQuizMetas, updateQuiz, fetchQuiz } from '../../redux'
import '../../css/Card.css'
import CanvasEditQuiz from '../canvas/CanvasEditQuizQuestions'

function QuizList() {
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const quizMetasData = useSelector((state) => state.quizMetaData)
    const quizData = useSelector((state) => state.quizData)
    console.log(quizData, 'hello')
    const { category: quizCategory } = useParams()
    const [flag, setFlag] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [selectedQuiz, setSelectedQuiz] = useState(null)
    const [showCanvasQuiz, setShowCanvasQuiz] = useState(false)
    const [questions, setQuestions] = useState([])
    const [quizDetails, setQuizDetails] = useState([])
    const [quiz, setQuiz] = useState({})
    const idRef = useRef(null)

    const handleMenuOpen = (event, category) => {
        setAnchorEl(event.currentTarget)
        setSelectedQuiz(category)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        setSelectedQuiz(null)
    }
    const handlePublish = (id, publish) => {
        dispatch(updateQuiz({ id, quizCategory, publish }))
        setFlag(!flag)
        handleMenuClose()
    }
    const generateRandomId = () => {
        return Math.random().toString(36).substr(2, 10)
    }
    const convertToQuestionModel = (questions, id) => {
        return questions.map((questionData) => {
            const questionId = generateRandomId()
            const {
                categoryId,
                questionPic,
                questionType,
                answerSelectionType,
                answers,
                question,
                correctAnswer,
                point,
                concepts,
                difficulty,
            } = questionData

            console.log(questionData, '--sjd')
            const convertedAnswers = answers.map((answerData, index) => {
                const weight = correctAnswer.includes(index + 1) ? 100 : 0
                const answerId = generateRandomId()

                return {
                    _id: answerId,
                    text: answerData,
                    weight: weight,
                }
            })

            return {
                _id: questionId,
                categoryId: categoryId,
                questionType: questionType,
                question_type:
                    answerSelectionType === 'single'
                        ? 'fill_in_the_blanks_question'
                        : 'multiple_choice_question',
                answers: convertedAnswers,
                question: question,
                correctAnswer: correctAnswer,
                point: point,
                concepts: concepts,
                difficulty: difficulty,
            }
        })
    }

    const handleEdit = (id) => {
        idRef.current = id
        handleMenuClose()
        dispatch(fetchQuiz(id))
    }
    useEffect(() => {
        const id = idRef.current
        if (quizData.quiz) {
            setQuiz(quizData.quiz)
            console.log(quizData.quiz)
            setQuizDetails(quizData.quiz.data)

            const convertedQuestions = convertToQuestionModel(
                quizData.quiz.data.questions,
                id
            )
            setQuestions(convertedQuestions)
            setShowCanvasQuiz(true)
        } else {
            setShowCanvasQuiz(false)
        }
    }, [quizData])

    const handleDelete = (id, deleted) => {
        dispatch(updateQuiz({ id, quizCategory, deleted }))
        setFlag(!flag)
        handleMenuClose()
    }

    const renderMenu = (id, isPublished) => (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => handlePublish(id, !isPublished)}>
                {isPublished ? 'Unpublish' : 'Publish'}
            </MenuItem>
            <MenuItem onClick={() => handleEdit(id)}>Edit</MenuItem>
            <MenuItem onClick={() => handleDelete(id, true)}>Delete</MenuItem>
        </Menu>
    )
    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            dispatch(fetchQuizMetas(quizCategory))
        }
    }, [
        dispatch,
        userData.userFetchCompleted,
        userData.user,
        quizCategory,
        flag,
    ])
    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }
    if (quizMetasData.loading) {
        return (
            <div className="container main-container mb-5">
                <TopLoader message={quizMetasData.loadingMessage} />
            </div>
        )
    }
    if (quizMetasData.error) {
        return (
            <div className="container main-container">
                <h4 className="alert alert-danger mt-4">
                    {quizMetasData.error}
                </h4>
            </div>
        )
    }
    if (quizMetasData.quizMetas.length === 0) {
        return (
            <div className="container main-container">
                <h4 className="alert alert-light mt-4">
                    No quiz available currently.
                </h4>
            </div>
        )
    }

    const quizCards = quizMetasData.quizMetas.map((quizMeta) => {
        const { title, synopsis, id, isPublished } = quizMeta
        const synopsisTrimmed =
            synopsis.length > 83
                ? `${synopsis.substring(0, 80).trim()}...`
                : synopsis
        return userData.user.role !== 'Instructor' && !isPublished ? null : (
            <div key={title.substring(0, 10)} className="basic-card card">
                {/* <img class="card-img-top" src="..." alt="Card image cap"/> */}
                <div className="card-body meta-container">
                    {userData.user.role === 'Instructor' ? (
                        <Stack display="flex" alignItems="flex-end">
                            <MoreVertIcon
                                className="menu-icon"
                                onClick={(event) => handleMenuOpen(event, id)}
                            />
                        </Stack>
                    ) : null}
                    <h5 className="card-title">{title}</h5>
                    <h5 className="card-title">{quizCategory}</h5>
                    <p className="card-text">{synopsisTrimmed}</p>
                    {isPublished ? (
                        <a
                            className="btn btn-primary"
                            href={`/quiz/${quizMeta.id}`}
                        >
                            Take the quiz
                        </a>
                    ) : (
                        <Box
                            border={'1px solid black'}
                            padding={'0.5rem'}
                            borderRadius={'0.3rem'}
                        >
                            <Typography textAlign={'center'}>
                                Quiz Not Published
                            </Typography>
                        </Box>
                    )}
                </div>
                {selectedQuiz === id && renderMenu(id, isPublished)}
            </div>
        )
    })
    const isStudent = userData.user.role === 'Student'
    const noPublishedQuizzes = quizMetasData.quizMetas.every(
        (quizMeta) => !quizMeta.isPublished
    )
    return (
        <Stack mb={'15rem'}>
            <div
                className="container row"
                style={{ margin: 'auto', marginBottom: '3rem' }}
            >
                {isStudent && noPublishedQuizzes && (
                    <div className="container main-container">
                        <h4 className="alert alert-info mt-4">
                            No published quizzes available currently.
                        </h4>
                    </div>
                )}
                {quizCards}
            </div>
            {showCanvasQuiz && (
                <Stack marginBottom={'30rem'}>
                    <Typography textAlign={'center'} mb={'3rem'} variant="h4">
                        {quizDetails.quizTitle}
                    </Typography>
                    {
                        <CanvasEditQuiz
                            ques={questions}
                            quizDetails={quizDetails}
                            quizCategories={quiz.category}
                            id={quiz.id}
                        />
                    }
                </Stack>
            )}
        </Stack>
    )
}

export default QuizList
