export const CANVAS_MODULES_META_REQUEST = 'CANVAS_MODULES_META_REQUEST'
export const CANVAS_MODULES_META_SUCCESS = 'CANVAS_MODULES_META_SUCCESS'
export const CANVAS_MODULES_META_FAILURE = 'CANVAS_MODULES_META_FAILURE'

export const CANVAS_MODULE_ITEMS_META_REQUEST =
    'CANVAS_MODULE_ITEMS_META_REQUEST'
export const CANVAS_MODULE_ITEMS_META_SUCCESS =
    'CANVAS_MODULE_ITEMS_META_SUCCESS'
export const CANVAS_MODULE_ITEMS_META_FAILURE =
    'CANVAS_MODULE_ITEMS_META_FAILURE'
