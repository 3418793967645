/* eslint-disable */
import { React } from 'react'
import './courses/CoursesHome/Tabs/styletab.css'
import Avatar from '@mui/material/Avatar';
import StudentDetailsModal from './courses/CoursesHome/Tabs/StudentDetailsModal'
import StudentMenu from './courses/CoursesHome/Tabs/StudentMenu'
// import Stack from '@mui/material/Stack';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { deepOrange, deepPurple } from '@mui/material/colors';
import Button from '@mui/material/Button';
import LandingPage from './LandingPage';
import { getRequests } from '../redux/Admin/AdminActions';
import AddStudentModal from './courses/CoursesHome/Tabs/AddStudentModal';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { sendData } from '../redux/search/searchActions';
import RequestList from'./RequestList';
// eslint-disable
const getAllRequests = (dispatch) => {
  dispatch(getRequests());
}

const searchEmail = (dispatch, email) => {
  dispatch(sendData(email));
}
var people = [];
const handleClick = async (dispatch) => {
  getAllRequests(dispatch);
}

// const handleAdmindata = (dispatch, adminData) => {
//   if (adminData.users.length > 0) {
//     for (var i = 0; i < adminData.users.length; i++) {
//       if (adminData.users[i] && adminData.users[i].email) console.log(adminData.users[i].email);
//       if (adminData.users[i] && adminData.users[i].email) searchEmail(dispatch, adminData.users[i].email);
//       const searchData = useSelector((state) => state.searchData);
//       console.log(searchData);
//       if (searchData && searchData.user) {
//         console.log(searchData.user);
//         people.push(searchData.user);
//       }
//     }
//   }
// }

export default function Requests() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  console.log("hi");
  var users = [];
  // getAllRequests(dispatch);
  useEffect(() => {
    handleClick(dispatch);
  }, []);
  const adminData = useSelector((state) => state.adminData);
  if (adminData.users) {
    users = adminData.users;
    console.log(adminData.users);
  }
  // console.log("Checking users in react");
  // console.log(users);
  // console.log(adminData.users.length);
  if(users.length > 0) people = users;
  // for (var i = 0; i < users.length; i++) {
  //   if (users && users[i]) {

  //   }
  // }
  // handleAdmindata(dispatch, adminData)
  // const searchData = useSelector((state) => state.searchData);
  // if(searchData && searchData.user) console.log(searchData);

  function showAlert(e) {
    alert(e);
  }
  // const people = [{ role: 'Student', name: 'Sounak Ghosh' }, { role: 'Student', name: 'Lionel Messi' }, { role: 'Student', name: 'Sushmit Dasgupta' }, { role: 'Student', name: 'Cristiano Ronaldo' }]
  const user = userData && userData.user;
  return (user ?
    <>
    <RequestList users = {people}/></>
     :
    <LandingPage />
  )
}
/* eslint-disable */