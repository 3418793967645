import {
    USER_VERIFICATION_FAILURE,
    USER_VERIFICATION_REQUEST,
    USER_VERIFICATION_SUCCESS,
} from './userVerificationTypes'

const initialState = {
    loading: false,
    loadingMessage: '',
    verificationDone: false, // Verification process done, irrespective of success or failure
    verified: false,
    message: '',
    error: '',
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case USER_VERIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
                loadingMessage: 'Verifying the user...',
            }
        case USER_VERIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                verificationDone: true,
                verified: true,
                message: 'User verified! You can login now',
                error: '',
            }
        case USER_VERIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                verificationDone: true,
                verified: false,
                message: '',
                error: action.payload,
            }
        default:
            return state
    }
}

export default reducer
