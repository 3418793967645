import {
    CLEAR_ERROR_FOR_COURSE_CATEGORY_INDEX,
    CLEAR_ERROR_FOR_COURSE_CATEGORY_ITEM_INDEX,
    CLEAR_ERROR_FOR_KEY,
    SET_ERROR_FOR_COURSE_CATEGORY_ITEM_KEY_AT_INDEX,
    SET_ERROR_FOR_COURSE_CATEGORY_KEY_AT_INDEX,
    SET_ERROR_FOR_KEY,
} from './errorTypes'

const initialState = {
    categoriesErrors: [],
}

const setErrorForCourseCategoryKeyAtIndex = (
    state,
    categoryIndex,
    errorKey,
    errorMessage
) => {
    const newCategoriesErrors = state.categoriesErrors
    if (!newCategoriesErrors[categoryIndex]) {
        newCategoriesErrors[categoryIndex] = {
            [errorKey]: errorMessage,
        }
    } else {
        const updatedCategoryErrors = {
            ...newCategoriesErrors[categoryIndex],
            [errorKey]: errorMessage,
        }
        newCategoriesErrors.splice(categoryIndex, 1, updatedCategoryErrors)
    }
    return {
        ...state,
        categoriesErrors: newCategoriesErrors,
    }
}

const setErrorForCourseCategoryItemKeyAtIndex = (
    state,
    categoryIndex,
    itemIndex,
    errorKey,
    errorMessage
) => {
    const newCategoriesErrors = state.categoriesErrors
    if (!newCategoriesErrors[categoryIndex]) {
        newCategoriesErrors[categoryIndex] = {}
    }
    if (!newCategoriesErrors[categoryIndex].items) {
        newCategoriesErrors[categoryIndex].items = []
    }
    if (!newCategoriesErrors[categoryIndex].items[itemIndex]) {
        newCategoriesErrors[categoryIndex].items[itemIndex] = {}
    }
    newCategoriesErrors[categoryIndex].items[itemIndex][errorKey] = errorMessage
    return {
        ...state,
        categoriesErrors: newCategoriesErrors,
    }
}

const clearErrorForCourseCategoryIndex = (state, categoryIndex) => {
    const updatedCategoriesErrors = state.categoriesErrors
    updatedCategoriesErrors.splice(categoryIndex, 1)
    return {
        ...state,
        categoriesErrors: updatedCategoriesErrors,
    }
}

const clearErrorForCourseCategoryItemIndex = (
    state,
    categoryIndex,
    itemIndex
) => {
    const updatedCategoriesErrors = state.categoriesErrors
    if (
        updatedCategoriesErrors[categoryIndex] &&
        updatedCategoriesErrors[categoryIndex].items
    ) {
        updatedCategoriesErrors[categoryIndex].items.splice(itemIndex, 1)
    }
    return {
        ...state,
        categoriesErrors: updatedCategoriesErrors,
    }
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_ERROR_FOR_KEY:
            return {
                ...state,
                [action.payload[0]]: action.payload[1],
            }
        case CLEAR_ERROR_FOR_KEY: {
            const updatedState = { ...state }
            delete updatedState[action.payload]
            return updatedState
        }
        case SET_ERROR_FOR_COURSE_CATEGORY_KEY_AT_INDEX:
            return setErrorForCourseCategoryKeyAtIndex(
                state,
                action.payload[0],
                action.payload[1],
                action.payload[2]
            )
        case CLEAR_ERROR_FOR_COURSE_CATEGORY_INDEX:
            return clearErrorForCourseCategoryIndex(state, action.payload)
        case SET_ERROR_FOR_COURSE_CATEGORY_ITEM_KEY_AT_INDEX:
            return setErrorForCourseCategoryItemKeyAtIndex(
                state,
                action.payload[0],
                action.payload[1],
                action.payload[2],
                action.payload[3]
            )
        case CLEAR_ERROR_FOR_COURSE_CATEGORY_ITEM_INDEX:
            return clearErrorForCourseCategoryItemIndex(
                state,
                action.payload[0],
                action.payload[1]
            )
        default:
            return state
    }
}

export default reducer
