import axios from 'axios'
import {
    QUIZ_CATEGORY_META_FAILURE,
    QUIZ_CATEGORY_META_REQUEST,
    QUIZ_CATEGORY_META_SUCCESS,
} from './quizCategoryTypes'

export const quizCategoryMetaRequest = () => ({
    type: QUIZ_CATEGORY_META_REQUEST,
})

export const quizCategoryMetaSuccess = (quizMetas) => ({
    type: QUIZ_CATEGORY_META_SUCCESS,
    payload: quizMetas,
})

export const quizCategoryMetaFailure = (errorMsg) => ({
    type: QUIZ_CATEGORY_META_FAILURE,
    payload: errorMsg,
})

export const fetchQuizCategoryMetas = () => (dispatch) => {
    dispatch(quizCategoryMetaRequest())
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/quiz/category/meta`)
        .then((res) => {
            const quizCategoryMetas = res.data
            if (!quizCategoryMetas) {
                dispatch(
                    quizCategoryMetaFailure('No quiz categories available')
                )
            } else {
                dispatch(quizCategoryMetaSuccess(quizCategoryMetas))
            }
        })
        .catch((err) => {
            dispatch(
                quizCategoryMetaFailure(
                    (err.response && err.response.data) ||
                        'Error fetching active quiz categories'
                )
            )
        })
}

export const saveQuizCategory = (category) => (dispatch) => {
    dispatch(quizCategoryMetaRequest())
    axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/quiz/category`,
        data: { category },
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            const quizCategory = response.data
            dispatch(quizCategoryMetaSuccess(quizCategory))
        })
        .catch((err) => {
            dispatch(
                quizCategoryMetaFailure(
                    (err.response && err.response.data) ||
                        'Error adding quiz category'
                )
            )
        })
}
