/*eslint-disable*/
import { TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import React, { useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './CreateQuiz.module.css'
import { Tooltip } from '@mui/material';

const NewOption = ({option, onChange, isValid, setIsValid, contentEditable, deletable, deleteOption, isNew, selected, handleChange, onClick}) => {
    return (
        <div
            className={`${styles['option']} ${contentEditable ? styles['editable'] : ''} ${selected ? styles['selected'] : ''} ${isValid ? '' : styles['error']}`}
            onClick={()=>{setIsValid(true); onClick(option.id);}}
        >
            {
                !isNew &&
                <Tooltip title="Mark as correct">
                    <Checkbox
                        checked={selected}
                        onChange={(e)=>{
                            setIsValid(true);
                            e.preventDefault();
                            e.stopPropagation();
                            handleChange(option.id);
                        }}
                        onClick={(e)=>{
                            setIsValid(true);
                            e.preventDefault();
                            e.stopPropagation();
                            handleChange(option.id);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                        color="success"
                    />
                </Tooltip>
            }

            {
                contentEditable ? (
                    <TextField
                        fullWidth
                        id={'option-text' + option.id}
                        onChange={(e) => {
                            if (contentEditable) {
                                e.preventDefault();
                                e.stopPropagation();
                                onChange(e.target.value);
                            }  
                        }}
                        onClick={(e)=>{
                            e.preventDefault();
                            e.stopPropagation();
                            if (contentEditable) {
                                e.target.select();
                            }
                        }}
                        onFocus={(e)=>{
                            if (contentEditable) {
                                e.target.select();
                            }
                        }}
                        onBlur={(e) => {
                            if (contentEditable) {
                                e.preventDefault();
                                e.stopPropagation();
                                onChange(e.target.value === '' ? 'Option' : e.target.value);
                            }  
                        }}
                        value={option.optionStatement}
                        sx={{
                            WebkitTextFillColor: "black"
                        }}
                        variant="standard"
                    />
                ) : (
                    <div
                        id="new-option"
                        className={`${styles['option-text']}`}
                    > 
                        {option.optionStatement}
                    </div>
                ) 
            }

            {
                contentEditable && deletable &&
                <DeleteIcon
                    onClick={(e)=>{
                        e.preventDefault();
                        e.stopPropagation();
                        deleteOption(option.id);
                    }} 
                />
            }
        </div>
    );
}

export default NewOption