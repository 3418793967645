import axios from 'axios'
import {
    SAVED_COURSES_META_FAILURE,
    SAVED_COURSES_META_REQUEST,
    SAVED_COURSES_META_SUCCESS,
} from './savedCourseTypes'

export const savedCoursesMetaRequest = () => ({
    type: SAVED_COURSES_META_REQUEST,
})

export const savedCoursesMetaSuccess = (savedCoursesMetas) => ({
    type: SAVED_COURSES_META_SUCCESS,
    payload: savedCoursesMetas,
})

export const savedCoursesMetaFailure = (errorMsg) => ({
    type: SAVED_COURSES_META_FAILURE,
    payload: errorMsg,
})

export const fetchSavedCoursesMeta = () => (dispatch) => {
    dispatch(savedCoursesMetaRequest())
    const url = `${process.env.REACT_APP_BASE_URL}/courses/meta`
    axios
        .get(url)
        .then((res) => {
            const savedCoursesMetas = res.data
            console.log("checking the metadata");
            console.log(res);
            if (!savedCoursesMetas) {
                dispatch(savedCoursesMetaFailure('Saved courses not available'))
            } else {
                savedCoursesMetas.forEach((savedCourse) => {
                    savedCourse.lastUpdatedOn = new Date(
                        savedCourse.lastUpdatedOn
                    )
                })
                savedCoursesMetas.sort(
                    (sc1, sc2) => sc2.lastUpdatedOn - sc1.lastUpdatedOn
                )
                dispatch(savedCoursesMetaSuccess(savedCoursesMetas))
            }
        })
        .catch((err) => {
            dispatch(
                savedCoursesMetaFailure(
                    (err.response && err.response.data) ||
                        'Error fetching saved courses'
                )
            )
        })
}
