/*eslint-disable*/
import { Command } from 'ckeditor5';

export default class InsertInputCommand extends Command {
    execute() {
        this.editor.model.change( writer => {
            // Insert <simpleBox>*</simpleBox> at the current selection position
            // in a way that will result in creating a valid model structure.
            const data = this.editor.getData();
            console.log(this.editor)
            this.editor.model.insertObject( createInput( writer, this.editor ) );
            // const ind = data.lastIndexOf('<input');
            // const newData = data.substring(0, ind + 6) + ' type="text" placeholder="' + (data.split('<input').length - 1) + '"' + data.substring(ind + 6)

            // console.log(data)
            // console.log(newData)
            // this.editor.setData(newData)
            // console.log(this.editor.setData())
        } );
    }

    refresh() {
        const model = this.editor.model;
        const selection = model.document.selection;
        const allowedIn = model.schema.findAllowedParent( selection.getFirstPosition(), 'input' );

        this.isEnabled = allowedIn !== null;
    }
}

function createInput( writer, editor ) {
    var input;
    editor.config._config.setInputId((prevInputId) => {
        input = writer.createElement('input', {
            placeholder: 'Blank-'+(prevInputId), 
            id: 'Blank-' + (prevInputId)
        }); 
        return prevInputId + 1;
    })
    return input;
}