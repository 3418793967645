/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getRequests } from '../../redux/Admin/AdminActions'
import { Link, NavLink } from 'react-router-dom'
import {
    AppBar,
    Avatar,
    Drawer,
    IconButton,
    MenuItem,
    Toolbar,
} from '@mui/material'
import Button from '@mui/material/Button'
import { Menu as MenuIcon } from '@mui/icons-material'
import { logout } from '../../redux'
// import { fetchCanvasAccessData } from '../../redux'
import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant'
import Notifications from './Notifications'
import TopLoader from '../commons/TopLoader'
import styles from '../../css/NavBar.module.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function NavBar() {
    const dispatch = useDispatch()
    const history = useHistory();
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 900)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [scrolledDown, setScrolledDown] = useState(false)
    const boolValue = useSelector(state => state.bool.boolValue);
    const userLoggedIn = useSelector(
        (state) =>
            state.userData &&
            state.userData.user &&
            state.userData.user.id !== undefined
    )
    const userData = useSelector((state) => state.userData)
    const isAdminAccount = userData && userData.user && userData.user.email === 'myacademic.org@gmail.com'
    const isFetchingUser = useSelector(
        (state) => state.userData && state.userData.loading
    )
    // const canvasAccessData = useSelector((state) => state.canvasAccessData)
    // useEffect(() => {
    //     window.scrollTo(0, 0)
    //     // console.log('This useEffect', userData)
    //     if (
    //         userData.userFetchCompleted &&
    //         userData.user &&
    //         userData.user.id !== undefined
    //     ) {
    //         dispatch(fetchCanvasAccessData(userData.user.id))
    //     }
    // }, [dispatch, userData.userFetchCompleted, userData.user])

    function handleWindowSizeChange() {
        setIsMobileView(window.innerWidth < 900)
    }

    useEffect(() => {
        const handleScroll = (event) => {
            if (
                event.srcElement.body.scrollTop > 50 ||
                event.srcElement.documentElement.scrollTop > 50
            ) {
                setScrolledDown(true)
            } else {
                setScrolledDown(false)
            }
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    const getDrawerChoices = () => {
        if (isFetchingUser) {
            return (
                <MenuItem
                    sx={{
                        backgroundColor: 'inherit',
                        color: '#fff',
                    }}
                >
                    Loading User...
                </MenuItem>
            )
        }

        if (!userLoggedIn) {
            return (
                <NavLink onClick={() => setIsDrawerOpen(false)} to="/login">
                    <MenuItem
                        sx={{
                            backgroundColor: 'inherit',
                            color: '#fff',
                        }}
                    >
                        Login
                    </MenuItem>
                </NavLink>
            )
        }
        var choices = []
        // console.log(canvasAccessData)
        userData &&
        userData.user &&
        userData.user.id &&
        isAdminAccount
            ? (choices = [
                {label: 'Manage Users',url:'/manage-users'},
                  
                  { label: 'Manage Question Bank', url: '/manageQuestions' },

                  // Modifications
                  //   { label: 'Quiz', url: '/quiz/categories' },
                  { label: 'My Account', url: '/myaccount' },
                  {
                      label: 'Logout',
                      url: '#',
                      onClick: () => {
                          setIsDrawerOpen(false)
                          dispatch(logout())
                          window.location.reload()
                          
                      },
                  },
              ].map(({ label, url, onClick }) => (
                  <NavLink
                      reloadDocument
                      key={label}
                      onClick={() => setIsDrawerOpen(false)}
                      to={url}
                  >
                      <MenuItem
                          className="mt-2 mb-2"
                          onClick={onClick || (() => {})}
                          sx={{
                              backgroundColor: 'inherit',
                              color: '#fff',
                              fontSize: '1.1rem',
                          }}
                      >
                          {label}
                      </MenuItem>
                  </NavLink>
              )))
            : (choices = [
                  //   canvasAccessData?.canvasAccessData?._doc?.hasCanvasAccess &&
                  //   userData.user.canvasAccess
                  //       ? { label: 'Canvas', url: '/canvas/courses' }
                  //       : userData.user.canvasAccess
                  //       ? {
                  //             label: 'Request Canvas Access',
                  //             url: '/canvas',
                  //         }
                  //       : {
                  //             label: 'Home',
                  //             url: '/',
                  //         },
                  { label: 'My Courses', url: '/courses' },
                  
                  //   { label: 'Quiz', url: '/quiz/categories' },
                  // Modifications

                  //   canvasAccessData?.canvasAccessData?._doc?.hasCanvasAccess
                  //       ? //   false
                  //         //   true
                  //         { label: 'Canvas', url: '/canvas/courses' }
                  //       : {
                  //             label: 'Request Canvas Access',
                  //             url: '/canvas',
                  //         },
                  { label: 'My Account', url: '/myaccount' },
                  {
                      label: 'Logout',
                      url: '#',
                      onClick: () => {

                          setIsDrawerOpen(false)
                          dispatch(logout())
                          window.location.reload()
                      },
                  },
              ].map(({ label, url, onClick }) => (
                  <NavLink
                      reloadDocument
                      key={label}
                      onClick={() => setIsDrawerOpen(false)}
                      to={url}
                  >
                      <MenuItem
                          className="mt-2 mb-2"
                          onClick={onClick || (() => {})}
                          sx={{
                              backgroundColor: 'inherit',
                              color: '#fff',
                              fontSize: '1.1rem',
                          }}
                      >
                          {label}
                      </MenuItem>
                  </NavLink>
              )))
        if (isMobileView) {
            choices.unshift(
                <MenuItem>
                    <div className="ml-1 mt-4 mr-2 mb-2 row">
                        <Avatar
                            alt={userData?.user?.firstName}
                            className="img-fluid"
                            src="/images/UserAvatarLogo.png"
                        />
                        <span
                            className="ml-2"
                            style={{
                                alignSelf: 'center',
                                color: '#fff',
                                fontSize: '1.3rem',
                            }}
                        >
                            {userData?.user?.firstName}{' '}
                            {userData?.user?.lastName}
                        </span>
                    </div>
                </MenuItem>
            )
        }
        return choices
    }

    // if (canvasAccessData.loading) {
    //     return (
    //         <div className="container main-container mb-5">
    //             <TopLoader message={canvasAccessData.loadingMessage} />
    //         </div>
    //     )
    // }
    // const no = ['You are added to the course DSA by Mr. XYZ', 'Notification-2', 'Notification-3'];
    return (
        <header>
            <AppBar sx={{ backgroundColor: '#0055A2' }}>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Link className={styles["navbar-brand"]} to="/">
                        <img
                            alt="logo"
                            id="nav-logo"
                            src="/logo-new.png"
                            style={{
                                // width: scrolledDown ? '40px' : '70px',
                                height: scrolledDown ? '40px' : '70px',
                                margin: scrolledDown ? '8px 12px' : '8px 12px',
                            }}
                        />
                        <h4 className={`${styles["navbar-text"]} ml-4`} style={{ alignSelf: 'center' }}>
                            <i>my</i>Academic
                        </h4>
                    </Link>
                    {<Toolbar>
                        {userLoggedIn && !isMobileView ? (
                            <div 
                                style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                <Notifications
                                    Notifs={userData?.user?.notifications}
                                />
                                <div className={styles["nav-links"]}>
                                    <Avatar
                                        alt={userData?.user?.firstName}
                                        className="img-fluid"
                                        src="/images/UserAvatarLogo.png"
                                    />
                                    <div
                                        aria-haspopup="true"
                                        aria-label="menu"
                                        color="inherit"
                                        edge="start"
                                        onClick={() => {
                                            setIsDrawerOpen(!isDrawerOpen)
                                        }}
                                        style={{
                                            display: 'flex',
                                            outline: 'none',
                                        }}
                                    >
                                        <h4
                                            className={`${styles['user-name']}`}
                                            style={{
                                                alignSelf: 'center',
                                                color: '#fff',
                                            }}
                                            onClick={() => {
                                                setIsDrawerOpen(!isDrawerOpen)
                                            }}
                                        >
                                            {userData?.user?.firstName}
                                            &nbsp;
                                            {userData?.user?.lastName}
                                        </h4>
                                    </div>
                                    {/* </button> */}
                                    <IconButton
                                    aria-haspopup="true"
                                    aria-label="menu"
                                    color="inherit"
                                    edge="start"
                                    onClick={() => {
                                        setIsDrawerOpen(!isDrawerOpen)
                                    }}
                                    style={{
                                        outline: 'none',
                                    }}
                                >
                                    <MenuIcon fontSize="large" />
                                </IconButton>
                                </div>

                            </div>
                        ) : (
                            <IconButton
                                aria-haspopup="true"
                                aria-label="menu"
                                color="inherit"
                                edge="start"
                                onClick={() => {
                                    setIsDrawerOpen(!isDrawerOpen)
                                }}
                                style={{
                                    outline: 'none',
                                }}
                            >
                                <MenuIcon fontSize="large" />
                            </IconButton>
                        )}
                        <Drawer
                            anchor="right"
                            onClose={() => {
                                setIsDrawerOpen(false)
                            }}
                            open={isDrawerOpen}
                            SlideProps={{
                                sx: {
                                    backgroundColor: '#0055A2',
                                    minWidth: '200px',
                                },
                            }}
                        >
                            <div
                                className={
                                    userLoggedIn && isMobileView ? '' : 'pt-5'
                                }
                            >
                                {getDrawerChoices()}
                            </div>
                        </Drawer>
                    </Toolbar>}
                </div>
            </AppBar>
        </header>
    )
}

export default NavBar
/* eslint-disable */
