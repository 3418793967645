/*eslint-disable*/
import React from 'react';
import { Divider, Fab, Skeleton, Tab, Tabs, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import tabStyles from './Tab.module.css';

const GradeAnalyzer = ({isLoading}) => {
    return (
		<div>
			<div className={`${tabStyles['tab-heading']}`}>
                Grade Analyzer
			</div>
            <Divider />
		</div>
	)
}

export default GradeAnalyzer