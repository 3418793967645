/* eslint-disable */

import { React, useEffect } from 'react'
import './styletab.css'
import Avatar from '@mui/material/Avatar'
import StudentDetailsModal from './StudentDetailsModal'
import StudentMenu from './StudentMenu'
// import Stack from '@mui/material/Stack';
import { deepOrange, deepPurple } from '@mui/material/colors'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import AddStudentModal from './AddStudentModal'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSavedCourse } from '../../../../redux'
import { useState } from 'react'
// import { BrowserRouter as Router, Link } from 'react-router-dom';
import Routes from '../../../../Routes'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'

const returnCourse = (id, dispatch) => {
    dispatch(fetchSavedCourse(id))
}

export default function StudentList({ courseId, people }) {
    var showModal = false
    const dispatch = useDispatch()
    const [course, setCourse] = useState({})
    const userData = useSelector((state) => state.userData)
    const courseData = useSelector((state) => state.currentCourseData)
    // useEffect(() => {
    //   const fetchCourse = async () => {
    //     const courseData = returnCourse(courseId, dispatch);
    //     console.log("TestNew");
    //     console.log(courseData);
    //     setCourse(courseData);
    //   };

    //   fetchCourse();
    // }, [courseId, dispatch]);

    // const courseData = returnCourse(courseId, dispatch);
    const courseCheck = courseData.createdBy
    const userCheck = userData.user.id.toString()
    function showAlert(e) {
        alert(e)
    }

    return (
        <Router>
            <div
                className="sp"
                id=""
                style={{
                    textAlign: 'left',
                    // display: 'flex',
                    flexDirection: 'column',
                    minHeight: '70vh',
                    overflowY: 'auto',
                }}
            >
                <table>
                    <tr>
                        <th></th>
                        <th style={{ width: '70%' }}>Name</th>
                        <th>Role</th>
                        {userCheck === courseCheck ? (
                            <th>
                                <AddStudentModal courseId={courseId} />
                            </th>
                        ) : (
                            <th></th>
                        )}
                    </tr>

                    {people
                        ? people.map((i) => {
                              return (
                                  <>
                                      <tr>
                                          {i.key === courseCheck ? (
                                              <td>
                                                  <Avatar
                                                      sx={{
                                                          bgcolor:
                                                              deepOrange[500],
                                                      }}
                                                  >
                                                      {i.name[0]}
                                                  </Avatar>
                                              </td>
                                          ) : (
                                              <td>
                                                  <Avatar
                                                      sx={{
                                                          bgcolor:
                                                              deepPurple[500],
                                                      }}
                                                  >
                                                      {i.name[0]}
                                                  </Avatar>
                                              </td>
                                          )}
                                          <td>
                                              <div key={i.key} className="item">
                                                  <StudentDetailsModal
                                                      student={i}
                                                  />
                                              </div>
                                          </td>
                                          <td>
                                              <div
                                                  key={i.key}
                                                  className="item_role"
                                              >
                                                  {/* {i.role} */}
                                                  {i.key === courseCheck
                                                      ? 'Instructor'
                                                      : 'Student'}
                                              </div>
                                          </td>
                                          <td>
                                              <StudentMenu
                                                  courseId={courseId}
                                                  studentId={i.key}
                                                  email={i.email}
                                                  createdByUser={userCheck == courseCheck}
                                              />
                                          </td>
                                      </tr>
                                  </>
                              )
                          })
                        : null}
                </table>
                {/* <Routes/> */}
            </div>
        </Router>
    )
}

// StudentList.propTypes = {
//   courseId: PropTypes.string.isRequired,
//   courseMeetingDetails: PropTypes.string.isRequired,
// }
