/* eslint-disable */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Autocomplete, TextField } from '@mui/material'
import { matchSorter } from 'match-sorter'
// import { Button } from 'react-bootstrap'

// const getURLFromOriginalName = (originalName) => {
//     const subUrl = originalName
//         .toLowerCase()
//         .replaceAll(',', '')
//         .replaceAll('/', '-')
//         .replaceAll(' ', '-')
//     return `https://www.zippia.com/widgets/college-major-paths/${subUrl}-major`
// }

function CareerSearch({ data, onSelection }) {
    // const [showTextField, setShowTextField] = useState(false)

    // const [selectedOption, setSelectedOption] = useState('')

    const filterOptions = (options, { inputValue }) =>
        matchSorter(options, inputValue, { keys: ['display_name'] })

    const onChange = (event, value) => {
        if (value !== '') {
            // setSelectedOption(value)
            if (typeof value === 'string') {
                onSelection({
                    name: value,
                    display_name: value,
                    original_name: value,
                })
            } else {
                onSelection(value)
            }
        }
    }
    // const handleCantFindClick = () => {
    //     setShowTextField(!showTextField)
    // }
    // const onTextFieldChange = (event) => {
    //     setSelectedOption(event.target.value)
    //     console.log(event.target.value)
    // }

    // const onTextSubmit = () => {
    //     const temp = {
    //         name: selectedOption,
    //         display_name: selectedOption,
    //         original_name: selectedOption,
    //     }
    //     console.log(temp)
    //     onSelection(temp)
    // }

    const onInputChange = (event, value) => {
        const temp = {
            name: value,
            display_name: value,
            original_name: value,
        }
        if (value !== '') {
            onSelection(temp)
        }
    }
    return (
        <div style={{ width: '100%', display: 'block', textAlign: 'center' }}>
            <div className="search-container">
                <Autocomplete
                    freeSolo
                    filterOptions={(options, params) => {
                        const filtered = filterOptions(options, params)
                        const { inputValue } = params
                        const isExisting = options.some(
                            (option) => inputValue === option.display_name
                        )
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                name: inputValue,
                                display_name: inputValue,
                                original_name: inputValue,
                            })
                        }

                        return filtered
                    }}
                    getOptionLabel={(option) => option.display_name || option}
                    id="combo-box-demo"
                    onInputChange={(e, data) => onInputChange(e, data)}
                    onChange={onChange}
                    options={data}
                    renderInput={(params) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <TextField {...params} label="Enter your major" />
                    )}
                />
            </div>
        </div>
    )
}

CareerSearch.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.array,
    onSelection: PropTypes.func,
}

CareerSearch.defaultProps = {
    data: [],
    onSelection: null,
}

export default CareerSearch
