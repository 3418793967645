/*eslint-disable*/
import React from 'react';
import { Divider, Fab, Skeleton, Tab, Tabs, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import tabStyles from './Tab.module.css';

const Quizzes = ({isLoading}) => {
    return (
		<div>
			<div className={`${tabStyles['tab-heading']}`}>
                Quizzes
				<Fab aria-label="add" color="primary" disabled={isLoading}>
					<Tooltip title="Add Course">
						<AddIcon />
					</Tooltip>
				</Fab>
			</div>
            <Divider />
		</div>
	)
}

export default Quizzes