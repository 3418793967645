export const COURSE_TARGET_GRADE_UPDATE = 'COURSE_TARGET_GRADE_UPDATE'
export const COURSE_NAME_UPDATE = 'COURSE_NAME_UPDATE'
export const COURSE_DESCRIPTION_UPDATE = 'COURSE_DESCRIPTION_UPDATE'
export const COURSE_SYLLABUS_UPDATE = 'COURSE_SYLLABUS_UPDATE'
export const COURSE_MEETING_UPDATE = 'COURSE_MEETING_UPDATE'
export const COURSE_NOTES_UPDATE = 'COURSE_NOTES_UPDATE'

export const COURSE_CATEGORY_NAME_UPDATE = 'COURSE_CATEGORY_NAME_UPDATE'
export const COURSE_CATEGORY_TYPE_UPDATE = 'COURSE_CATEGORY_TYPE_UPDATE'
export const COURSE_CATEGORY_MAX_POINTS_UPDATE = 'COURSE_CATEGORY_MAX_POINTS_UPDATE'
export const COURSE_CATEGORY_WEIGHT_UPDATE = 'COURSE_CATEGORY_WEIGHT_UPDATE'

export const COURSE_CATEGORY_ITEM_NAME_UPDATE =
    'COURSE_CATEGORY_ITEM_NAME_UPDATE'
export const COURSE_CATEGORY_ITEM_DESCRIPTION_UPDATE =
    'COURSE_CATEGORY_ITEM_DESCRIPTION_UPDATE'
export const COURSE_CATEGORY_ITEM_KNOWN_SCORE_SWITCH =
    'COURSE_CATEGORY_ITEM_KNOWN_SCORE_SWITCH'
export const COURSE_CATEGORY_ITEM_GRADE_UPDATE =
    'COURSE_CATEGORY_ITEM_GRADE_UPDATE'
export const COURSE_CATEGORY_ITEM_MAX_POINTS_UPDATE =
    'COURSE_CATEGORY_ITEM_MAX_POINTS_UPDATE'
export const COURSE_CATEGORY_ITEM_WEIGHT_UPDATE =
    'COURSE_CATEGORY_ITEM_WEIGHT_UPDATE'

export const COURSE_CATEGORY_ADD = 'COURSE_CATEGORY_ADD'
export const COURSE_CATEGORY_DELETE = 'COURSE_CATEGORY_DELETE'

export const COURSE_CATEGORY_ITEM_ADD = 'COURSE_CATEGORY_ITEM_ADD'
export const COURSE_CATEGORY_ITEM_DELETE = 'COURSE_CATEGORY_ITEM_DELETE'

export const ACHIEVE_TARGET_GRADE = 'ACHIEVE_TARGET_GRADE'
export const MODIFY_KNOWN_SCORES = 'MODIFY_KNOWN_SCORES'

export const UPDATE_COURSE_METER_DIVISIONS = 'UPDATE_COURSE_METER_DIVISIONS'

export const COURSE_GRADES_SAVE_REQUEST = 'COURSE_GRADES_SAVE_REQUEST'
export const COURSE_GRADES_SAVE_SUCCESS = 'COURSE_GRADES_SAVE_SUCCESS'
export const COURSE_GRADES_SAVE_FAILURE = 'COURSE_GRADES_SAVE_FAILURE'

export const RESET_COURSE_DATA = 'RESET_COURSE_DATA'

export const SAVED_COURSE_LOAD_REQUEST = 'SAVED_COURSE_LOAD_REQUEST'
export const SAVED_COURSE_LOAD_SUCCESS = 'SAVED_COURSE_LOAD_SUCCESS'
export const SAVED_COURSE_LOAD_FAILURE = 'SAVED_COURSE_LOAD_FAILURE'

export const COURSE_GRADES_UPDATE_REQUEST = 'COURSE_GRADES_UPDATE_REQUEST'
export const COURSE_GRADES_UPDATE_SUCCESS = 'COURSE_GRADES_UPDATE_SUCCESS'
export const COURSE_GRADES_UPDATE_FAILURE = 'COURSE_GRADES_UPDATE_FAILURE'

export const COURSE_GRADES_DELETE_REQUEST = 'COURSE_GRADES_DELETE_REQUEST'
export const COURSE_GRADES_DELETE_SUCCESS = 'COURSE_GRADES_DELETE_SUCCESS'
export const COURSE_GRADES_DELETE_FAILURE = 'COURSE_GRADES_DELETE_FAILURE'

// Modifications
export const COURSE_STUDENT_ADDITION_SUCCESS = 'COURSE_STUDENT_ADDITION_SUCCESS'
export const COURSE_STUDENT_ADDITION_FAILURE = 'COURSE_STUDENT_ADDITION_FAILURE'

export const COURSE_SET_GRADE_PERSONAL_SUCCESS = 'COURSE_SET_GRADE_PERSONAL_SUCCESS'