import { roundValueToDecimals } from '../../../utils'
import {
    CATEGORY_WEIGHT_DECIMAL_ACCURACY,
    POINTS_DECIMAL_ACCURACY,
} from '../../../utils/constants'

export const getPointsBasedCategoryReducedData = (
    category,
    achievingTargetGrade,
    gradesInAchievingTargetGrade,
    weightPerPoint
) => {
    let totalGrades = null
    let knownGradesSum = 0
    let totalCategoryMaximumPoints = 0
    let knownGradesWeightSum = 0
    let unknownGradesWeightSum = 0
    let unknownGradesCount = 0
    category.items.forEach((item, itemIdx) => {
        const { knownScores, grade, maximumPoints } = item
        const weight = maximumPoints * weightPerPoint
        if (achievingTargetGrade && !knownScores) {
            totalGrades +=
                maximumPoints > 0
                    ? (gradesInAchievingTargetGrade[itemIdx] / maximumPoints) *
                      weight
                    : 0
        } else if (knownScores) {
            totalGrades = totalGrades === null ? 0 : totalGrades
            totalGrades +=
                maximumPoints > 0 ? (grade / maximumPoints) * weight : 0
        }
        totalCategoryMaximumPoints += maximumPoints
        if (knownScores) {
            knownGradesSum +=
                maximumPoints > 0 ? (grade / maximumPoints) * weight : 0
            knownGradesWeightSum += weight
        } else {
            unknownGradesWeightSum += weight
            unknownGradesCount += 1
        }
    })
    return {
        totalGrades, // Total grades can be actual grades or achievable grades
        totalItemsWeight: totalCategoryMaximumPoints * weightPerPoint,
        totalCategoryMaximumPoints,
        totalCategoryWeight: totalCategoryMaximumPoints * weightPerPoint,
        knownGradesSum, // Similar to totalGrades but it's always sum of actual grades
        knownGradesWeightSum,
        unknownGradesWeightSum,
        unknownGradesCount,
    }
}

export const getUpdatedPointsBasedItemWeight = (
    itemMaxPoints,
    itemMaxPointsDiff,
    totalNonPointsBasedCategoriesWeight,
    totalPointsBasedCategoriesMaxPoints
) => {
    if (totalPointsBasedCategoriesMaxPoints + itemMaxPointsDiff === 0) return 0
    return roundValueToDecimals(
        (itemMaxPoints /
            (totalPointsBasedCategoriesMaxPoints + itemMaxPointsDiff)) *
            (100 - totalNonPointsBasedCategoriesWeight),
        CATEGORY_WEIGHT_DECIMAL_ACCURACY
    )
}

export const getItemWeightsOfPointsBasedCategory = (
    category,
    totalNonPointsBasedCategoriesWeight,
    totalPointsBasedCategoriesMaxPoints
) =>
    category.items.map((item) =>
        getUpdatedPointsBasedItemWeight(
            item.maximumPoints,
            0,
            totalNonPointsBasedCategoriesWeight,
            totalPointsBasedCategoriesMaxPoints
        )
    )

export const getTotalItemsWeightOfPointsBasedCategory = (
    category,
    totalNonPointsBasedCategoriesWeight,
    totalPointsBasedCategoriesMaxPoints
) =>
    getItemWeightsOfPointsBasedCategory(
        category,
        totalNonPointsBasedCategoriesWeight,
        totalPointsBasedCategoriesMaxPoints
    ).reduce((sum, itemWeight) => sum + itemWeight, 0)

export const getTotalItemsMaxPointsOfPointsOnlyCategory = (category) =>
    roundValueToDecimals(
        category.items.reduce(
            (maxPointsSum, item) => maxPointsSum + item.maximumPoints,
            0
        ),
        POINTS_DECIMAL_ACCURACY
    )

export const getMaximumPointsOfPointsBasedCategory = (category) =>
    category.maximumPoints || 0

export const getRemainingMaxPointsOfPointsBasedCategory = (category) =>
    roundValueToDecimals(
        getMaximumPointsOfPointsBasedCategory(category) -
            getTotalItemsMaxPointsOfPointsOnlyCategory(category),
        POINTS_DECIMAL_ACCURACY
    )

export const getWeightOfPointsBasedCategory =
    getTotalItemsWeightOfPointsBasedCategory

export const updateCategoriesWithTypeUpdateToPointsBased = () => {
    // Nothing to do here
}

const getCleanPointsBasedCategoryItem = (item) => {
    const { name, description, knownScores, grade, maximumPoints } = item
    return {
        name,
        description,
        knownScores,
        grade,
        maximumPoints,
    }
}

export const getCleanPointsBasedCategory = (category) => {
    const { name, categoryType, maximumPoints, items } = category
    return {
        name,
        categoryType,
        maximumPoints,
        items: items.map(getCleanPointsBasedCategoryItem),
    }
}
