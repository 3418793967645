/* eslint-disable */
import axios from 'axios'
import {
    CANVAS_ACCESS_META_FAILURE,
    CANVAS_ACCESS_META_REQUEST,
    CANVAS_ACCESS_META_SUCCESS,
} from './canvasAccessCategoryTypes'

export const canvasAccessDataRequest = () => ({
    type: CANVAS_ACCESS_META_REQUEST,
})

export const canvasAccessDataSuccess = (canvasAccessData) => ({
    type: CANVAS_ACCESS_META_SUCCESS,
    payload: canvasAccessData,
})

export const canvasAccessDataFailure = (errorMsg) => ({
    type: CANVAS_ACCESS_META_FAILURE,
    payload: errorMsg,
})

export const fetchCanvasAccessData = (userId) => (dispatch) => {
    dispatch(canvasAccessDataRequest())
    const url = `${process.env.REACT_APP_BASE_URL}/canvas/access/${userId}`
    // if (userId && userId.trim().length > 0) {
    //     url += `?userId=${userId}`
    // }
    console.log(url)
    axios
        .get(url)
        .then((res) => {
            const canvasAccessData = res.data
            if (!canvasAccessData) {
                dispatch(canvasAccessDataFailure('No Access Data available'))
            } else {
                dispatch(canvasAccessDataSuccess(canvasAccessData))
            }
        })
        .catch((err) => {
            dispatch(
                canvasAccessDataFailure(
                    (err.response && err.response.data) ||
                        'Error fetching Canvas Access Data'
                )
            )
        })
}

export const updateCanvasAccessData = (accessToken, userId) => (dispatch) => {
    // console.log('post')
    dispatch(canvasAccessDataRequest())
    const url = `${process.env.REACT_APP_BASE_URL}/canvas/access`
    // if (userId && userId.trim().length > 0) {
    //     url += `?userId=${userId}`
    // }
    // console.log(url)
    axios
        .post(url, { accessToken, userId })
        .then((res) => {
            const canvasAccessData = res.data
            if (!canvasAccessData) {
                dispatch(canvasAccessDataFailure('Cannot Write Access Data'))
            } else {
                dispatch(canvasAccessDataSuccess(canvasAccessData))
            }
        })
        .catch((err) => {
            dispatch(
                canvasAccessDataFailure(
                    (err.response && err.response.data) ||
                        'Cannot Write Access Data'
                )
            )
        })
}
