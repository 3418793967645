/* eslint-disable */

import axios from 'axios'
import {
    ADD_QUIZ_CATEGORY_REQUEST,
    ADD_QUIZ_CATEGORY_SUCCESS,
    ADD_QUIZ_CATEGORY_FAILURE,
    EDIT_QUIZ_CATEGORY_REQUEST,
    EDIT_QUIZ_CATEGORY_SUCCESS,
    EDIT_QUIZ_CATEGORY_FAILURE,
    DELETE_QUIZ_CATEGORY_REQUEST,
    DELETE_QUIZ_CATEGORY_SUCCESS,
    DELETE_QUIZ_CATEGORY_FAILURE,
    GET_QUIZ_CATEGORIES_REQUEST,
    GET_QUIZ_CATEGORIES_SUCCESS,
    GET_QUIZ_CATEGORIES_FAILURE,
} from './categoryTypes'

const baseUrl = process.env.REACT_APP_BASE_URL

export const addQuizCategory = (categoryName) => async (dispatch) => {
    try {
        dispatch({ type: ADD_QUIZ_CATEGORY_REQUEST })

        // Make API call to add quiz category
        const response = await axios.post(`${baseUrl}/quizCategories`, {
            categoryName: categoryName,
        })

        dispatch({
            type: ADD_QUIZ_CATEGORY_SUCCESS,
            payload: response.data,
        })
    } catch (error) {
        dispatch({
            type: ADD_QUIZ_CATEGORY_FAILURE,
            payload: error.response
                ? error.response.data
                : 'Failed to add quiz category',
        })
        throw error
    }
}

// Edit Quiz Category Action
export const editQuizCategory =
    (categoryId, updatedCategory) => async (dispatch) => {
        try {
            dispatch({ type: EDIT_QUIZ_CATEGORY_REQUEST })

            // Make API call to edit quiz category
            const response = await axios.put(
                `${baseUrl}/quizCategories/${categoryId}`,
                updatedCategory
            )

            dispatch({
                type: EDIT_QUIZ_CATEGORY_SUCCESS,
                payload: response.data,
            })
        } catch (error) {
            dispatch({
                type: EDIT_QUIZ_CATEGORY_FAILURE,
                payload: error.response
                    ? error.response.data
                    : 'Failed to edit quiz category',
            })
            throw error
        }
    }

// Delete Quiz Category Action
export const deleteQuizCategory = (categoryId) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_QUIZ_CATEGORY_REQUEST })

        // Make API call to delete quiz category
        await axios.delete(`${baseUrl}/quizCategories/${categoryId}`)

        dispatch({
            type: DELETE_QUIZ_CATEGORY_SUCCESS,
            payload: categoryId,
        })
    } catch (error) {
        dispatch({
            type: DELETE_QUIZ_CATEGORY_FAILURE,
            payload: error.response
                ? error.response.data
                : 'Failed to delete quiz category',
        })
        throw error
    }
}

// Fetch Quiz Categories Action
export const fetchQuizCategories = () => async (dispatch) => {
    try {
        dispatch({ type: GET_QUIZ_CATEGORIES_REQUEST })

        // Make API call to fetch quiz categories
        const response = await axios.get(`${baseUrl}/quizCategories`)

        dispatch({
            type: GET_QUIZ_CATEGORIES_SUCCESS,
            payload: response.data,
        })
    } catch (error) {
        dispatch({
            type: GET_QUIZ_CATEGORIES_FAILURE,
            payload: error.response
                ? error.response.data
                : 'Failed to fetch quiz categories',
        })
    }
}
