import { React, useEffect, useState } from 'react'
import { useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {
    Checkbox,
    Collapse,
    Grid,
    IconButton,
    InputAdornment,
    Slider,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import EditOffIcon from '@mui/icons-material/EditOff'
import { Box } from '@mui/system'
import { addAnchorTagsForLinksInText } from '../../utils'
import { CATEGORY_WEIGHT_DECIMAL_ACCURACY } from '../../utils/constants'

export default function CategoryItemRow(props) {
    const {
        name,
        description,
        weight,
        grade,
        knownScore,
        maximumPoints,
        categoryWeight,
        categoryType,
        errorData,
        onItemNameChange,
        onItemDescriptionChange,
        enableModifyGrade,
        onItemKnownScoreChange,
        onItemGradeChange,
        onItemMaximumPointChange,
        onItemWeightChange,
        handleDeleteItemClicked,
        editableWeight,
    } = props
    const [width, setWidth] = useState(window.innerWidth)
    const [showDescription, setShowDescription] = useState(false)
    const [enableDescriptionEdit, setEnableDescriptionEdit] = useState(false)
    const userData = useSelector((state) => state.userData)
    const currentCourseData = useSelector((state) => state.currentCourseData)
    const [isInstructorOfCourse, setIsInstructorOfCourse] = useState(false)
    function handleWindowSizeChange() {
        setWidth(window.innerWidth)
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])
    useEffect(() => {
        setIsInstructorOfCourse(userData.user.id === currentCourseData.createdBy)
    }, [currentCourseData.createdBy, userData.user.id])
    const isMobileOrTab = width <= 1200

    return (
        <>
            <TableRow>
                <TableCell>
                    <Grid
                        alignItems="center"
                        container
                        minWidth="130px"
                        spacing={2}
                    >
                        <Grid item lg={9} md={9} xl={9} xs={9.5}>
                            <TextField
                                disabled={!isInstructorOfCourse}
                                error={errorData.name}
                                helperText={errorData.name}
                                inputProps={{ style: { minWidth: '100px' } }}
                                onChange={(e) =>
                                    onItemNameChange(e.target.value)
                                }
                                size="small"
                                value={name}
                                variant="standard"
                            />
                        </Grid>
                        <Grid item lg={1} md={1} xl={1} xs={1}>
                            <IconButton
                                aria-label="expand item description"
                                onClick={() =>
                                    setShowDescription(!showDescription)
                                }
                            >
                                <InfoOutlinedIcon sx={{ color: '#0055A2' }} />
                            </IconButton>
                        </Grid>
                        <Grid item lg={1} md={1} xl={1} xs={1}>
                            {showDescription ? (
                                <IconButton
                                    className="pt-0 pb-0 ml-3"
                                    onClick={(e) => {
                                        setEnableDescriptionEdit(
                                            !enableDescriptionEdit
                                        )
                                        e.stopPropagation(true)
                                    }}
                                >
                                    {enableDescriptionEdit ? (
                                        <EditOffIcon />
                                    ) : (
                                        <IconButton />
                                    )}
                                </IconButton>
                            ) : null}
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell align="center">
                    <Grid
                        alignItems="center"
                        container
                        minWidth="100px"
                        spacing={2}
                    >
                        <Grid item lg={1.5} md={6} xl={1.5} xs={5}>
                            <Tooltip
                                placement="top"
                                title="Mark score as known"
                            >
                                <Checkbox
                                    checked={knownScore}
                                    disabled={!enableModifyGrade}
                                    onChange={(e) =>
                                        onItemKnownScoreChange(e.target.checked)
                                    }
                                />
                            </Tooltip>
                        </Grid>
                        {isMobileOrTab ? null : (
                            <Grid
                                item
                                lg={8}
                                md={7}
                                xl={errorData.grade ? 7.5 : 8}
                                xs={10}
                            >
                                <Slider
                                    aria-labelledby="input-slider"
                                    disabled={knownScore !== enableModifyGrade}
                                    max={maximumPoints}
                                    onChange={(e) =>
                                        onItemGradeChange(e.target.value)
                                    }
                                    step={0.5}
                                    value={grade}
                                />
                            </Grid>
                        )}
                        <Grid
                            item
                            lg={2}
                            md={6}
                            xl={errorData.grade ? 3 : 2.5}
                            xs={7}
                        >
                            <TextField
                                disabled={knownScore !== enableModifyGrade}
                                error={errorData.grade}
                                helperText={errorData.grade}
                                inputProps={{
                                    step: 0.5,
                                    min: 0,
                                    type: 'number',
                                    'aria-labelledby': 'grade-input-slider',
                                }}
                                onChange={(e) =>
                                    onItemGradeChange(e.target.value)
                                }
                                onWheel={(e) => e.target.blur()}
                                size="small"
                                value={grade.toString()}
                                variant="standard"
                            />
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell align="center">
                    <TextField
                        disabled={!enableModifyGrade || !isInstructorOfCourse}
                        error={errorData.maximumPoints}
                        helperText={errorData.maximumPoints}
                        inputProps={{
                            type: 'number',
                            size: 'small',
                            min: 0,
                            max: 10000,
                            step: 1,
                            style: { textAlign: 'center' },
                        }}
                        onChange={(e) =>
                            onItemMaximumPointChange(e.target.value)
                        }
                        onWheel={(e) => e.target.blur()}
                        value={maximumPoints.toString()}
                        variant="standard"
                    />
                </TableCell>
                <TableCell align="center">
                    <TextField
                        disabled={!editableWeight || !enableModifyGrade || !isInstructorOfCourse}
                        error={errorData.weight}
                        helperText={errorData.weight}
                        inputProps={{
                            type: 'number',
                            size: 'small',
                            min: 0,
                            max:
                                categoryType === 'STANDARD'
                                    ? null
                                    : categoryWeight,
                            step: 0.1 ** CATEGORY_WEIGHT_DECIMAL_ACCURACY,
                            style: { textAlign: 'center', minWidth: '50px' },
                        }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    %
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => onItemWeightChange(e.target.value)}
                        onWheel={(e) => e.target.blur()}
                        value={weight.toString()}
                        variant="standard"
                    />
                </TableCell>
                <TableCell align="center">
                    <IconButton
                        aria-label="delete item"
                        disabled={!enableModifyGrade || !isInstructorOfCourse}
                        edge="end"
                        onClick={handleDeleteItemClicked}
                    >
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    align="center"
                    colSpan={5}
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                >
                    <Collapse in={showDescription} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 0.5 }}>
                            {enableDescriptionEdit ? (
                                <TextField
                                    error={!!errorData.description}
                                    fullWidth
                                    helperText={`${description.length}/256 characters`}
                                    id="description-edit"
                                    inputProps={{
                                        style: {
                                            padding: 0,
                                        },
                                    }}
                                    multiline
                                    onBlur={() =>
                                        setEnableDescriptionEdit(
                                            !enableDescriptionEdit
                                        )
                                    }
                                    onChange={(e) =>
                                        onItemDescriptionChange(e.target.value)
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                    rows={4}
                                    value={description}
                                    variant="filled"
                                />
                            ) : (
                                <Typography
                                    id="description"
                                    onClick={(e) => e.stopPropagation()}
                                    style={{ whiteSpace: 'pre-line' }}
                                    sx={{
                                        display: '-webkit-box',
                                        overflow: 'scroll',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 4,
                                        background: 'rgb(247, 247, 247)',
                                        minHeight: '7.8em',
                                        padding: '25px 12px 8px',
                                        border: errorData.description
                                            ? '1px red solid'
                                            : 'none',
                                        textAlign: 'start',
                                    }}
                                    variant="body1"
                                >
                                    {addAnchorTagsForLinksInText(description)}
                                </Typography>
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

CategoryItemRow.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    name: PropTypes.string,
    description: PropTypes.string,
    weight: PropTypes.number,
    grade: PropTypes.number,
    knownScore: PropTypes.bool,
    maximumPoints: PropTypes.number,
    categoryWeight: PropTypes.number,
    categoryType: PropTypes.string.isRequired,
    enableModifyGrade: PropTypes.bool,
    onItemNameChange: PropTypes.func.isRequired,
    onItemDescriptionChange: PropTypes.func.isRequired,
    onItemKnownScoreChange: PropTypes.func.isRequired,
    onItemGradeChange: PropTypes.func.isRequired,
    onItemMaximumPointChange: PropTypes.func.isRequired,
    onItemWeightChange: PropTypes.func.isRequired,
    handleDeleteItemClicked: PropTypes.func.isRequired,
    editableWeight: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    errorData: PropTypes.object,
}

CategoryItemRow.defaultProps = {
    name: '',
    description: '',
    weight: 0,
    grade: 0,
    knownScore: false,
    maximumPoints: 0,
    categoryWeight: 0,
    errorData: {},
    enableModifyGrade: true,
    editableWeight: true,
}
