/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import styles from './CreateCourse.module.css'
import ClearIcon from '@mui/icons-material/Clear';
import Modal from '@mui/material/Modal';
import { Button, createTheme, Fab, TextField, ThemeProvider, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const CreateCourse = ({open, setOpen}) => {
    const [courseTitle, setCourseTitle] = useState(""); 
    const [isValidTitle, setIsValidTitle] = useState(true); 
    const [courseStartDate, setCourseStartDate] = useState(dayjs()); 
    const [isValidStartDate, setIsValidStartDate] = useState(true); 
    const [courseEndDate, setCourseEndDate] = useState(dayjs()); 
    const [isValidEndDate, setIsValidEndDate] = useState(true); 
    const [courseDescription, setCourseDescription] = useState("");
    const [isValidCourseDescription, setIsValidCourseDescription] = useState(true); 

	const theme = createTheme({
		components: {
			MuiFormLabel: {
				styleOverrides: {
					asterisk: { color: "#d32f2f" },
				},
			},
		},
	
	})

    const handleClose = () => {
        setOpen(false);
    }

    return (
		<div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={`${styles['modal-container']}`}>
                    <div className={`${styles['row']}`}>
                        <h1 className={`${styles['modal-heading']}`}>Create Course</h1>
                        <ClearIcon 
                            onClick={handleClose}
                            sx={{
                                fontSize: "2.5em",
                                cursor: "pointer"
                            }}
                        />
                    </div>
					<div className={`${styles['modal-input-container']}`}>
						<div className={`${styles['to-col']}`} style={{display: "flex", gap: "1em", alignItems: "flex-start"}}>
							<ThemeProvider theme={theme}>
								<TextField
									autoFocus={true}
									id="course-title"
									label="Course Title"
									onBlur={() => {
										if (courseTitle === '') {
											setIsValidTitle(false);
										}
									}}
									onChange={(e) => {setIsValidTitle(true); setCourseTitle(e.target.value);}}
									value={courseTitle}
									fullWidth
									required
									color={!isValidTitle ? 'error' : 'primary'}
									error={!isValidTitle}
									helperText={!isValidTitle ? '* Course title is required' : ''}
								/>
							</ThemeProvider>
                        </div>						

                        <div className={`${styles['to-col']}`} style={{display: "flex", gap: "1em", alignItems: "flex-start"}}>
							<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                                <DatePicker
                                    label="Start Date"
                                    required
                                    id='course-start-date'
                                    value={courseStartDate}
                                    onChange={(newStartDate) => {
                                        setCourseStartDate(newStartDate)
                                    }}
                                    color={!isValidStartDate ? 'error' : 'primary'}
                                    error={!isValidStartDate}
                                    helperText={!isValidStartDate ? 'Start Date is required' : ''}
                                    minTime={dayjs('2022-04-17T00:00:01')}
                                    fullWidth 
                                />
							</LocalizationProvider> 
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                                <DatePicker 
                                    label="End Date"
                                    required
                                    id='course-end-date'
                                    value={courseEndDate}
                                    onChange={(newEndDate) => {
                                        setCourseEndDate(newEndDate)
                                    }}
                                    color={!isValidEndDate ? 'error' : 'primary'}
                                    error={!isValidEndDate}
                                    helperText={!isValidEndDate ? 'End Date is required' : ''}
                                    minTime={dayjs('2022-04-17T00:00:01')}
                                    fullWidth 
                                />
							</LocalizationProvider> 
                        </div>
                        <TextField
							fullWidth
							id="course-description"
							label="Course Description"
							onBlur={() => setCourseDescription(courseDescription)}
							onChange={(e) => setCourseDescription(e.target.value)}
							value={courseDescription}
							multiline
							rows={3}
						/>
{/* 
								<ThemeProvider theme={theme}>	
									<TimePicker
										label="Time Limit"
										required
										id='quiz-time-limit'
										defaultValue={dayjs('2022-04-17T01:00')}
										value={timeLimit}
										views={['hours', 'minutes', 'seconds']}
										onChange={(newTimeLimit) => {
											setTimeLimit(newTimeLimit)
										}}
										ampm={false}
										color={!isValidTimeLimit ? 'error' : 'primary'}
										error={!isValidTimeLimit}
										helperText={!isValidTimeLimit ? 'Quiz title is required' : ''}
										minTime={dayjs('2022-04-17T00:00:01')}
										fullWidth
									/>
								</ThemeProvider>
                            */}

						{/* 

						<div
							className={`${styles['row']}`}
						>
							<div>
								<h3 className={`${styles['h3']}`}>QUESTIONS</h3>
								{
									!isValidQuestions && 
									<p 
										className={styles['error-helper-text']}
									>
										* At least one question is required
									</p>
								}
							</div>
							<Fab aria-label="add" color={isValidQuestions ? "primary" : 'error'} onClick={() => {setIsValidQuestions(true); setShowAddQuestionForm(true);}}>
								<Tooltip title="Add Question">
									<AddIcon />
								</Tooltip>
							</Fab>
						</div>

						{
							showAddQuestionForm && 
							<div className={`${styles['create-question-form']}`}>
								<div className={`${styles['row']}`}>
									<h3 className={`${styles['h3']}`}>New Question</h3>
									<ClearIcon 
										onClick={(e)=>{
											e.stopPropagation()
											setCurrentQuestion({
												type: "MCQ",
												statement: "",
												questionImages: [],
												options: [{
													id: 1,
													optionStatement: 'Option'
												}],
												correctAnswers: [],
												explanation: "",
												points: "1"
											})
											setShowAddQuestionForm(false)
										}}
										sx={{
											fontSize: "2.5em",
											cursor: "pointer"
										}}
									/>
								</div>
								<NewQuestion 
									currentQuestion={currentQuestion}
									setCurrentQuestion={setCurrentQuestion} 
									addQuestion={handleAddQuestion}
									isNew={true}
								/>
							</div>
						}

						<div className={`${styles['questions-list']}`}>
							{questions.length === 0 ? (
								<div
									className={`${styles['row-center']}`} 
								>
									<p className={`${styles['default-text']}`}>No questions added</p>
								</div>
							) : (
								questions.map((question, i) => (editQuestionIndex === i) ? (
								<div className={`${styles['create-question-form']}`}>
									<div className={`${styles['row']}`}>
										<h3 className={`${styles['h3']}`}>Edit Question {i + 1}</h3>
										<ClearIcon 
											onClick={()=>{
												setEditQuestionIndex(-1)
											}}
											sx={{
												fontSize: "2.5em",
												cursor: "pointer"
											}}
										/>
									</div>
									<NewQuestion 
										currentQuestion={questions[editQuestionIndex]}
										setCurrentQuestion={(fn) => {
											setQuestions((prevQuestions) => (
												prevQuestions.map((prevQuestion, j) => {
													if (i === j) {
														return fn(prevQuestion);
													} else {
														return prevQuestion;
													}
												})
											))
										}} 
										addQuestion={() => setEditQuestionIndex(-1)}
										isNew={false}
									/>
								</div>
							) : (
								<div 
									draggable="true" 
									onDragStart={(e) => handleDragStart(e, question)}
									onDragEnd={handleDragEnd}
									onDragOver={handleDragOver}
									onDrop={(e) => handleDrop(e, question)} 
									key={i}
									onClick={() => {
										setEditQuestionIndex(i);
									}}
									className={`${styles['create-question-container']}`}
								>
									<div 
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											cursor: "grab"
										}}
									>
										<DragIndicatorIcon
											sx={{
												color: "rgba(0, 0, 0, 0.50)"
											}}
										/>
									</div>
									<div>
										<div className={`${styles['row']}`}>
											<h3 className={`${styles['h3']}`}>Question {i + 1}</h3>
											<div>
												<Tooltip title="Delete This Question">
													<DeleteIcon 
														onClick={
															(e)=>{
																e.preventDefault();
                            									e.stopPropagation();
																setQuestions(questions => (
																	questions.filter((question, j) => (
																		i !== j 
																	))
																))
															}
														} 
														sx={{
															fontSize: "2em"
														}}
													/>
												</Tooltip>
											</div>
										</div>	
										<div dangerouslySetInnerHTML={{__html: question.statement}}></div>
									</div>
								</div>
							)))}
						</div> */}
					</div>
					<div className={`${styles['row-center']} ${styles['create-quiz-btns']}`} style={{gap: '1em'}}>
                        <Button
                            className={`btn btn-primary ${styles['add-to-course-btn']}`}
                            onClick={() => handleSubmit(false)}
                            variant='contained'
                            size='large'
                        >
                            Create
                        </Button>

						<Button
                            className={`btn btn-primary ${styles['add-to-course-btn']}`}
                            onClick={() => handleSubmit(true)}
                            variant='contained'
                            size='large'
                        >
                            Create and Publish
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
	)
}

export default CreateCourse 