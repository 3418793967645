/* eslint-disable */
import React, { useState, useEffect } from 'react'
import {
    List,
    ListItem,
    ListItemIcon,
    Box,
    ListItemText,
    Link,
    Stack,
} from '@mui/material'
import { Redirect, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
// import HomeIcon from '@mui/icons-material/Home'
// import MenuBookIcon from '@mui/icons-material/MenuBook'
// import AssignmentIcon from '@mui/icons-material/Assignment'
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Home from './CanvasCoursHome'
import Modules from './CanvasCourseModules'
import Quiz from './CanvasQuiz'
import Sidebar from './Sidebar'
import { fetchCanvasData } from '../../redux'
import TopLoader from '../commons/TopLoader'

const courseDetails = () => {
    const { course_id: courseId } = useParams()
    const [selectedItem, setSelectedItem] = useState('Home')

    const handleListItemClick = (itemName) => {
        setSelectedItem(itemName)
    }
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)

    const canvasData = useSelector((state) => state.canvasData)
    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            dispatch(fetchCanvasData(courseId))
        }
    }, [dispatch, userData.userFetchCompleted, userData.user])
    // console.log(canvasData)
    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (canvasData.loading) {
        return (
            <div className="container main-container mb-5">
                <TopLoader message={canvasData.loadingMessage} />
            </div>
        )
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }
    if (userData.user.role !== 'Instructor') {
        return (
            <div className="container main-container">
                <h4 className="alert alert-danger mt-4">
                    {
                        'To view this page, the user must log in as an instructor.'
                    }
                </h4>
            </div>
        )
    }
    // console.log(canvasData)
    return (
        <Stack spacing={8} direction={'row'}>
            <Sidebar courseId={courseId} />

            {selectedItem === 'Home' && (
                <Home canvasData={canvasData.canvasData} />
            )}
            {selectedItem === 'Modules' && <Modules courseId={courseId} />}
            {selectedItem === 'Quizzes' && (
                <Quiz canvasData={canvasData.canvasData} />
            )}
        </Stack>
    )
}

export default courseDetails
