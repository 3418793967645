import {
    GET_CURRENT_USER_FAILURE,
    GET_CURRENT_USER_REQUEST,
    GET_CURRENT_USER_SUCCESS,
    USER_LOGIN_FAILURE,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT_FAILURE,
    USER_LOGOUT_REQUEST,
    USER_LOGOUT_SUCCESS,
    USER_REGISTER_EXIT,
    USER_REGISTER_FAILURE,
    USER_REGISTER_INIT,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
} from './userTypes'

const initialState = {
    loading: false,
    loadingMessage: '',
    user: null,
    message: '',
    error: '',
    registered: false,
    userFetchCompleted: false, // Fetch user done, irrespective of success or failure
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                loadingMessage: 'Logging in...',
            }
        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                user: {},
                message: 'Successfully logged in!',
                error: '',
            }
        case USER_LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                message: '',
                error: action.payload,
            }
        case USER_REGISTER_INIT:
            return {
                ...state,
                message: '',
                error: '',
                registered: false,
            }
        case USER_REGISTER_REQUEST:
            return {
                ...state,
                loading: true,
                loadingMessage: 'Registering...',
                error: '',
            }
        case USER_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                message:
                    'Registered successfully! Please check your email for further steps',
                error: '',
                registered: true,
            }
        case USER_REGISTER_FAILURE:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                message: '',
                error: action.payload,
            }
        case USER_REGISTER_EXIT:
            return {
                ...state,
                error: '',
                registered: false,
            }
        case GET_CURRENT_USER_REQUEST:
            return {
                ...state,
                loading: true,
                loadingMessage: 'Fetching user details...',
                error: '',
                userFetchCompleted: false,
            }
        case GET_CURRENT_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                user: action.payload,
                error: '',
                userFetchCompleted: true,
            }
        case GET_CURRENT_USER_FAILURE:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                message: '',
                userFetchCompleted: true,
            }
        case USER_LOGOUT_REQUEST:
            return {
                ...state,
                loading: true,
                loadingMessage: 'Logging user out',
                error: '',
            }
        case USER_LOGOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                user: null,
                error: '',
                registered: false,
            }
        case USER_LOGOUT_FAILURE: {
            const updatedState = {
                loading: false,
                loadingMessage: '',
                message: '',
                error: '',
            }
            if (state.user !== null) {
                updatedState.user = null
                updatedState.registered = false
            }
            return updatedState
        }
        default:
            return state
    }
}

export default reducer
