/* eslint-disable */

import axios from 'axios'

import {
    UPDATE_EMAIL,
    // USER_LOGIN_SUCCESS,
    SET_USER_DATA,
    SET_USER_FAIL,
} from './searchTypes'

const setUser = (user) => ({
    type: SET_USER_DATA,
    payload: user,
})

export const sendData = (userEmail) => async (dispatch) => {
    try {
        const { data } = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/getSearchData/${userEmail}`,
            {
                email: userEmail,
            }
        )
        if (data) {
            dispatch(setUser(data))
        } else {
            dispatch(emailFail(userEmail))
        }
    } catch (error) {
        dispatch(emailFail(userEmail))
        console.error(error)
    }
}

export const updateEMail = (email) => ({
    type: UPDATE_EMAIL,
    payload: email,
})

export const emailFail = (email) => ({
    type: SET_USER_FAIL,
    payload: email,
})
