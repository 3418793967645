/*eslint-disable*/
import React, { useRef, useState } from 'react';
import styles from './AnnouncementThumbnail.module.css'
import { ClickAwayListener, Divider, Grow, Menu, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const AnnouncementThumbnail = ({announcement}) => {
    const anchorRef = useRef(null);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    
    const displayMenu = (e) => {
        setIsOpenMenu(true);
    }

    const closeMenu = () => {
        setIsOpenMenu(false);
    }

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    return (
        <div className={styles['announcement-tile']}>
            <div className={styles['announcement-date-container']}>
                <div className={styles['announcement-time']}>06:00 PM</div>
                <div className={styles['announcement-date']}>
                    <div className={styles['announcement-date-month']}>12 Nov</div>
                    <div className={styles['announcement-year']}>2024</div>
                </div>
            </div>
            <div className={styles['announcement-main-container']}>
                <div className={styles['announcement-top-container']}>
                    <div className={styles['announcement-title']}>
                        {announcement.title}
                    </div>
                    <div className={styles['announcement-creator-name']}>by {announcement.creatorName}</div>
                </div>
                <Divider />
                <div className={styles['announcement-description']}>{announcement.description}</div>
            </div>
            <MoreHorizIcon ref={anchorRef} className={styles['announcement-more-menu']} onClick={displayMenu} />
            <Popper
                open={isOpenMenu}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'right top',
                        }}
                    >
                    <Paper>
                        <ClickAwayListener onClickAway={closeMenu}>
                        <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                        >
                            <MenuItem style={{display: "flex", gap: "0.5rem", alignItems: "center"}} onClick={closeMenu}><EditIcon /> Edit</MenuItem>
                            <MenuItem style={{display: "flex", gap: "0.5rem", alignItems: "center"}} onClick={closeMenu}><DeleteIcon /> Delete</MenuItem>
                        </MenuList>
                        </ClickAwayListener>
                    </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}

export default AnnouncementThumbnail