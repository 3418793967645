import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
    clearErrorForKey as clearErrorForKeyRedux,
    login as loginRedux,
    setErrorForKey as setErrorForKeyRedux,
} from '../../redux'
import { validateEmail } from '../../utils'
// import { Resend } from 'resend';

function LoginForm({
    userData,
    errorData,
    login,
    setErrorForKey,
    clearErrorForKey,
}) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    useEffect(
        () => () => {
            clearErrorForKey('email')
            clearErrorForKey('password')
        },
        [clearErrorForKey]
    )

    const onEmailChange = (updatedEmail) => {
        let errorMessage = null
        if (!validateEmail(updatedEmail))
            errorMessage = 'Invalid email provided'
        setErrorForKey('email', errorMessage)
        setEmail(updatedEmail)
    }

    const onPasswordChange = (updatedPassword) => {
        let errorMessage = null
        if (updatedPassword.length === 0) errorMessage = 'No password provided'
        else if (updatedPassword.length < 6)
            errorMessage = 'Password length must atleast be 6'
        setErrorForKey('password', errorMessage)
        setPassword(updatedPassword)
    }

    const handleSubmit = () => {
        login(email, password)
    }

    const isFormValid = () =>
        errorData.email === null && errorData.password === null

    return (
        <form>
            <div className="form-group">
                <label className="input-max-400" htmlFor="email">
                    Email Address
                    <input
                        className={`form-control ${
                            // eslint-disable-next-line no-nested-ternary
                            errorData.email === undefined
                                ? ''
                                : errorData.email
                                ? 'form-input-invalid'
                                : 'form-input-valid'
                        }`}
                        maxLength={50}
                        onChange={(e) => onEmailChange(e.target.value)}
                        type="email"
                        value={email}
                    />
                </label>
            </div>
            <div className="form-group">
                <label className="input-max-400" htmlFor="password">
                    Password
                    <input
                        className={`form-control input-max-400 ${
                            // eslint-disable-next-line no-nested-ternary
                            errorData.password === undefined
                                ? ''
                                : errorData.password
                                ? 'form-input-invalid'
                                : 'form-input-valid'
                        }`}
                        id="password"
                        maxLength={20}
                        onChange={(e) => onPasswordChange(e.target.value)}
                        type="password"
                        value={password}
                    />
                </label>
            </div>
            <button
                className="btn btn-primary mr-3 mb-3"
                disabled={userData.loading || !isFormValid()}
                onClick={handleSubmit}
                type="submit"
            >
                Login
            </button>
            <Link to="/reset-password/generate">
                <button className="btn btn-primary mr-3 mb-3" type="submit">
                    Forgot Password
                </button>
            </Link>
            <Link to="/register">
                <button className="btn btn-primary mb-3" type="submit">
                    Register
                </button>
            </Link>
        </form>
    )
}

LoginForm.propTypes = {
    userData: PropTypes.shape({
        loading: PropTypes.bool,
    }).isRequired,
    errorData: PropTypes.shape({
        email: PropTypes.string,
        password: PropTypes.string,
    }).isRequired,
    login: PropTypes.func.isRequired,
    setErrorForKey: PropTypes.func.isRequired,
    clearErrorForKey: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    errorData: state.errorData,
})

const mapDispatchToProps = (dispatch) => ({
    login: (email, password) => dispatch(loginRedux(email, password)),
    setErrorForKey: (key, errorMessage) =>
        dispatch(setErrorForKeyRedux(key, errorMessage)),
    clearErrorForKey: (key) => dispatch(clearErrorForKeyRedux(key)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
