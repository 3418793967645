import axios from 'axios'
import {
    CANVAS_META_FAILURE,
    CANVAS_META_REQUEST,
    CANVAS_META_SUCCESS,
} from './canvasCategoryTypes'

export const canvasDataRequest = () => ({
    type: CANVAS_META_REQUEST,
})

export const canvasDataSuccess = (canvasData) => ({
    type: CANVAS_META_SUCCESS,
    payload: canvasData,
})

export const canvasDataFailure = (errorMsg) => ({
    type: CANVAS_META_FAILURE,
    payload: errorMsg,
})

export const fetchCanvasData = (courseId) => (dispatch) => {
    console.log(courseId)
    dispatch(canvasDataRequest())
    let url = `${process.env.REACT_APP_BASE_URL}/canvas/courses`
    if (courseId && courseId.trim().length > 0) {
        url += `?courseId=${courseId}`
    }

    // console.log(url)
    // if (courseId && courseId.trim().length > 0) {
    //     url += `?courseId=${courseId}`
    // }
    // console.log(url)
    axios
        .get(url)
        .then((res) => {
            const canvasData = res.data
            if (!canvasData) {
                dispatch(canvasDataFailure('No course/courses available'))
            } else {
                dispatch(canvasDataSuccess(canvasData))
            }
        })
        .catch((err) => {
            dispatch(
                canvasDataFailure(
                    (err.response && err.response.data) ||
                        'Error fetching course/courses from canvas'
                )
            )
        })
}
// export const fetchSingleCourse = () => (dispatch) => {
//     dispatch(canvasDataRequest())
//     axios
//         .get(`${process.env.REACT_APP_BASE_URL}/canvas/courses`)
//         .then((res) => {
//             const canvasData = res.data
//             if (!canvasData) {
//                 dispatch(canvasDataFailure('No courses available'))
//             } else {
//                 dispatch(canvasDataSuccess(canvasData))
//             }
//         })
//         .catch((err) => {
//             dispatch(
//                 canvasDataFailure(
//                     (err.response && err.response.data) ||
//                         'Error fetching courses from canvas'
//                 )
//             )
//         })
// }
// export const saveQuizCategory = (category) => (dispatch) => {
//     dispatch(canvasDataRequest())
//     axios({
//         method: 'post',
//         url: `${process.env.REACT_APP_BASE_URL}/quiz/category`,
//         data: { category },
//         headers: {
//             'Content-Type': 'application/json',
//         },
//     })
//         .then((response) => {
//             const quizCategory = response.data
//             dispatch(canvasDataSuccess(quizCategory))
//         })
//         .catch((err) => {
//             dispatch(
//                 canvasDataFailure(
//                     (err.response && err.response.data) ||
//                         'Error adding quiz category'
//                 )
//             )
//         })
// }
