import { UPDATE_EMAIL, SET_USER_DATA, SET_USER_FAIL } from './searchTypes'

const initialstate = {
    email: [],
    user: [],
    failedEmails: [],
}

const searchReducer = (state = initialstate, action = {}) => {
    switch (action.type) {
        case UPDATE_EMAIL:
            return {
                ...state,
                email: [...state.email, action.payload],
            }
        case SET_USER_DATA:
            return {
                ...state,
                user: [...state.user, action.payload],
            }
        case SET_USER_FAIL:
            return {
                ...state,
                failedEmails: [...state.failedEmails, action.payload],
            }
        default:
            return state
    }
}

export default searchReducer
