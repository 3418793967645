/*eslint-disable*/
import React from 'react';
import { Divider, Fab, Skeleton, Tab, Tabs, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import tabStyles from '../Tab.module.css';
import styles from './Syllabus.module.css';
import RTE from '../../../commons/RTE';

const Syllabus = ({isLoading}) => {
    return (
		<div className={`${tabStyles['tab-container']}`}>
			<div className={`${tabStyles['tab-heading']}`}>
                Syllabus
				<Fab aria-label="add" color="primary" disabled={isLoading}>
					<Tooltip title="Add Course">
						<EditIcon />
					</Tooltip>
				</Fab>
			</div>
            <Divider />
			<div className={`${styles['container']}`}>
				<div className={`${styles['files-container']}`}></div>
				<div className={`${styles['syllabus-container']}`}>
					<RTE value={1} setValue={()=>{}} placeholder={'Enter syllabus'} />
				</div>
			</div>
		</div>
	)
}

export default Syllabus