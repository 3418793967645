import {
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_GENERATE_FAILURE,
    RESET_PASSWORD_GENERATE_REQUEST,
    RESET_PASSWORD_GENERATE_SUCCESS,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
} from './resetPasswordTypes'

const initialState = {
    loading: false,
    loadingMessage: '',
    generationAttempted: false,
    generationSuccess: false,
    resetAttempted: false,
    resetSuccessfully: false,
    message: '',
    error: '',
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case RESET_PASSWORD_GENERATE_REQUEST:
            return {
                ...state,
                loading: true,
                loadingMessage: 'Verifying password reset credentials',
                generationAttempted: false,
                generationSuccess: false,
                message: '',
                error: '',
            }
        case RESET_PASSWORD_GENERATE_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                generationAttempted: true,
                generationSuccess: true,
                message:
                    'Please check your email for further instructions to reset the password',
                error: '',
            }
        case RESET_PASSWORD_GENERATE_FAILURE:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                generationAttempted: true,
                generationSuccess: false,
                message: '',
                error: action.payload,
            }
        case RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                loadingMessage: 'Resetting your password...',
                resetAttempted: false,
                resetSuccessfully: false,
                message: '',
                error: '',
            }
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                resetAttempted: true,
                resetSuccessfully: true,
                message: 'Password reset successfully! You can login now',
                error: '',
            }
        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                resetAttempted: true,
                resetSuccessfully: false,
                message: '',
                error: action.payload,
            }
        default:
            return state
    }
}

export default reducer
