import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import LoginForm from './forms/LoginForm'
import TopLoader from './commons/TopLoader'
import GoogleLogin from './GoogleLogin'

function Login({ userData }) {
    console.log(userData)
    // console.log("Abcdef");
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [width, setWidth] = useState(window.innerWidth)
    function handleWindowSizeChange() {
        setWidth(window.innerWidth)
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])
    if (userData && userData.user && userData.user.id !== undefined) {
        return <Redirect to="/" />
    }
    const isMobileOrTab = width <= 1200
    return (
        <>
            {userData.loading ? (
                <TopLoader message={userData.loadingMessage} />
            ) : (
                ''
            )}
            <div className="main-container container mt-4 mb-5">
                <div
                    className="card"
                    style={{
                        display: isMobileOrTab ? '' : 'flex',
                        flexDirection: isMobileOrTab ? '' : 'row',
                    }}
                >
                    <div
                        className={`card-body ${
                            isMobileOrTab ? '' : 'login-col'
                        }`}
                    >
                        <LoginForm />
                    </div>
                    <div
                        className={`card-body ${
                            isMobileOrTab ? '' : 'login-col'
                        }`}
                        style={{ alignSelf: 'center' }}
                    >
                        <GoogleLogin />
                    </div>
                </div>
                {userData.error ? (
                    <h4 className="alert alert-danger mt-4">
                        {userData.error}
                    </h4>
                ) : (
                    ''
                )}
                {userData.message ? (
                    <h4 className="alert alert-success mt-4">
                        {userData.message}
                    </h4>
                ) : (
                    ''
                )}
            </div>
        </>
    )
}

Login.propTypes = {
    userData: PropTypes.shape({
        loading: PropTypes.bool,
        loadingMessage: PropTypes.string,
        error: PropTypes.string,
        message: PropTypes.string,
        user: PropTypes.shape({
            id: PropTypes.string,
        }),
    }).isRequired,
}

const mapStateToProps = (state) => ({
    userData: state.userData,
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
