/* eslint-disable */
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import * as React from 'react'
import Box from '@mui/material/Box'
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { addStudent } from '../../../../redux/course/courseActions'
import { useState, useEffect } from 'react'
import { TextField, FormControl, Input } from '@mui/material'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { sendData, updateEMail } from '../../../../redux/search/searchActions'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import { validateEmail } from '../../../../../src/utils'
import AddStudentForm from './AddStudentForm'
import './modalstyle.css'
import Button from 'react-bootstrap/Button'
import { updateCoursePeople } from '../../../../redux/course/courseActions'
import Form from 'react-bootstrap/Form'
import { Routes, Route, Redirect } from 'react-router-dom'
import { color } from '@mui/system'
import AddStudentCSV from './AddStudentCSV'
import * as XLSX from 'xlsx'
import styles from './AddStudentModal.module.css'
import { useRef } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DescriptionIcon from '@mui/icons-material/Description';

const sendSearchDetails = (dispatch, email) => {
    dispatch(sendData(email))
}

const updateCourseData = (dispatch, user) => {
    dispatch(addStudent(user))
}

var lastUserAdded = ''

export default function AddStudentModal({ courseId }) {
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false)
    const [isChecked, setIsChecked] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => {
        setOpen(false)
        setEmail('')
        setEmails(new Set())
        setFile(null)
    }
    const [email, setEmail] = useState('')
    const [emails, setEmails] = useState(new Set())
    const [file, setFile] = useState(null)
    const userData = useSelector((state) => state.userData)
    const searchData = useSelector((state) => state.searchData)
    const courseData = useSelector((state) => state.currentCourseData)
    const userEmail = userData
        ? userData.user
            ? userData.user.email
            : null
        : null
    // const [file, setFile] = useState(null)
    const [, forceUpdate] = useState()
    const [refreshKey, setRefreshKey] = useState(0)

    const [activeTab, setActiveTab] = useState(0);

    const [draggedOver, setDraggedOver] = useState(false);
    const fileInputRef = useRef(null);

    const draggableStyles = {
        backgroundColor: draggedOver ? '#d5eaff' : 'transparent'
    }

    var s = ''
    var nlast
    var chk = true
    const handleSearchData = () => {
        if (searchData && searchData.user) {
            const usersToAdd = searchData.user.map((k) => ({
                name: k.firstName + ' ' + k.lastName,
                role: k.role,
                key: k.id,
                email: k.email,
                linkedin: 'linked',
                github: 'github',
            }))

            var n = searchData.user.length
            // console.log('Testing users to add')
            // console.log(lastUserAdded)

            if (n > 0 && usersToAdd[n - 1])
                console.log(
                    lastUserAdded + ' ' + usersToAdd[n - 1].key.toString()
                )

            if (
                n > 0 &&
                usersToAdd[n - 1] &&
                usersToAdd[n - 1].key.toString() != lastUserAdded
            ) {
                updateCoursePeople(
                    courseId,
                    usersToAdd[n - 1].name,
                    usersToAdd[n - 1].role,
                    usersToAdd[n - 1].email,
                    usersToAdd[n - 1].linkedin,
                    usersToAdd[n - 1].github,
                    usersToAdd[n - 1].key
                )
                // lastEmail = usersToAdd[n - 1].email;
            }
            if (n > 0 && usersToAdd[n - 1])
                lastUserAdded = usersToAdd[n - 1].key.toString()
        }
        if (searchData && searchData.failedEmails) {
            var n = searchData.failedEmails.length
            // console.log(n)
            // console.log(searchData.failedEmails[0])
            if (n != 0) s += searchData.failedEmails[n - 1]
            // console.log(s)
            // console.log(searchData)
        }
        if (s != '') alert('Emails not present in the system: ' + s)
        s = ''
    }

    const findEmails = (text) => {
        console.log(text);
        const emailRegex = /[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+/g;
        const foundEmails = text.match(emailRegex);
        return foundEmails;
        // console.log(foundEmails)
        // return foundEmails ? foundEmails.join(', ') : '';
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0]
        setFile(file)
        handleUpload()
    }

    const handleUpload = (file) => {
        // event.preventDefault()
        // const file = event.target.files[0]
        setFile(file);
        console.log("handleUpload")
        console.log(file)
        if (file && file.name) {
            if (file && file.name && file.name.endsWith('.csv')) {
                console.log('CSV File')
                const reader = new FileReader()
                reader.onload = (event) => {
                    const content = event.target.result
                    setEmails((emails) => new Set([...(findEmails(content)), ...emails]))
                    console.log(emails)

                }
                reader.readAsText(file)
            } else if (
                (file && file.name && file.name.endsWith('.xls')) ||
                file.name.endsWith('.xlsx')
            ) {
                console.log('Excel file')
                var name = file.name
                const reader = new FileReader()
                reader.onload = (evt) => {
                    const bstr = evt.target.result
                    const wb = XLSX.read(bstr, { type: 'binary' })
                    const wsname = wb.SheetNames[0]
                    const ws = wb.Sheets[wsname]
                    const data = XLSX.utils.sheet_to_csv(ws, { header: 1 })
                    setEmails((emails) => new Set([...(findEmails(data)), ...emails]))
                    console.log(emails)
                }
                reader.readAsBinaryString(file)
            }
            console.log(file.name)
        }
        console.log("EMAIL PRINTING STARTS")
        console.log(email)
        console.log("EMAIL PRINTING ENDS")
    }

    const handleDrop = (e)=>{
        e.preventDefault();
        console.log(e)
        setDraggedOver(false);
        if (e.dataTransfer.items) {
            [...e.dataTransfer.items].forEach((item, i) => {
            if (item.kind === "file") {
                const file = item.getAsFile();
                handleUpload(file);
            }
            });
        } else {
            [...e.dataTransfer.files].forEach((file, i) => {
                handleUpload(file);
            });
        }
    }

    useEffect(() => {
        handleSearchData()
    }, [dispatch, searchData])

    const checkSubAddresing = (email) => {
        if (email.indexOf('+') === -1) return false
        else return true
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        var str = ''
        const ppl = courseData.people
        var n = ppl.length
        emails.forEach((x) => {
            var n2 = ppl.length
            var chk = true
            for (var j = 0; j < n2; j++) {
                if (ppl[j].email === x) {
                    chk = false
                    break
                }
            }
            if (x === userEmail || !chk) {
                alert('Same user can not be added again.')
            } else if (checkSubAddresing(x)) {
                // console.log("Subaddressing")
                alert('The platform does not allow sub addressing.')
            } else if (validateEmail(x)) {
                sendSearchDetails(dispatch, x)
            } else {
                str = str + x + ' '
            }
        })
        if (str !== '') alert('Invalid emails: ' + str)
        // window.location.reload(false);
        setTimeout(() => {
            window.location.reload()
        }, 1000)
        // forceUpdate();
        setRefreshKey((prevKey) => prevKey + 1)
    }

    const browseFiles = () => {
        document.getElementById('file-input').click();
    }

    return (
        <div>
            <Button
                onClick={handleOpen}
                style={{
                    background: 'transparent',
                    outline: 'none',
                    outlineOffset: 'none',
                }}
            >
                <GroupAddIcon style={{ color: 'black' }} />
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={`${styles['modal-container']}`}>
                    <div className={`${styles['row']}`}>
                        <h1>Add Participants</h1>
                        <ClearIcon 
                            onClick={handleClose}
                            sx={{
                                fontSize: "2.5em",
                                cursor: "pointer"
                            }}
                        />
                    </div>
                    <div className={`${styles['modal-input-container']}`}>
                        <div className={`${styles['tabs']}`}>
                            <div className={`${styles['tab']} ${activeTab === 0 ? styles['active'] : ''}`} onClick={()=>{setActiveTab(0)}}>Using Email</div>
                            <div className={`${styles['tab']} ${activeTab === 1 ? styles['active'] : ''}`} onClick={()=>{setActiveTab(1)}}>Using CSV File</div>
                        </div>
                        <div className={`${styles['modal-input']}`}>
                            {
                                activeTab === 0 ? (
                                    <div className={`${styles['email-input-container']}`}>
                                        <input 
                                            placeholder='Enter E-Mail' 
                                            className={`${styles['email-input']}`} 
                                            type='email'
                                            value={email}
                                            onChange={(e)=>{setEmail(e.target.value)}}
                                        />
                                        <AddBoxIcon 
                                            className={`${styles['email-add-btn']}`}
                                            sx={{
                                                fontSize: "2.5em",
                                            }}
                                            onClick={()=>{
                                                setEmails((emails)=>(
                                                    new Set([email, ...emails])
                                                ))
                                            }} 
                                        />
                                    </div>
                                ) : (
                                    <div 
                                        style={draggableStyles}
                                        className={`${styles['droppable-area']}`}
                                        onDrop={handleDrop} 
                                        onDragOver={(e)=>{
                                            e.preventDefault();
                                            setDraggedOver(true);
                                        }}
                                        onDragLeave={(e)=>{
                                            e.preventDefault();
                                            setDraggedOver(false);
                                        }}
                                    >
                                        <UploadFileIcon
                                            sx={{
                                                fontSize: "3em"
                                            }}
                                        />
                                        <h4>{`${draggedOver ? '' : 'Drag and'} Drop File`} </h4>
                                        <h6>or <span className={`${styles['browse-files-link']}`} onClick={browseFiles}>browse files</span> from device</h6>
                                        <h6 className={`${styles['supported-extenstions']}`}>Supports .csv, .xls, .xlsx</h6>
                                        {file && <h6 className={`${styles['file-name']}`}> <DescriptionIcon /> '{file.name}' uploaded</h6>}
                                        <input 
                                            ref={fileInputRef}
                                            id="file-input" 
                                            onChange={() => {
                                                console.log(fileInputRef)
                                                console.log(fileInputRef.current.files)
                                                const file = fileInputRef.current.files[0];
                                                handleUpload(file); 
                                            }} 
                                            type='file' 
                                            accept=".xls,.xlsx,.csv" 
                                            hidden
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {
                        emails.size !== 0 &&
                        <>
                            <h3>Emails</h3>
                            <div className={`${styles['emails-list']}`}>
                                {
                                    Array.from(emails).map((email, i) => (
                                        <div className={`${styles['email']} ${i % 2 === 0 ? styles['dark'] : ''}`}>
                                            {email}
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    }
                    <div className={`${styles['row-center']}`}>
                        <a
                            className={`btn btn-primary ${styles['add-to-course-btn']}`}
                            onClick={handleSubmit}
                            style={{
                                color: 'white'
                            }}
                        >
                            Add to course
                        </a>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

/* eslint-disable */
