// Virtual TA ask question action types
export const VIRTUAL_TA_ASK_QUESTION_REQUEST = 'VIRTUAL_TA_ASK_QUESTION_REQUEST';
export const VIRTUAL_TA_ASK_QUESTION_SUCCESS = 'VIRTUAL_TA_ASK_QUESTION_SUCCESS';
export const VIRTUAL_TA_ASK_QUESTION_FAILURE = 'VIRTUAL_TA_ASK_QUESTION_FAILURE';

// Virtual TA get all questions action types
export const VIRTUAL_TA_GET_ALL_QUESTIONS_REQUEST = 'VIRTUAL_TA_GET_ALL_QUESTIONS_REQUEST';
export const VIRTUAL_TA_GET_ALL_QUESTIONS_SUCCESS = 'VIRTUAL_TA_GET_ALL_QUESTIONS_SUCCESS';
export const VIRTUAL_TA_GET_ALL_QUESTIONS_FAILURE = 'VIRTUAL_TA_GET_ALL_QUESTIONS_FAILURE';

// Set question action type
export const SET_QUESTION = 'SET_QUESTION';

// Set conversation history action type
export const SET_CONVERSATION_HISTORY = 'SET_CONVERSATION_HISTORY';

// Set weak concepts action type
export const SET_WEAK_CONCEPTS = 'SET_WEAK_CONCEPTS';
