import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { verifyUserVerificationToken } from '../redux'
import getQueryStringParams from '../utils/queryParams'
import TopLoader from './commons/TopLoader'

function VerifyUser(props) {
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const userVerificationData = useSelector(
        (state) => state.userVerificationData
    )
    const {
        // eslint-disable-next-line react/prop-types
        location: { search: searchParams },
    } = props
    useEffect(() => {
        if (userData.userFetchCompleted && userData.user === null) {
            const queryParams = getQueryStringParams(searchParams)
            const token = queryParams.token || ''
            dispatch(verifyUserVerificationToken(token))
        }
    }, [dispatch, searchParams, userData])

    if (userData && userData.user && userData.user.id !== undefined) {
        return <Redirect to="/" />
    }

    let loadingMessage = null
    if (userData.loading) {
        loadingMessage = userData.loadingMessage
    } else if (userVerificationData.loading) {
        loadingMessage = userVerificationData.loadingMessage
    }

    return (
        <>
            {loadingMessage ? <TopLoader message={loadingMessage} /> : null}
            <div className="container main-container mt-4 mb-5">
                {userVerificationData.error ? (
                    <h4 className="alert alert-danger mt-4">
                        {userVerificationData.error}
                    </h4>
                ) : (
                    ''
                )}
                {userVerificationData.message ? (
                    <h4 className="alert alert-success mt-4">
                        {userVerificationData.message}
                    </h4>
                ) : (
                    ''
                )}
            </div>
        </>
    )
}

export default VerifyUser
