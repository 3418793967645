/* eslint-disable */

import axios from 'axios'
import {
    QUIZ_CLEAR,
    QUIZ_FAILURE,
    QUIZ_REQUEST,
    QUIZ_SUCCESS,
} from './quizTypes'

export const quizRequest = () => ({
    type: QUIZ_REQUEST,
})

export const quizSuccess = (quiz) => ({
    type: QUIZ_SUCCESS,
    payload: quiz,
})

export const quizFailure = (errorMsg) => ({
    type: QUIZ_FAILURE,
    payload: errorMsg,
})

export const quizClear = () => ({
    type: QUIZ_CLEAR,
})

export const fetchQuiz = (id) => (dispatch) => {
    dispatch(quizRequest())

    axios
        .get(`${process.env.REACT_APP_BASE_URL}/quiz/${id}`)
        .then((res) => {
            console.log(res.data)
            const quiz = res.data
            console.log(quiz, '---')
            if (!quiz || quiz.length === 0) {
                dispatch(quizFailure('Quiz not found'))
            } else {
                dispatch(quizSuccess(quiz))
            }
        })
        .catch((err) => {
            dispatch(
                quizFailure(
                    (err.response && err.response.data) ||
                        'Error loading the quiz'
                )
            )
        })
}

export const updateQuiz = (data) => async (dispatch) => {
    dispatch(quizRequest())

    const formData = new FormData()

    formData.append('active', true)
    formData.append('isPublished', data.publish)
    formData.append('deleted', data.deleted)
    formData.append('data', JSON.stringify(data.newQuiz))

    if (
        data.filteredMountFiles &&
        Object.keys(data.filteredMountFiles).length > 0
    ) {
        console.log('hiii')
        for (const key of Object.keys(data.filteredMountFiles)) {
            formData.append(key, data.filteredMountFiles[key])
        }
    }

    axios({
        method: 'patch',
        url: `${process.env.REACT_APP_BASE_URL}/quiz/${data.id}`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
    })
        .then(() => {
            dispatch(quizSuccess(quizData))
        })
        .catch((error) => {
            dispatch(
                quizFailure(
                    (error.response && error.response.data) ||
                        'Updating quiz failed'
                )
            )
        })
}

export const updateQuizMetaData = (data, flag) => async (dispatch) => {
    dispatch(quizRequest())

    try {
        const response = await axios.patch(
            `${process.env.REACT_APP_BASE_URL}/quizMetaData/${data.id}`,
            {
                isPublished: data.publish,
                deleted: data.deleted,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        console.log(response)
        dispatch(quizSuccess(quizData))
    } catch (error) {
        dispatch(
            quizFailure(
                (error.response && error.response.data) ||
                    'Updating quiz failed'
            )
        )
    }
}

export const createQuiz =
    (quizData, isPublished, courseId, selectedFiles) => async (dispatch) => {
        console.log(selectedFiles)
        dispatch(quizRequest())
        const formData = new FormData()
        formData.append('quizData', JSON.stringify(quizData))
        formData.append('isPublished', isPublished)
        formData.append('courseId', courseId)
        if (selectedFiles && Object.keys(selectedFiles).length > 0) {
            for (const key of Object.keys(selectedFiles)) {
                formData.append(key, selectedFiles[key])
            }
        }
        try {
            await axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/quiz`,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            dispatch(quizSuccess(quizData))
        } catch (error) {
            dispatch(
                quizFailure(
                    (error.response && error.response.data) ||
                        'Failed to create quiz'
                )
            )
        }
    }

export const generateQuiz = (quizData) => async (dispatch) => {
    dispatch(quizRequest());

    try {
        const response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/quiz/generate-quiz`,
            headers: {
            'Content-Type': 'application/json'
            },
            data: JSON.stringify(quizData)
        });
        const result = await response.data;
        console.log('Quiz Generated:', result);
        dispatch(quizSuccess(result));
        return result;
    } catch (err) {
        dispatch(
            quizFailure(
                (err.response && err.response.data) || 'Error generating the quiz'
            )
        );
    }
}