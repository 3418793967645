/*eslint-disable*/

import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Menu from '@mui/material/Menu'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography'
import ListItemText from '@mui/material/ListItemText';
import * as XLSX from 'xlsx';
import * as Papa from 'papaparse';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Requests from './Requests';



export default function ManageUsers() {
  const [isDeleteAlertOpen, setDeleteAlertOpen] = useState(false);

  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);

  const [emailsToBeAdded, setEmailsToBeAdded] = useState([]);

  const [emailPresenceMap, setEmailPresenceMap] = useState(new Map());

  const [noRegisteredUserEntered, setNoRegisterUserEntered] = useState(true);

  const [selectedFile, setSelectedFile] = useState(null);

  const [value, setValue] = React.useState('1');

  

  const userData = useSelector((state) => state.userData)

  const isAdminAccount = userData && userData.user && userData.user.email === process.env.REACT_APP_ADMIN_EMAIL_ADDRESS;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (!file) return;

    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (fileExtension === 'csv') {
      
      Papa.parse(file, {
        complete: (result) => {
          const parsedEmails = result.data.flat().filter(email => email.includes('@'));
          setEmailsToBeAdded(parsedEmails);

          
          checkEmailPresence(parsedEmails);
        },
        header: false,
      });
    } else if (fileExtension === 'xlsx') {
      
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const parsedEmails = sheetData.flat().filter(email => typeof email === 'string' && email.includes('@'));
        setEmailsToBeAdded(parsedEmails);

        
        checkEmailPresence(parsedEmails);
      };
      reader.readAsArrayBuffer(file);
    } else {
      alert('Unsupported file format. Please upload a .csv or .xlsx file.');
    }
  };

  const checkEmailPresence = async (emails) => {
    const mailPresenceMappingResp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/check-user-existence`, emails
    )
    const mailPresenceMapping = new Map(Object.entries(mailPresenceMappingResp.data));
    setEmailPresenceMap(mailPresenceMapping);

    setNoRegisterUserEntered(true);
    for (const [key, value] of mailPresenceMapping) {
      if (value === "present") {
        setNoRegisterUserEntered(false);
        break;
      }
    }
  }

  const DowngradeButtonComponent = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const menuOpen = Boolean(anchorEl)
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget)
    }
    const handleMenuClose = () => {
      setAnchorEl(null)
    }
    return (
      <>
        <Button
          id="basic-button"
          aria-controls={menuOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
          style={{
            border: 'none',
            cursor: 'pointer',
            borderRadius: '5px',
            height: '25px',
            fontWeight: 'bold',
            borderRadius: '100px',
            textAlign: 'left',
          }}
          onClick={handleClick}
        >
          <MoreVertIcon></MoreVertIcon>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              setAlertState(!isAlertOpen)
              props.value[0]
                ? setClickedRowRole('Instructor')
                : setClickedRowRole('Student')
              setClickedRowEmail(props.value[1])
              handleMenuClose()
            }}
          >
            {props.value[0] ? 'Revoke' : 'Grant'}
          </MenuItem>
          <MenuItem onClick={() => { setDeleteAlertOpen(!isDeleteAlertOpen); setClickedRowEmail(props.value[1]); handleMenuClose() }}>Remove</MenuItem>
        </Menu>
      </>
    )
  }
  const history = useHistory()
  const [isAlertOpen, setAlertState] = useState(false)
  const [clickedRowRole, setClickedRowRole] = useState('Student')
  const [clickedRowEmail, setClickedRowEmail] = useState('')

  const [colDefs, setColDefs] = useState([
    { field: 'Username', flex: 1 },
    { field: 'Email', flex: 1 },
    { field: 'Role', flex: 0.3 },
    {
      field: 'Actions',
      headerName: '',
      cellRenderer: DowngradeButtonComponent,
      flex: 0.25,
      sortable: false,
      filter: false,
      resizable: false,
    },
  ])

  const [curUserEmail,setCurUserEmail] = useState("");

  const [usersList, setUsersList] = useState([])
  const [rowData, setRowData] = useState([])
  const [reloadcnt, setReloadcnt] = useState(0)



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/display-users`
        )
        setUsersList(response.data)
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [reloadcnt])

  useEffect(() => {
    if (usersList.length > 0) {
      const rows = usersList.map((user) => ({
        Username: user.username,
        Email: user.email,
        Role: user.instructorAccess ? 'Instructor' : 'Student',
        Actions: [user.instructorAccess, user.email],
      }))
      setRowData(rows)
    }
  }, [usersList])
  const defaultColDef = useMemo(() => {
    return {
      filter: 'agTextColumnFilter',
      floatingFilter: true,
    }
  }, [])
  const handleClose = () => {
    setAlertState(false)
    setDeleteAlertOpen(false);
  }

  const handleContClick = async () => {
    setAlertState(false)

    if (clickedRowRole === 'Instructor') {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/revoke-instructor-access/${clickedRowEmail}`
      )

      window.location.reload()
    } else {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/upgrade-user/${clickedRowEmail}`
      )

      window.location.reload()
    }
  }

  const handleEmailTextChange = async (event) => {

    const text = event.target.value;
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
    const emails = text.match(emailRegex);
    setEmailsToBeAdded(emails);
    checkEmailPresence(emails);

  }

  const handleInviteClick = async () => {
    console.log(emailPresenceMap);
    const statusStr = await axios.post(`${process.env.REACT_APP_BASE_URL}/send-mails`, Object.fromEntries(emailPresenceMap));
    if (statusStr.data === "mails sent successfully") {
      alert("Mails sent successfully");
      setAddUserDialogOpen(false);
    }
    else {
      alert("Mailing Failed");
      setAddUserDialogOpen(false);
    }
  }



  const handleDeleteContClick = async () => {
    setDeleteAlertOpen(false);
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user-deletion/${clickedRowEmail}`);
    window.location.reload()
  }

  return (isAdminAccount? 
    (<>
      <React.Fragment>
        <div
          className={'ag-theme-quartz'}
          style={{
            height: '600px',
            marginLeft: '8%',
            marginRight: '8%',
            paddingBottom: '70px',
          }}
        >
          <div style={{ position: "relative", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <h2
              style={{
                color: '#007bff',

                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                margin: 0,
              }}
            >
              Manage Users
            </h2>

            <Fab onClick={() => { setAddUserDialogOpen(true) }} color='primary' style={{ marginRight: "40px", marginBottom: "5px" }}>
              <GroupAddIcon fontSize='large'></GroupAddIcon>
            </Fab>
          </div>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', height: '100%' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Registered users" value="1" />
                  <Tab label="Pending requests" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ height: '500px' }}>
                <Box sx={{ height: '100%' }}>
                  <AgGridReact
                    rowData={rowData}
                    columnDefs={colDefs}
                    defaultColDef={defaultColDef}
                    suppressRowClickSelection={true}
                    enableCellTextSelection={true} 
                  />
                </Box>
              </TabPanel>
              <TabPanel value="2"><Requests/></TabPanel>

            </TabContext>
          </Box>
        </div>
        <Modal
          open={addUserDialogOpen}
          onClose={() => { setAddUserDialogOpen(false); }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            component="form"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: 'none',
              borderRadius: "10px",
              boxShadow: 24,
              p: 4,
            }}
            noValidate
            autoComplete="off"
          >
            <h3 style={{ color: "#007bff", textAlign: "center", fontWeight: "bold" }}>Add Users</h3>
            <TextField
              id="outlined-multiline-static"
              label="Enter emails"
              multiline
              rows={4}
              style={{ width: "100%" }}
              onChange={handleEmailTextChange}
            />

            <div style={{ display: "flex", alignItems: "center", textAlign: "center" }}>
              <hr style={{ flex: "1", border: "none", borderTop: "1px solid #ccc", color: "#7b564d" }} />
              <span style={{ padding: "0 10px", fontWeight: "bold", color: "#7b564d" }}>OR</span>
              <hr style={{ flex: "1", border: "none", borderTop: "1px solid #ccc", color: "#7b564d" }} />
            </div>


            <Box display="flex" alignItems="center" justifyContent="center" >
              <Button
                variant="outlined"
                component="label"
                style={{ width: "100%" }}
                startIcon={<UploadFileIcon />}
              >
                Upload File
                <input
                  type="file"
                  hidden
                  accept=".csv, .xlsx"
                  onChange={handleFileChange}
                />
              </Button>
            </Box>

            {selectedFile && (
              <Typography variant="body1" ml={1}>
                {selectedFile.name}
              </Typography>
            )}

            <List
              sx={{ width: '100%', bgcolor: 'background.paper', display: "block", boxShadow: "2", height: "180px", overflow: "auto", marginTop: "30px", borderRadius: "5px" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader style={{ backgroundColor: "#f2f0f0", fontWeight: "bold", fontSize: "100%" }} component="div" id="nested-list-subheader">
                  Entered Emails:
                </ListSubheader>
              }
              dense={true}
            >
              {emailsToBeAdded ? emailsToBeAdded.map((email, index) => (
                <ListItemButton key={index} style={{ color: "black" }}>
                  <ListItemText style={{ display: "flex" }}>
                    <span style={emailPresenceMap.get(email) === 'present' ? { textDecoration: "line-through" } : {}}>
                      {email}
                    </span>
                    {emailPresenceMap.get(email) === 'present' && (
                      <span style={{ color: "red", fontSize: "smaller" }}>REGISTERED</span>
                    )}
                  </ListItemText>
                </ListItemButton>
              )) : <></>}
            </List>
            {noRegisteredUserEntered ? <></> : <Alert style={{ marginTop: "10px", height: "30px", padding: "0px", textAlign: "center", paddingLeft: "20px" }} severity="warning">Registered users won't get invitations.</Alert>}
            <div style={{ textAlign: "center", width: "100%", marginTop: "10px" }}>
              <Button
                onClick={() => { setAddUserDialogOpen(false); setEmailsToBeAdded([]); setEmailPresenceMap(new Map()); setNoRegisterUserEntered(true); setSelectedFile(null) }}
                style={{ flex: "1", width: "48%", marginRight: "4%" }}
                variant="contained"
              >
                Cancel
              </Button>
              <Button onClick={handleInviteClick} style={{ flex: "1", width: "48%" }} variant="contained">
                Invite
              </Button>
            </div>
          </Box>
        </Modal>
        <Dialog
          open={isAlertOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Are you sure?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {clickedRowRole === 'Student'
                ? `Grant instructor access to ${clickedRowEmail}.`
                : `Revoke instructor access for ${clickedRowEmail}.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleContClick} autoFocus>
              Continue
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isDeleteAlertOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Are you sure?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              User <b>{clickedRowEmail}</b> will be deleted permanently.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleDeleteContClick} autoFocus>
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </>):(
      <Fragment>
      <Dialog
        open={true}
      >
        <DialogTitle>UNAUTHORISED ACCESS</DialogTitle>
        <DialogActions>
          
          <Button onClick={()=>{history.push("/")}}>Back to homepage</Button>
        </DialogActions>
      </Dialog>
      </Fragment>
    )
  )
}
