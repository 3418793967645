import axios from 'axios'
import {
    QUIZ_QUESTION_FAILURE,
    QUIZ_QUESTION_REQUEST,
    QUIZ_QUESTION_SUCCESS,
} from './quizQuestionTypes'

export const quizQuestionRequest = () => ({
    type: QUIZ_QUESTION_REQUEST,
})

export const quizQuestionSuccess = (questions) => ({
    type: QUIZ_QUESTION_SUCCESS,
    payload: questions,
})

export const quizQuestionFailure = (errorMsg) => ({
    type: QUIZ_QUESTION_FAILURE,
    payload: errorMsg,
})

export const fetchQuizQuestions = () => (dispatch) => {
    dispatch(quizQuestionRequest())
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/quiz/question`)
        .then((res) => {
            const newQuizQuestions = res.data
            const quizQuestions = newQuizQuestions.map((question) => ({
                ...question,
                question_type: "multiple_choice_question",
                answers: question.answers.map((answer, index) => ({ _id : Math.random().toString(36) ,text: answer,
                    weight : question.correctAnswer.includes(index || index.toString()) ? 100 : 0,
                }))
            }));
            console.log(quizQuestions);
            if (!quizQuestions) {
                dispatch(quizQuestionFailure('No quiz available'))
            } else {
                dispatch(quizQuestionSuccess(quizQuestions))
            }
        })
        .catch((err) => {
            dispatch(
                quizQuestionFailure(
                    (err.response && err.response.data) ||
                        'Error fetching available quizzes'
                )
            )
        })
}

export const fetchAllQuizQuestions = () => (dispatch) => {
    dispatch(quizQuestionRequest())
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/quiz/all-question`)
        .then((res) => {
            const newQuizQuestions = res.data
            const quizQuestions = newQuizQuestions.map((question) => ({
                ...question,
                question_type: "multiple_choice_question",
                answers: question.answers.map((answer, index) => ({ _id : Math.random().toString(36) ,text: answer,
                    weight : question.correctAnswer.includes(index+1 || (index+1).toString()) ? 100 : 0,
                }))
            }));
            // console.log(quizQuestions);
            if (!quizQuestions) {
                dispatch(quizQuestionFailure('No quiz available'))
            } else {
                dispatch(quizQuestionSuccess(quizQuestions))
            }
        })
        .catch((err) => {
            dispatch(
                quizQuestionFailure(
                    (err.response && err.response.data) ||
                        'Error fetching available quizzes'
                )
            )
        })
}