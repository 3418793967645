/*eslint-disable*/
import { Plugin } from 'ckeditor5';
import InsertInputCommand from './InputCommand';

export default class InputEditing extends Plugin {
    init() {
        this._defineSchema();   
        this._defineConverters();
        this.editor.commands.add( 'insertInput', new InsertInputCommand( this.editor ) );
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register( 'input', {
            inheritAllFrom: '$inlineObject',
            allowAttributes: ['placeholder', 'id']
        } );
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        conversion.for( 'upcast' ).elementToElement( {
            view: {
                name: 'input',
                classes: 'question-input'
            },
            model: ( viewElement, { writer: modelWriter } ) => {
                return modelWriter.createElement( 'input', {
                    placeholder: viewElement.getAttribute( 'placeholder' ),
                    id: viewElement.getAttribute('id')
                } );
            }
        } );

        conversion.for( 'downcast' ).elementToElement( {
            model: 'input',
            view: ( modelElement, { writer: viewWriter } ) => {
                return viewWriter.createEditableElement( 'input', {
                    class: 'question-input',
                    'placeholder': modelElement.getAttribute( 'placeholder' ),
                    'id': modelElement.getAttribute('id')
                } );
            }
        } );
    }
}