/* eslint-disable */
import React, { useEffect } from 'react'
import {
    Card,
    CardContent,
    Box,
    Typography,
    Grid,
    Stack,
    Divider,
    Link,
} from '@mui/material'
import { Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import TopLoader from '../commons/TopLoader'
import { fetchCanvasData } from '../../redux'

const getRandomColor = () => {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
    }
    return color
}

const CourseCards = () => {
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const canvasData = useSelector((state) => state.canvasData)
    console.log(canvasData, '----')
    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            console.log('hiiiii')
            dispatch(fetchCanvasData())
        }
    }, [dispatch, userData.userFetchCompleted, userData.user])
    // console.log(canvasData)
    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (canvasData.loading) {
        return (
            <div className="container main-container mb-5">
                <TopLoader message={canvasData.loadingMessage} />
            </div>
        )
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }
    if (userData.user.role !== 'Instructor') {
        return (
            <div className="container main-container">
                <h4 className="alert alert-danger mt-4">
                    {
                        'To view this page, the user must log in as an instructor.'
                    }
                </h4>
            </div>
        )
    }
    var resultCourses = []
    console.log(canvasData.canvasData)
    if (
        canvasData.canvasData.length == 0 ||
        JSON.stringify(canvasData.canvasData) === '{}'
    ) {
        return (
            <div className="container main-container">
                <h4 className="alert alert-danger mt-4">
                    {
                        'Logged In user doesnot have any valid courses, Please check the canvas access token'
                    }
                </h4>
            </div>
        )
    }
    {
        canvasData.canvasData.length > 0 &&
            canvasData.canvasData.map((course, index) => {
                if (!course.access_restricted_by_date) {
                    if (
                        course.enrollments[0].role == 'TaEnrollment' ||
                        course.enrollments[0].role == 'TeacherEnrollment'
                    ) {
                        resultCourses.push(course)
                    }
                }
            })
    }

    return (
        <Stack spacing={2} marginBottom={50}>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <h1 className="container" style={{ paddingLeft: '11rem' }}>
                Canvas Courses
            </h1>
            <Divider />
            <Grid container spacing={2} paddingLeft={10} paddingRight={15}>
                {resultCourses.map((course, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card>
                            <Box
                                sx={{
                                    bgcolor: getRandomColor(),
                                    color: 'white',
                                    minHeight: 200,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {course.title}
                            </Box>
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                >
                                    <Link
                                        href={`/courses/canvas/${course.id}/home`}
                                        rel="noopener noreferrer"
                                    >
                                        {course.name}
                                    </Link>
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {course.course_code}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Stack>
    )
}

export default CourseCards
