/*eslint-disable*/
import axios from 'axios';
import {
    GET_REQUESTS_SUCCESS
} from './AdminTypes';

export const getRequestsSuccess = (userRequests) => ({
    type: GET_REQUESTS_SUCCESS,
    payload: userRequests,
})

export const getRequests = () => (dispatch) => {
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/requests/`)
        .then((res) => {
            const users = res.data;
            // console.log("testing users in front-end");
            console.log(users);
            if (users) {
                // console.log("Testing users in actions of front-end");
                // console.log(users);
                dispatch(getRequestsSuccess(users));
            }
        })
        .catch((err) => {
            console.log(err);
        })
}

export const deleteRequest = (id) => (dispatch) => {
    axios.delete(`${process.env.REACT_APP_BASE_URL}/requests/${id}`)
        .then(() => console.log("Successfully deleted!"))
        .catch((err) => {
            console.log(err)
        });
}

export const upgradeRequest = (id) => (dispatch) => {
    axios.patch(`${process.env.REACT_APP_BASE_URL}/requests/${id}`)
        .then(() => console.log("Success"))
        .catch((err) => {
            console.log(err)
        });
}