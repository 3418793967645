/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
    Box,
    Typography,
    Container,
    Button,
    Divider,
    Stack,
} from '@mui/material'
import { fetchCanvasData } from '../../redux'
import TopLoader from '../commons/TopLoader'
import Sidebar from './Sidebar'

const Home = () => {
    const { course_id: courseId } = useParams()
    // const [selectedItem, setSelectedItem] = useState('Home')

    // const handleListItemClick = (itemName) => {
    //     setSelectedItem(itemName)
    // }
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)

    const canvasData = useSelector((state) => state.canvasData)
    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            dispatch(fetchCanvasData(courseId))
        }
    }, [dispatch, userData.userFetchCompleted, userData.user])
    function formatDateString(dateString) {
        const dateObject = new Date(dateString)

        // Format the date to a custom string (adjust format as needed)
        const formattedDate = dateObject.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'UTC',
        })

        return formattedDate
    }

    // console.log(canvasData)
    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (canvasData.loading) {
        return (
            <div className="container main-container mb-5">
                <TopLoader message={canvasData.loadingMessage} />
            </div>
        )
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }
    if (userData.user.role !== 'Instructor') {
        return (
            <div className="container main-container">
                <h4 className="alert alert-danger mt-4">
                    {
                        'To view this page, the user must log in as an instructor.'
                    }
                </h4>
            </div>
        )
    }
    console.log(canvasData.canvasData)
    return (
        <Stack spacing={25} direction={'row'} marginBottom={50}>
            <Sidebar courseId={courseId} />
            <Box
                sx={{
                    // width: 240,
                    padding: 2,
                    marginBottom: 20,
                    height: '100vh',
                    display: 'flex',
                }}
            >
                <Container maxWidth="lg">
                    <Stack direction={'row'} spacing={10}>
                        <Typography
                            variant="h5"
                            component="h2"
                            sx={{ mb: 2, mt: 1 }}
                            fontWeight={'bold'}
                        >
                            Welcome to {canvasData.canvasData.course_code}
                        </Typography>
                        <Stack>
                            <Typography variant="h6" component="h2">
                                Start Date{' '}
                                {formatDateString(
                                    canvasData.canvasData.start_at
                                )}
                            </Typography>
                            <Typography
                                variant="h6"
                                component="h2"
                                sx={{ mb: 2 }}
                            >
                                End Date{' '}
                                {formatDateString(canvasData.canvasData.end_at)}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Divider />

                    <Typography
                        variant="h5"
                        component="p"
                        sx={{ mb: 4, mt: 2 }}
                    >
                        You are assigned as an instructor for this course
                    </Typography>
                    <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                        Get Started
                    </Button>
                    <Button variant="outlined" color="primary">
                        Learn More
                    </Button>
                </Container>
            </Box>
        </Stack>
    )
}

export default Home
