import axios from 'axios'
import {
    CONTACT_US_FAILURE,
    CONTACT_US_REQUEST,
    CONTACT_US_SUCCESS,
} from './contactUsTypes'

export const contactUsRequest = () => ({
    type: CONTACT_US_REQUEST,
})

export const contactUsSuccess = () => ({
    type: CONTACT_US_SUCCESS,
})

export const contactUsFailure = (errorMsg) => ({
    type: CONTACT_US_FAILURE,
    payload: errorMsg,
})

export const sendContactUsEmail = (name, email, message) => (dispatch) => {
    dispatch(contactUsRequest())
    axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/contact-us`,
        data: { name, email, message },
        headers: { 'Content-Type': 'application/json' },
    })
        .then(() => {
            dispatch(contactUsSuccess())
        })
        .catch((error) => {
            dispatch(
                contactUsFailure(
                    (error.response && error.response.data) ||
                        'Failed to contact us'
                )
            )
        })
}
