export default function getQueryStringParams(query) {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
              .split('&')
              .reduce((params, param) => {
                  /* eslint-disable no-param-reassign */
                  const [key, value] = param.split('=')
                  params[key] = value
                      ? decodeURIComponent(value.replace(/\+/g, ' '))
                      : ''
                  return params
              }, {})
        : {}
}
