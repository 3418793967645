import {GET_REQUESTS_SUCCESS} from './AdminTypes';

const initialState = {
    users: [],
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_REQUESTS_SUCCESS:
            // console.log("Within reducer");
            // console.log(action.payload);
            return {
                ...state,
                users: action.payload,
            }
        default:
            return state
    }
}

export default reducer