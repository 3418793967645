import {
    getCleanStandardCategory,
    getItemWeightsOfStandardCategory,
    getMaximumPointsOfStandardCategory,
    getStandardCategoryReducedData,
    getWeightOfStandardCategory,
    updateCategoriesWithTypeUpdateToStandard,
} from './standardCategoryHelper'
import {
    getCleanHybridCategory,
    getHybridCategoryReducedData,
    getItemWeightsOfHybridCategory,
    getMaximumPointsOfHybridCategory,
    getUpdatedHybridItemWeight,
    getWeightOfHybridCategory,
    updateCategoriesWithTypeUpdateToHybrid,
} from './hybridCategoryHelper'
import {
    getCleanPointsBasedCategory,
    getItemWeightsOfPointsBasedCategory,
    getMaximumPointsOfPointsBasedCategory,
    getPointsBasedCategoryReducedData,
    getUpdatedPointsBasedItemWeight,
    getWeightOfPointsBasedCategory,
    updateCategoriesWithTypeUpdateToPointsBased,
} from './pointsBasedCategoryHelper'
import { getDistributedValuesTotaling } from '../../../utils'
import { CATEGORY_WEIGHT_DECIMAL_ACCURACY } from '../../../utils/constants'

export const getCategoriesReducedData = (
    categories,
    achievingTargetGrade,
    gradesInAchievingTargetGrade
) => {
    let totalGrades = null
    let totalCategoriesWeight = 0
    let totalItemsWeight = 0
    let knownGradesSum = 0
    let knownGradesWeightSum = 0
    let unknownGradesWeightSum = 0
    let unknownGradesCount = 0
    let totalMaxPointsOfPointsBasedCategories = 0
    const pointsBasedCategoryIndexes = []
    const totalMaxPointsPerCategory = new Array(categories.length)
    const consumeCategoryReducedData = (categoryReducedData, categoryIdx) => {
        totalGrades += categoryReducedData.totalGrades
        totalItemsWeight += categoryReducedData.totalItemsWeight
        totalMaxPointsPerCategory[categoryIdx] =
            categoryReducedData.totalCategoryMaximumPoints
        totalCategoriesWeight += categoryReducedData.totalCategoryWeight
        knownGradesSum += categoryReducedData.knownGradesSum
        knownGradesWeightSum += categoryReducedData.knownGradesWeightSum
        unknownGradesWeightSum += categoryReducedData.unknownGradesWeightSum
        unknownGradesCount += categoryReducedData.unknownGradesCount
    }
    categories.forEach((category, categoryIdx) => {
        if (category.categoryType === 'POINTS_BASED') {
            pointsBasedCategoryIndexes.push(categoryIdx)
            totalMaxPointsOfPointsBasedCategories +=
                getMaximumPointsOfPointsBasedCategory(category)
            return
        }
        let categoryReduceFunction
        switch (category.categoryType) {
            case 'STANDARD':
                categoryReduceFunction = getStandardCategoryReducedData
                break
            case 'HYBRID':
                categoryReduceFunction = getHybridCategoryReducedData
                break
            default:
                categoryReduceFunction = getStandardCategoryReducedData
                break
        }
        consumeCategoryReducedData(
            categoryReduceFunction(
                category,
                achievingTargetGrade,
                gradesInAchievingTargetGrade[categoryIdx]
            ),
            categoryIdx
        )
    })
    const pointsBasedCategoriesWeightPerPoint =
        totalCategoriesWeight < 100 && totalMaxPointsOfPointsBasedCategories > 0
            ? (100 - totalCategoriesWeight) /
              totalMaxPointsOfPointsBasedCategories
            : 0
    pointsBasedCategoryIndexes.forEach((categoryIdx) => {
        consumeCategoryReducedData(
            getPointsBasedCategoryReducedData(
                categories[categoryIdx],
                achievingTargetGrade,
                gradesInAchievingTargetGrade[categoryIdx],
                pointsBasedCategoriesWeightPerPoint
            ),
            categoryIdx
        )
    })
    let projectedGrade = 0
    if (knownGradesWeightSum > 0) {
        projectedGrade =
            knownGradesSum > knownGradesWeightSum
                ? 1
                : knownGradesSum / knownGradesWeightSum
    }
    return {
        totalGrades, // Total grades can be actual grades or achievable grades
        totalItemsWeight,
        totalCategoriesWeight,
        totalMaxPointsPerCategory,
        knownGradesSum, // Similar to totalGrades but it's always sum of actual grades
        knownGradesWeightSum,
        unknownGradesWeightSum,
        unknownGradesCount,
        maxAchievableGrade: knownGradesSum + unknownGradesWeightSum,
        projectedGrade,
    }
}

export const getWeightsOfCategories = (categories) => {
    let totalNonPointsBasedCategoriesWeight = 0
    let totalPointsBasedCategoriesMaxPoints = 0
    const weights = Array(categories.length)
    const pointsBasedCategoryIndexes = []
    categories.forEach((category, idx) => {
        if (category.categoryType === 'POINTS_BASED') {
            pointsBasedCategoryIndexes.push(idx)
            totalPointsBasedCategoriesMaxPoints +=
                getMaximumPointsOfPointsBasedCategory(category)
            return
        }
        switch (category.categoryType) {
            case 'STANDARD':
                weights[idx] = getWeightOfStandardCategory(category)
                totalNonPointsBasedCategoriesWeight += weights[idx]
                break
            case 'HYBRID':
                weights[idx] = getWeightOfHybridCategory(category)
                totalNonPointsBasedCategoriesWeight += weights[idx]
                break
            default:
                throw new Error(
                    `Unrecognized category while getting categories weight at index ${idx}`
                )
        }
    })
    if (totalNonPointsBasedCategoriesWeight >= 100) {
        pointsBasedCategoryIndexes.forEach((idx) => {
            weights[idx] = 0
        })
    } else if (pointsBasedCategoryIndexes.length > 0) {
        const pointsBasedCategoriesWeights = pointsBasedCategoryIndexes.map(
            (idx) =>
                getWeightOfPointsBasedCategory(
                    categories[idx],
                    totalNonPointsBasedCategoriesWeight,
                    totalPointsBasedCategoriesMaxPoints
                )
        )
        const balancedPointsBasedCategoriesWeights =
            getDistributedValuesTotaling(
                100 - totalNonPointsBasedCategoriesWeight,
                pointsBasedCategoriesWeights.length,
                CATEGORY_WEIGHT_DECIMAL_ACCURACY,
                pointsBasedCategoriesWeights
            )
        let i = 0
        pointsBasedCategoryIndexes.forEach((idx) => {
            weights[idx] = balancedPointsBasedCategoriesWeights[i]
            i += 1
        })
    }
    return weights
}

export const getWeightOfCategoryAtIndex = (categories, idx) => {
    if (categories[idx].categoryType === 'POINTS_BASED') {
        return getWeightsOfCategories(categories)[idx]
    }
    switch (categories[idx].categoryType) {
        case 'STANDARD':
            return getWeightOfStandardCategory(categories[idx])
        case 'HYBRID':
            return getWeightOfHybridCategory(categories[idx])
        default:
            throw new Error(
                `Unrecognized category while getting category weight at index ${idx}`
            )
    }
}

export const getItemWeightsOfCategories = (categories) => {
    let totalNonPointsBasedCategoriesWeight = 0
    let totalPointsBasedCategoriesMaxPoints = 0
    const weights = Array(categories.length)
    const pointsBasedCategoryIndexes = []
    categories.forEach((category, idx) => {
        if (category.categoryType === 'POINTS_BASED') {
            pointsBasedCategoryIndexes.push(idx)
            totalPointsBasedCategoriesMaxPoints +=
                getMaximumPointsOfPointsBasedCategory(category)
            return
        }
        switch (category.categoryType) {
            case 'STANDARD':
                weights[idx] = getItemWeightsOfStandardCategory(category)
                totalNonPointsBasedCategoriesWeight +=
                    getWeightOfStandardCategory(category)
                break
            case 'HYBRID':
                weights[idx] = getItemWeightsOfHybridCategory(category)
                totalNonPointsBasedCategoriesWeight +=
                    getWeightOfHybridCategory(category)
                break
            default:
                throw new Error(
                    `Unrecognized category ${category.categoryType} while getting categories items weight at index ${idx}`
                )
        }
    })

    if (totalNonPointsBasedCategoriesWeight >= 100) {
        pointsBasedCategoryIndexes.forEach((idx) => {
            weights[idx] = new Array(categories[idx].items.length).fill(0)
        })
    } else if (pointsBasedCategoryIndexes.length > 0) {
        const pointsBasedCategoriesItemWeights = pointsBasedCategoryIndexes
            .map((idx) =>
                getItemWeightsOfPointsBasedCategory(
                    categories[idx],
                    totalNonPointsBasedCategoriesWeight,
                    totalPointsBasedCategoriesMaxPoints
                )
            )
            .flat()
        const balancedPointsBasedCategoriesItemWeights =
            getDistributedValuesTotaling(
                100 - totalNonPointsBasedCategoriesWeight,
                pointsBasedCategoriesItemWeights.length,
                CATEGORY_WEIGHT_DECIMAL_ACCURACY,
                pointsBasedCategoriesItemWeights
            )
        let i = 0
        pointsBasedCategoryIndexes.forEach((idx) => {
            weights[idx] = []
            for (let j = 0; j < categories[idx].items.length; j += 1) {
                weights[idx].push(balancedPointsBasedCategoriesItemWeights[i])
                i += 1
            }
        })
    }
    return weights
}

export const getItemWeightsOfCategoryAtIndex = (categories, idx) => {
    if (categories[idx].categoryType === 'POINTS_BASED') {
        return getItemWeightsOfCategories(categories)[idx]
    }
    switch (categories[idx].categoryType) {
        case 'STANDARD':
            return getItemWeightsOfStandardCategory(categories[idx])
        case 'HYBRID':
            return getItemWeightsOfHybridCategory(categories[idx])
        default:
            throw new Error(
                `Unrecognized category while getting category items weight at index ${idx}`
            )
    }
}

export const getTotalCategoriesWeights = (categories) =>
    getWeightsOfCategories(categories).reduce((sum, weight) => sum + weight, 0)

export const getMaximumPointsOfCategoryAtIndex = (category) => {
    switch (category.categoryType) {
        case 'STANDARD':
            return getMaximumPointsOfStandardCategory(category)
        case 'HYBRID':
            return getMaximumPointsOfHybridCategory(category)
        case 'POINTS_BASED':
            return getMaximumPointsOfPointsBasedCategory(category)
        default:
            throw new Error(
                `Unrecognized category${category} while getting category maximum points`
            )
    }
}

export const getMaximumPointsOfCategories = (categories) =>
    categories.map(getMaximumPointsOfCategoryAtIndex)

export const getTotalCategoriesMaximumPoints = (categories) =>
    getMaximumPointsOfCategories(categories).reduce(
        (sum, maxPoints) => sum + maxPoints,
        0
    )

export const getUpdatedWeightOfItem = (
    itemMaxPoints,
    categoryType,
    categoryMaxPoints,
    categoryWeight,
    itemMaxPointsDiff,
    totalNonPointsBasedCategoriesWeight,
    totalPointsBasedCategoriesMaxPoints
) => {
    switch (categoryType) {
        case 'POINTS_BASED':
            return getUpdatedPointsBasedItemWeight(
                itemMaxPoints,
                itemMaxPointsDiff,
                totalNonPointsBasedCategoriesWeight,
                totalPointsBasedCategoriesMaxPoints
            )
        case 'HYBRID':
            return getUpdatedHybridItemWeight(
                itemMaxPoints,
                categoryMaxPoints,
                categoryWeight
            )
        default:
            throw new Error('Unrecognized category while updating item weight')
    }
}

export const updateCategoriesWithTypeChanges = (
    categories,
    categoryIndex,
    updatedCategoryType
) => {
    switch (updatedCategoryType) {
        case 'HYBRID':
            updateCategoriesWithTypeUpdateToHybrid(categories, categoryIndex)
            break
        case 'POINTS_BASED':
            updateCategoriesWithTypeUpdateToPointsBased(
                categories,
                categoryIndex
            )
            break
        case 'STANDARD':
            updateCategoriesWithTypeUpdateToStandard(categories, categoryIndex)
            break
        default:
            throw new Error(
                'Unrecognized category while updating category type'
            )
    }
}

export const getCleanCategories = (categories) => {
    const cleanCategories = []
    categories.forEach((category) => {
        switch (category.categoryType) {
            case 'STANDARD':
                cleanCategories.push(getCleanStandardCategory(category))
                break
            case 'HYBRID':
                cleanCategories.push(getCleanHybridCategory(category))
                break
            case 'POINTS_BASED':
                cleanCategories.push(getCleanPointsBasedCategory(category))
                break
            default:
                throw new Error(
                    'Unrecognized category while cleaning categories'
                )
        }
    })
    return cleanCategories
}

// export const updatePointsBasedCategories = (categories) => {
//     let categoriesItemWeights;
//     categories.forEach((category, categoryIdx) => {
//         if (category.categoryType === 'POINTS_BASED') {
//             if (!categoriesItemWeights) {
//                 categoriesItemWeights = getItemWeightsOfCategories(categories);
//             }
//             for (let itemIdx = 0; itemIdx < category.items.length; itemIdx++) {
//                 category.items[itemIdx].weight = categoriesItemWeights[categoryIdx][itemIdx];
//             }
//         }
//     });
// }
