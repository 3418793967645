/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import {
    Box,
    Card,
    CardContent,
    Typography,
    Container,
    Stack,
    Divider,
    Link,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import LinkIcon from '@mui/icons-material/Link'
import { makeStyles } from '@mui/styles'
import { fetchModulesData, fetchCanvasData } from '../../redux'
import { useSelector, useDispatch } from 'react-redux'
import TopLoader from '../commons/TopLoader'
import Sidebar from './Sidebar'

const modules = [
    {
        title: 'Module 1',
        items: ['Item 1', 'Item 2', 'Item 3'],
    },
    {
        title: 'Module 2',
        items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
    },
    {
        title: 'Module 1',
        items: ['Item 1', 'Item 2', 'Item 3'],
    },
    {
        title: 'Module 2',
        items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
    },
    {
        title: 'Module 1',
        items: ['Item 1', 'Item 2', 'Item 3'],
    },
    {
        title: 'Module 2',
        items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
    },
    // Add more modules as needed
]
const useStyles = makeStyles({
    card: {
        // minWidth: 1000,
        border: '1px solid #e0e0e0',
        padding: 0,
        // margin: -10,
    },
    title: {
        fontSize: 20,
        backgroundColor: '#f5f5f5',
        padding: 15,
        margin: 0,
        color: '#333',
    },
    linkIcon: {
        marginLeft: 'auto',
    },
    bodyItem: {
        border: '1px solid #e0e0e0',
        padding: 10,
        transition: 'background-color 0.3s ease', // smooth transition
        '&:hover': {
            backgroundColor: '#f0f8ff', // light gray on hover
        },
        // fontSize: 20,
        margin: 0,
    },
})
const ModuleCards = () => {
    const { course_id: courseId } = useParams()
    // console.log(courseId)
    const [selectedItem, setSelectedItem] = useState('')
    const classes = useStyles()

    const handleItemClick = (item) => {
        setSelectedItem(item)
    }
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const modulesData = useSelector((state) => state.modulesData)
    const canvasData = useSelector((state) => state.canvasData)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            dispatch(fetchModulesData(courseId))
            dispatch(fetchCanvasData(courseId))
        }
    }, [dispatch, userData.userFetchCompleted, userData.user])

    console.log(modulesData)

    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }
    if (userData.user.role !== 'Instructor') {
        return (
            <div className="container main-container">
                <h4 className="alert alert-danger mt-4">
                    {
                        'To view this page, the user must log in as an instructor.'
                    }
                </h4>
            </div>
        )
    }
    if (modulesData.loading) {
        return (
            <div className="container main-container mb-5">
                <TopLoader message={modulesData.loadingMessage} />
            </div>
        )
    }

    if (canvasData.loading) {
        return (
            <div className="container main-container mb-5">
                <TopLoader message={canvasData.loadingMessage} />
            </div>
        )
    }
    // console.log(canvasData)
    return (
        <Stack spacing={20} direction={'row'} marginBottom={50}>
            <Sidebar courseId={courseId} />
            <Container maxWidth="lg">
                <Stack>
                    <Typography
                        variant="h5"
                        component="h2"
                        sx={{ mb: 2, mt: 1 }}
                        fontWeight={'bold'}
                    >
                        {canvasData.canvasData.course_code} : Modules
                    </Typography>

                    <Divider />
                    <Stack
                        container
                        spacing={2}
                        paddingRight={2}
                        maxHeight={'100%'}
                        fullWidth
                    >
                        {modulesData.modulesData.map((module, index) => (
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography
                                        className={classes.title}
                                        color="textSecondary"
                                        gutterBottom
                                    >
                                        {module.name}
                                    </Typography>
                                    {/* <Grid container spacing={2}> */}
                                    {module.items.map((item, itemIndex) => (
                                        <Link
                                            href={item.html_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                color: 'inherit',
                                                textDecoration: 'inherit',
                                            }}
                                        >
                                            <div className={classes.bodyItem}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexWrap: 'wrap',
                                                    }}
                                                >
                                                    <IconButton
                                                        aria-label="link"
                                                        // className={classes.linkIcon}
                                                    >
                                                        <LinkIcon />
                                                    </IconButton>
                                                    &nbsp;
                                                    <div>
                                                        <Typography
                                                            fontSize="1.1rem"
                                                            fontWeight="bold"
                                                        >
                                                            {item.title}
                                                        </Typography>
                                                        {/* <Typography variant="subtitle2">
                                                            {
                                                                quiz.points_possible
                                                            }{' '}
                                                            points |{' '}
                                                            {
                                                                quiz.question_count
                                                            }{' '}
                                                            questions
                                                        </Typography> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                        // <Grid item xs={12} key={itemIndex}>
                                        //     <Card
                                        //         onClick={() =>
                                        //             handleItemClick(item)
                                        //         }
                                        //         sx={{
                                        //             cursor: 'pointer',
                                        //             '&:hover': {
                                        //                 backgroundColor:
                                        //                     '#e0e0e0',
                                        //             },
                                        //         }}
                                        //     >
                                        //         <CardContent>
                                        //             <Link
                                        //                 href={item.html_url}
                                        //                 target="_blank"
                                        //                 rel="noopener noreferrer"
                                        //                 style={{
                                        //                     color: 'inherit',
                                        //                     textDecoration:
                                        //                         'inherit',
                                        //                 }}
                                        //             >
                                        //                 <Typography variant="body1">
                                        //                     {item.title}
                                        //                 </Typography>
                                        //             </Link>
                                        //         </CardContent>
                                        //     </Card>
                                        // </Grid>
                                    ))}
                                </CardContent>
                            </Card>
                        ))}
                        {/* <Typography variant="h6" sx={{ mt: 4 }}>
                        Selected Item: {selectedItem}
                    </Typography> */}
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    )
}

export default ModuleCards
