import React from 'react'
import { Provider } from 'react-redux'
import store from './redux/store'
import './css/App.css'
import './css/Mobile.css'
import Routes from './Routes'

function App() {
    console.log('Google OAuth Client ID:', process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID);
    return (
        <Provider store={store}>
            <div className="App">
                <Routes />
            </div>
        </Provider>
    )
}

export default App
