/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import {
    Box,
    Card,
    CardContent,
    Typography,
    Grid,
    Stack,
    Divider,
    Button,
    Container,
} from '@mui/material'
import {
    fetchAssignmentData,
    fetchCanvasData,
    fetchAssignmentGroupData,
} from '../../redux'
import { useSelector, useDispatch } from 'react-redux'
import TopLoader from '../commons/TopLoader'
import Sidebar from './Sidebar'
import { makeStyles } from '@mui/styles'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import LinkIcon from '@mui/icons-material/Link'
import Link from '@mui/material/Link'

const useStyles = makeStyles({
    card: {
        // minWidth: 1000,
        border: '1px solid #e0e0e0',
        padding: 0,
        // margin: -10,
    },
    title: {
        fontSize: 20,
        backgroundColor: '#f5f5f5',
        padding: 15,
        margin: 0,
        color: '#333',
    },
    linkIcon: {
        marginLeft: 'auto',
    },
    bodyItem: {
        border: '1px solid #e0e0e0',
        padding: 10,
        transition: 'background-color 0.3s ease', // smooth transition
        '&:hover': {
            backgroundColor: '#f0f8ff', // light gray on hover
        },
        // fontSize: 20,
        margin: 0,
    },
})
const CanvasAssignments = () => {
    const { course_id: courseId } = useParams()
    const classes = useStyles()

    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const canvasData = useSelector((state) => state.canvasData)
    const assignmentGroupData = useSelector(
        (state) => state.assignmentGroupData
    )
    const assignmentData = useSelector((state) => state.assignmentData)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            dispatch(fetchAssignmentData(courseId))
            dispatch(fetchCanvasData(courseId))
            dispatch(fetchAssignmentGroupData(courseId))
        }
    }, [dispatch, userData.userFetchCompleted, userData.user])

    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }
    if (userData.user.role !== 'Instructor') {
        return (
            <div className="container main-container">
                <h4 className="alert alert-danger mt-4">
                    {
                        'To view this page, the user must log in as an instructor.'
                    }
                </h4>
            </div>
        )
    }
    if (assignmentData.loading) {
        return (
            <div className="container main-container mb-5">
                <TopLoader message={assignmentData.loadingMessage} />
            </div>
        )
    }

    if (canvasData.loading) {
        return (
            <div className="container main-container mb-5">
                <TopLoader message={canvasData.loadingMessage} />
            </div>
        )
    }
    const DateTimeConverter = (date) => {
        if (date == null) {
            return 'closed'
        }
        const utcDate = new Date(date)
        const localDate = new Date(
            utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
        )
        const formattedDate = localDate.toLocaleString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        })
        return 'Due ' + formattedDate
    }
    console.log(assignmentGroupData)
    return (
        <Stack spacing={30} direction={'row'} marginBottom={200}>
            <Sidebar courseId={courseId} />
            <Container maxWidth="lg">
                <Stack spacing={2}>
                    <Typography
                        variant="h5"
                        component="h2"
                        sx={{ mb: 2, mt: 1 }}
                        fontWeight={'bold'}
                    >
                        {canvasData.canvasData.course_code} : Assignments
                    </Typography>

                    <Divider />
                    <Stack
                        container
                        spacing={2}
                        paddingRight={2}
                        maxHeight={'100%'}
                        fullWidth
                    >
                        {/* <Link href={`/canvas/courses/${courseId}/quiz/edit`}>
                            <Button
                                startIcon={<AddIcon />}
                                variant="contained"
                                align="right"
                                sx={{ marginLeft: 'auto' }}
                            >
                                Quiz
                            </Button>
                        </Link> */}
                        {assignmentGroupData.assignmentGroupData.map(
                            (assignmentGroup, index) => (
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Grid
                                            container
                                            className={classes.title}
                                        >
                                            <Grid item>
                                                {' '}
                                                <Typography
                                                    className={classes.title}
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    {assignmentGroup.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Grid
                                                    container
                                                    direction="row-reverse"
                                                >
                                                    <Grid
                                                        item
                                                        style={{
                                                            border: '1px solid black',
                                                            overflow: 'hidden',
                                                            borderRadius:
                                                                '50px',
                                                        }}
                                                    >
                                                        {' '}
                                                        <Typography
                                                            className={
                                                                classes.title
                                                            }
                                                            style={{
                                                                fontSize:
                                                                    '12px',
                                                            }}
                                                            color="textSecondary"
                                                            gutterBottom
                                                        >
                                                            {
                                                                assignmentGroup.group_weight
                                                            }{' '}
                                                            % of total
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {assignmentData.assignmentData.map(
                                            (assignment, index) =>
                                                assignment.assignment_group_id ==
                                                assignmentGroup.id ? (
                                                    <Link
                                                        href={
                                                            assignment.html_url
                                                        }
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{
                                                            color: 'inherit',
                                                            textDecoration:
                                                                'inherit',
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                classes.bodyItem
                                                            }
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    flexWrap:
                                                                        'wrap',
                                                                }}
                                                            >
                                                                <IconButton
                                                                    aria-label="link"
                                                                    // className={classes.linkIcon}
                                                                >
                                                                    <LinkIcon />
                                                                </IconButton>
                                                                &nbsp;
                                                                <div>
                                                                    <Typography
                                                                        fontSize="1.1rem"
                                                                        fontWeight="bold"
                                                                    >
                                                                        {
                                                                            assignment.name
                                                                        }
                                                                    </Typography>
                                                                    <Typography variant="subtitle2">
                                                                        {DateTimeConverter(
                                                                            assignment.due_at
                                                                        )}{' '}
                                                                        |{' '}
                                                                        {
                                                                            assignment.points_possible
                                                                        }{' '}
                                                                        points
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ) : null
                                        )}
                                    </CardContent>
                                </Card>
                            )
                        )}
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    )
}

export default CanvasAssignments
