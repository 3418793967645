import {
    CONTACT_US_FAILURE,
    CONTACT_US_REQUEST,
    CONTACT_US_SUCCESS,
} from './contactUsTypes'

const initialState = {
    loading: false,
    loadingMessage: '',
    message: '',
    error: '',
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CONTACT_US_REQUEST:
            return {
                ...state,
                loading: true,
                loadingMessage: 'Contacting us...',
                message: '',
                error: '',
            }
        case CONTACT_US_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                message: 'Thank you for contacting us!',
                error: '',
            }
        case CONTACT_US_FAILURE:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                message: '',
                error: action.payload,
            }
        default:
            return state
    }
}

export default reducer
