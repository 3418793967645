import axios from 'axios'
import {
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_GENERATE_FAILURE,
    RESET_PASSWORD_GENERATE_REQUEST,
    RESET_PASSWORD_GENERATE_SUCCESS,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
} from './resetPasswordTypes'

export const resetPasswordGenerateRequest = () => ({
    type: RESET_PASSWORD_GENERATE_REQUEST,
})

export const resetPasswordGenerateSuccess = () => ({
    type: RESET_PASSWORD_GENERATE_SUCCESS,
})

export const resetPasswordGenerateFailure = (errorMsg) => ({
    type: RESET_PASSWORD_GENERATE_FAILURE,
    payload: errorMsg,
})

export const resetPasswordRequest = () => ({
    type: RESET_PASSWORD_REQUEST,
})

export const resetPasswordSuccess = () => ({
    type: RESET_PASSWORD_SUCCESS,
})

export const resetPasswordFailure = (errorMsg) => ({
    type: RESET_PASSWORD_FAILURE,
    payload: errorMsg,
})

export const generateResetPasswordEmail = (email) => (dispatch) => {
    dispatch(resetPasswordGenerateRequest())
    axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/reset-password/generate`,
        data: { email },
        headers: { 'Content-Type': 'application/json' },
    })
        .then(() => {
            dispatch(resetPasswordGenerateSuccess())
        })
        .catch((error) => {
            dispatch(
                resetPasswordGenerateFailure(
                    (error.response && error.response.data) ||
                        'Failed to reset password'
                )
            )
        })
}

export const resetPassword =
    (resetPasswordToken, newPassword) => (dispatch) => {
        dispatch(resetPasswordRequest())
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/reset-password`,
            data: { token: resetPasswordToken, password: newPassword },
            headers: { 'Content-Type': 'application/json' },
        })
            .then(() => {
                dispatch(resetPasswordSuccess())
            })
            .catch((error) => {
                dispatch(
                    resetPasswordFailure(
                        (error.response && error.response.data) ||
                            'Failed to reset password'
                    )
                )
            })
    }
