import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Snackbar } from '@mui/material'

export default function Notification({ show, onClose, severity, message }) {
    return (
        <Snackbar
            key="notification"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={6000}
            onClose={onClose}
            open={show}
        >
            <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
}

Notification.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    severity: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
    message: PropTypes.string,
}

Notification.defaultProps = {
    show: false,
    onClose: () => {},
    severity: 'info',
    message: '',
}
