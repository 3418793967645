/* eslint-disable */

import { React } from 'react'

// import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card'
import Button from '@mui/material/Button'
import EmailIcon from '@mui/icons-material/Email'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import GitHubIcon from '@mui/icons-material/GitHub'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FullNotificationModal from './FullNotificationModal'

function NotificationCard({ Notifications }) {
    return (
        <Card
            style={{
                width: '20rem',
                height: 'fit-content',
                position: 'absolute',
                zIndex: '999',
                background: 'white',
                boxShadow: '0px 0px 0.5px 0.5px grey',
                padding: '0rem',
                margin: '0rem',
            }}
        >
            {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
            <Card.Body>
                <Card.Title style={{ color: 'black' }}>
                    Notifications
                </Card.Title>
                <hr style={{ width: '2000' }}></hr>
                <Card.Text style={{ color: 'grey' }}>
                    <ul style={{ listStyleType: 'none' }}>
                        {Notifications.map((notif) =>
                            !notif.seen ? (
                                <li style={{ fontWeight: 'bold' }}>
                                    <FullNotificationModal
                                        start={
                                            notif.notification.length > 30
                                                ? notif.notification.slice(
                                                      0,
                                                      30
                                                  ) + '...'
                                                : notif.notification
                                        }
                                        full={notif.notification}
                                    />
                                </li>
                            ) : (
                                <li>
                                    <FullNotificationModal
                                        start={
                                            notif.notification.length > 100
                                                ? notif.notification.slice(
                                                      0,
                                                      25
                                                  ) + '...'
                                                : notif.notification
                                        }
                                        full={notif.notification}
                                    />
                                </li>
                            )
                        )}
                    </ul>
                </Card.Text>
                {/* <a href = '' style = {{color: 'black'}}><GitHubIcon/></a> */}
                {/* <Button variant="primary">Go somewhere</Button> */}
            </Card.Body>
        </Card>
    )
}

export default NotificationCard

// StudentCard.propTypes = {
//     student: PropTypes.string.isRequired,
// }
