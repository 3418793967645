/* eslint-disable */

import React, { useEffect } from 'react'
import { Stack } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { fetchQuizCategoryMetas } from '../../redux'
import '../../css/Card.css'
import Button from '@mui/material/Button'

import AddIcon from '@mui/icons-material/Add'

function QuizCategoryList() {
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const quizCategoryMetaData = useSelector(
        (state) => state.quizCategoryMetaData
    )

    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            dispatch(fetchQuizCategoryMetas())
        }
    }, [dispatch, userData.userFetchCompleted, userData.user])

    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }

    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }

    if (quizCategoryMetaData.error) {
        return (
            <div className="container main-container">
                <h4 className="alert alert-danger mt-4">
                    {quizCategoryMetaData.error}
                </h4>
            </div>
        )
    }

    if (
        quizCategoryMetaData.quizCategoryMetas.length === 0 ||
        Object.keys(quizCategoryMetaData.quizCategoryMetas).length === 0
    ) {
        return (
            <div className="container main-container">
                {userData.user.role === 'Instructor' ? (
                    <a href="/quiz/manage" style={{ textDecoration: 'none' }}>
                        <Button
                            startIcon={<AddIcon />}
                            variant="contained"
                            style={{ width: '200px', float: 'right' }}
                        >
                            Create Quiz
                        </Button>
                    </a>
                ) : null}
                <br />
                <h4 className="alert alert-light mt-4">
                    No quiz available currently.
                </h4>
            </div>
        )
    }
    console.log(
        Object.keys(quizCategoryMetaData.quizCategoryMetas).length === 0
    )
    const categoryCards = Object.keys(
        quizCategoryMetaData.quizCategoryMetas
    ).map((quizCategory) => {
        const { quizCount } =
            quizCategoryMetaData.quizCategoryMetas[quizCategory]
        return (
            <div key={quizCategory} className="basic-card card">
                <div className="card-body meta-container">
                    <h5 className="card-title">{quizCategory}</h5>
                    <p className="card-text">{`${quizCount} quiz${
                        quizCount > 1 ? 'zes' : ''
                    }`}</p>
                    <a
                        className="btn btn-primary"
                        href={`/quizzes/${encodeURI(quizCategory)}`}
                    >
                        Explore
                    </a>
                </div>
            </div>
        )
    })

    return (
        <Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                paddingLeft="10rem"
                paddingRight="5rem"
            >
                <h1 className="container">Quizzes</h1>
                {userData.user.role === 'Instructor' ? (
                    <a href="/quiz/manage" style={{ textDecoration: 'none' }}>
                        <Button
                            startIcon={<AddIcon />}
                            variant="contained"
                            style={{ width: '200px' }}
                        >
                            Create Quiz
                        </Button>
                    </a>
                ) : null}
            </Stack>
            <div
                className="container main-container mt-4 mb-5 row"
                style={{ margin: 'auto' }}
            >
                {categoryCards}
            </div>
        </Stack>
    )
}

export default QuizCategoryList
