import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { googleLogin as googleLoginRedux } from '../redux'

function GoogleLogin({ googleLogin }) {
    useEffect(() => {
        // eslint-disable-next-line no-undef
        google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
            callback: (response) => {
                googleLogin(response.credential)
            },
        })
        // eslint-disable-next-line no-undef
        google.accounts.id.renderButton(document.getElementById('signInDiv'), {
            theme: 'outline',
            size: 'large',
        })
    }, [])

    return <div id="signInDiv" style={{ textAlign: '-webkit-center' }} />
}

GoogleLogin.propTypes = {
    googleLogin: PropTypes.func.isRequired,
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        googleLogin: (jwtToken) => dispatch(googleLoginRedux(jwtToken)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleLogin)
