import {
    FETCH_QUESTIONS,
    ADD_QUESTION_SUCCESS,
    ADD_QUESTION_FAILURE,
    DELETE_QUESTIONS,
    EDIT_QUESTIONS,
} from './questionBankTypes'

const initialState = {
    questions: [],
    status: null,
    error: null,
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case FETCH_QUESTIONS:
            // console.log("Within reducer");
            // console.log(action.payload);
            return {
                ...state,
                questions: action.payload,
            }
        case ADD_QUESTION_SUCCESS:
            return {
                ...state,
                status: action.payload,
                error: null,
            }
        case ADD_QUESTION_FAILURE:
            return {
                ...state,
                status: null,
                error: action.payload,
            }
        case DELETE_QUESTIONS:
            return {
                ...state,
                deletedQuestion : action.payload
            }
        case EDIT_QUESTIONS:
            return {
                ...state,
                updatedQuestion : action.payload
            }
        default:
            return state
    }
}

export default reducer
