/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Configuration, OpenAIApi } from 'openai'
import { useDispatch, useSelector } from 'react-redux'
import EditIcon from '@mui/icons-material/Edit'
import EditOffIcon from '@mui/icons-material/EditOff'
import {
    TextField,
    Typography,
    Button,
    CircularProgress,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material'
import { pdfjs, Document, Page } from 'react-pdf'
import axios from 'axios'
import { setErrorForKey, updateCourseFields } from '../../../../redux'
import { addAnchorTagsForLinksInText } from '../../../../utils'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
const AWS = require('aws-sdk')

const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
})

const { GoogleGenerativeAI } = require('@google/generative-ai')
const genAI = new GoogleGenerativeAI(process.env.REACT_APP_PALM_API_KEY)

const openai = new OpenAIApi(configuration)

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: 'us-east-1',
})

const S3_BUCKET_NAME = 'myacademic-syllabusfiles'

export default function Syllabus({ courseId, courseSyllabus }) {
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const errorData = useSelector((state) => state.errorData)
    const [enableSyllabusEdit, setEnableSyllabusEdit] = useState(false)
    const [pdfText, setPdfText] = useState('')
    const [iframeKey, setIframeKey] = useState(0)
    const [uploadedSyllabus, setUploadedSyllabus] = useState(null) // Store the uploaded file data
    const [tempPdfUrl, setTempPdfUrl] = useState(null) // Temporary URL for rendering PDF
    const iframeRef = useRef(null)
    const [enableSyllabusTextEdit, setEnableSyllabusTextEdit] = useState(false)
    const [searchedSyllabusText, setSearchedSyllabusText] = useState('')
    const [fileExists, setFileExists] = useState(true)
    const model = genAI.getGenerativeModel({ model: 'gemini-pro' })
    const [loading, setLoading] = useState(false)
    const [role, setRole] = useState(null)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            setRole(userData.user.role)
            if (userData.user.firstName === 'Admin') {
                setRole('Admin')
            }
        }
    }, [dispatch, userData.userFetchCompleted, userData.user])

    useEffect(() => {
        async function fetchData() {
            try {
                const coursedetails = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/course/${courseId}`
                )
                const { syllabustext } = coursedetails.data
                setSearchedSyllabusText(syllabustext)
            } catch (err) {
                console.error(err)
                throw new Error('Error searching for syllabus text.')
            }
        }

        fetchData()
    }, [courseId]) // Add courseId as a dependency to re-fetch when courseId changes

    useEffect(() => {
        // Function to check if the file exists in S3
        const checkFileExists = async () => {
            const s3 = new AWS.S3()
            const params = {
                Bucket: S3_BUCKET_NAME,
                Key: `${courseId}-syllabus.pdf`,
            }

            try {
                await s3.headObject(params).promise()
                setFileExists(true)
            } catch (error) {
                // Set fileExists to false if the file is not found in S3
                setFileExists(false)
            }
        }
        checkFileExists()
    }, [courseId])

    const handleEditButtonClick = () => {
        if (!enableSyllabusTextEdit) {
            // Save changes if currently in edit mode
            dispatch(
                updateCourseFields(courseId, {
                    syllabustext: searchedSyllabusText,
                })
            )
        }
        setEnableSyllabusTextEdit(!enableSyllabusTextEdit)
    }

    const CHUNK_SIZE = 10 * 1024 * 1024 // 5MB chunk size

    const handleFileChange = async (event) => {
        const file = event.target.files[0]
        try {
            if (file) {
                // Check if the file type is PDF
                if (file.type !== 'application/pdf') {
                    alert(
                        'Only PDF files are supported. Please upload a PDF file.'
                    )
                    return // Do not proceed with further actions
                }

                // Check if the file size is too big

                const reader = new FileReader()
                let offset = 0

                reader.onloadend = async () => {
                    // Process the chunk (you can modify this part to suit your needs)
                    const chunkData = new Uint8Array(reader.result)
                    console.log('Processing chunk:', chunkData)

                    // Continue reading the next chunk
                    offset += CHUNK_SIZE
                    if (offset < file.size) {
                        readChunk()
                    } else {
                        // All chunks have been processed
                        setUploadedSyllabus(reader.result) // Store the uploaded file data
                        setPdfText('') // Clear the previous PDF text
                        setEnableSyllabusEdit(false) // Disable syllabus editing
                        setIframeKey(iframeKey + 1)

                        // Convert ArrayBuffer to a temporary data URL for rendering
                        const dataUrl = `data:application/pdf;base64,${btoa(
                            new Uint8Array(reader.result).reduce(
                                (data, byte) =>
                                    data + String.fromCharCode(byte),
                                ''
                            )
                        )}`
                        setTempPdfUrl(dataUrl)
                    }
                }

                const readChunk = () => {
                    const blob = file.slice(offset, offset + CHUNK_SIZE)
                    reader.readAsArrayBuffer(blob)
                }

                // Start reading the first chunk
                readChunk()

                const maxSize = 2 * 1024 * 1024 // 10 MB (adjust this value as needed)
                if (file.size > maxSize) {
                    throw new Error(
                        'File size is too big! Preview not available. Please click the save button.'
                    )
                }
            }
        } catch (err) {
            alert(err.message)
        }
    }

    const handleSaveToS3 = async () => {
        if (uploadedSyllabus) {
            const s3 = new AWS.S3()
            const params = {
                Bucket: S3_BUCKET_NAME,
                Key: `${courseId}-syllabus.pdf`,
                Body: uploadedSyllabus,
                ACL: 'public-read',
                ContentType: 'application/pdf',
            }

            try {
                setLoading(true)
                // const upload = await s3.createMultipartUpload(params).promise()

                // // Specify the part size in bytes (e.g., 10 MB)
                // const partSize = 10 * 1024 * 1024

                // // Upload each part
                // const parts = await Promise.all(
                //     Array.from(
                //         { length: Math.ceil(file.size / partSize) },
                //         async (_, i) => {
                //             const start = i * partSize
                //             const end = Math.min(start + partSize, file.size)

                //             const partParams = {
                //                 Bucket: S3_BUCKET_NAME,
                //                 Key: `${courseId}-syllabus.pdf`,
                //                 UploadId: upload.UploadId,
                //                 PartNumber: i + 1,
                //                 Body: file.slice(start, end),
                //             }

                //             const part = await s3
                //                 .uploadPart(partParams)
                //                 .promise()
                //             return { PartNumber: i + 1, ETag: part.ETag }
                //         }
                //     )
                // )

                // // Complete the multipart upload
                // await s3
                //     .completeMultipartUpload({
                //         Bucket: S3_BUCKET_NAME,
                //         Key: `${courseId}-syllabus.pdf`,
                //         UploadId: upload.UploadId,
                //         MultipartUpload: { Parts: parts },
                //     })
                //     .promise()
                await s3.upload(params).promise()
                const s3Url = `https://${S3_BUCKET_NAME}.s3.amazonaws.com/${courseId}-syllabus.pdf`

                try {
                    const extractedText = await handleTextExtraction(
                        uploadedSyllabus
                    )
                    setPdfText(extractedText)
                    dispatch(
                        updateCourseFields(courseId, {
                            syllabus: extractedText,
                        })
                    )
                } catch (err) {
                    alert(
                        'File size is too big! Syllabus text cannot be extracted.'
                    )
                    dispatch(
                        updateCourseFields(courseId, {
                            syllabus: ' ',
                            meetingDetails: ' ',
                        })
                    )
                }
                setEnableSyllabusEdit(true)
                setTimeout(() => {
                    window.location.reload()
                }, 200)
                setLoading(false)
            } catch (error) {
                console.error('Error uploading file to S3:', error)
                alert('File could not be uploaded!')
            }
        }
    }

    const handleDeleteFile = async () => {
        if (courseSyllabus) {
            const s3 = new AWS.S3()
            const params = {
                Bucket: S3_BUCKET_NAME,
                Key: `${courseId}-syllabus.pdf`,
            }

            try {
                // Delete the file from S3
                await s3.deleteObject(params).promise()

                // Dispatch an action to update Redux state
                dispatch(updateCourseFields(courseId, { syllabus: ' ' }))
                dispatch(updateCourseFields(courseId, { meetingDetails: ' ' }))

                // Clear local state
                setUploadedSyllabus(null)
                setTempPdfUrl(null)
                setPdfText('')
                setEnableSyllabusEdit(false)

                console.log('File deleted')
                window.location.reload()
            } catch (error) {
                console.error('Error deleting file from S3:', error)
            }
        }
    }

    const handleDeleteButtonClick = () => {
        setOpenDeleteDialog(true) // Open dialog when delete button is clicked
    }

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false) // Close dialog without deleting
    }

    const confirmDeleteFile = () => {
        setOpenDeleteDialog(false) // Close dialog and delete the file
        handleDeleteFile()
    }
    
    const handleTextExtraction = async (pdfData) => {
        // Do not use Uint8Array here, work directly with pdfData as ArrayBuffer
        const pdf = await pdfjs.getDocument(pdfData).promise
        let extractedText = ''

        for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i)
            const textContent = await page.getTextContent()

            let prevY = null
            let lineText = ''

            for (const item of textContent.items) {
                if (prevY === null || Math.abs(item.transform[5] - prevY) < 1) {
                    lineText += item.str
                } else {
                    // Add the lineText as a separate line and reset
                    extractedText += lineText + '\n'
                    lineText = item.str
                }

                prevY = item.transform[5]
            }

            if (lineText.length > 0) {
                extractedText += lineText + '\n'
            }

            extractedText += '\n'
        }
        console.log(extractedText)
        console.log('Test')
        setPdfText(extractedText)
        dispatch(updateCourseFields(courseId, { syllabus: extractedText }))
        console.log('Text:')
        console.log(extractedText)
        try {
            const prompt = `Please extract class meeting details from the following syllabus text:\n${extractedText}\nIf the class meeting details are not found, strictly return a ' ' only and nothing else`
            const result = await model.generateContent(prompt)
            const response = result.response
            const answer = response.text()
            console.log(answer)
            // let response = ''
            // response = await openai.createCompletion({
            //     model: 'text-davinci-003',
            //     prompt,
            //     temperature: 0.6,
            //     max_tokens: 3000,
            // })
            // const meetingDetailsResponse = response.data.choices[0].text.trim()
            const details = answer
                .replace(/^[\r\n]+/, '')
                .replace(/^\.+/, '')
                .replace(/[ "'"]/g, '')

            console.log(details)
            dispatch(
                updateCourseFields(courseId, {
                    meetingDetails: details,
                })
            )
        } catch (error) {
            console.error('Error extracting meeting details:', error)
            alert(
                'File size is too big! Meeting details cannot be automatically extracted from the file.'
            )
            dispatch(
                updateCourseFields(courseId, {
                    meetingDetails: ' ',
                })
            )
        }
    }

    return (
        <div
            style={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '70vh',
            }}
        >
            {enableSyllabusEdit ? (
                <TextField error={!!errorData.syllabus} />
            ) : (
                <>
                    {tempPdfUrl && (
                        <iframe
                            ref={iframeRef}
                            key={iframeKey}
                            src={tempPdfUrl}
                            width="100%"
                            height="500"
                        ></iframe>
                    )}
                    {fileExists && courseSyllabus && !uploadedSyllabus && (
                        <iframe
                            ref={iframeRef}
                            key={iframeKey}
                            src={`https://${S3_BUCKET_NAME}.s3.amazonaws.com/${courseId}-syllabus.pdf`}
                            width="100%"
                            height="500"
                        ></iframe>
                    )}
                </>
            )}
            {role === 'Instructor' && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingTop: '10px',
                    }}
                >
                    {/* Upload button */}

                    <Button
                        variant="contained"
                        color="primary"
                        component="label"
                        htmlFor="fileInput"
                        style={{
                            marginRight: '1rem',
                            cursor: 'pointer',
                            marginBottom: '30px',
                        }}
                    >
                        Choose File
                        <input
                            type="file"
                            id="fileInput"
                            accept=".pdf,.doc,.docx"
                            onChange={handleFileChange}
                            style={{
                                display: 'none',
                            }}
                        />
                    </Button>

                    {/* Save button */}
                    {uploadedSyllabus && !enableSyllabusEdit && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveToS3}
                            style={{
                                marginBottom: '30px',
                            }}
                        >
                            Save
                        </Button>
                    )}
                    {loading && (
                        <Paper
                            elevation={1}
                            sx={{
                                p: 1,
                                display: 'flex',
                                alignItems: 'center',
                                maxHeight: '50px',
                                marginLeft: '14px', // Add left margin
                                marginTop: '-5px',
                            }}
                        >
                            <CircularProgress
                                size={24}
                                sx={{ marginRight: 2 }}
                            />
                            <Typography variant="body2">
                                Saving the file...
                            </Typography>
                        </Paper>
                    )}
                    {courseSyllabus && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDeleteButtonClick}
                            style={{
                                marginLeft: 'auto',
                                cursor: 'pointer',
                                marginRight: '5px',
                                marginBottom: '30px',
                            }}
                        >
                            Delete File
                        </Button>
                    )}
                </div>
            )}

            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
            >
                <DialogTitle>{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you want to delete the uploaded file?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        No
                    </Button>
                    <Button onClick={confirmDeleteFile} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            {role != 'Instructor' && <br></br>}

            {enableSyllabusTextEdit ? (
                <TextField
                    error={!!errorData.syllabus}
                    fullWidth
                    helperText={`${searchedSyllabusText.length}/512 characters`}
                    id="description-edit"
                    inputProps={{
                        style: {
                            padding: 0,
                            minHeight: '23.5%',
                            border: '1px solid black',
                            borderRadius: '4px',
                        },
                    }}
                    multiline
                    onBlur={handleEditButtonClick}
                    onChange={(e) => setSearchedSyllabusText(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    rows={4}
                    value={searchedSyllabusText}
                    variant="filled"
                />
            ) : (
                <Typography
                    id="description"
                    onClick={(e) => e.stopPropagation()}
                    style={{
                        whiteSpace: 'pre-line',
                        border: '1px solid black',
                        borderRadius: '4px',
                    }}
                    sx={{
                        flexGrow: 20,
                        display: '-webkit-box',
                        overflow: 'scroll',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 4,
                        background: 'rgb(247, 247, 247)',
                        minHeight: '7.8em',
                        padding: '25px 12px 8px',
                        border: errorData.description
                            ? '1px red solid'
                            : 'none',
                    }}
                    variant="body1"
                >
                    {addAnchorTagsForLinksInText(searchedSyllabusText)}
                </Typography>
            )}
            {role === 'Instructor' && (
                <button
                    className="btn btn-primary m-2"
                    onClick={handleEditButtonClick}
                    style={{ width: 'fit-content' }}
                    type="button"
                >
                    {enableSyllabusTextEdit ? (
                        <>
                            <EditOffIcon fontSize="small" />{' '}
                        </>
                    ) : (
                        <>
                            <EditIcon fontSize="small" /> Edit
                        </>
                    )}
                </button>
            )}
        </div>
    )
}

Syllabus.propTypes = {
    courseId: PropTypes.string.isRequired,
    courseSyllabus: PropTypes.string.isRequired,
}

/* eslint-disable */
