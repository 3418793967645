import axios from 'axios'
import {
    GET_USER_LINKS_FAILURE,
    GET_USER_LINKS_REQUEST,
    GET_USER_LINKS_SUCCESS,
} from './userLinkTypes'

export const getUserLinkRequest = () => ({
    type: GET_USER_LINKS_REQUEST,
})

export const getUserLinkSuccess = (user) => ({
    type: GET_USER_LINKS_SUCCESS,
    payload: user,
})

export const getUserLinkFailure = (errorMsg) => ({
    type: GET_USER_LINKS_FAILURE,
    payload: errorMsg,
})

export const getUserLinks = () => (dispatch) => {
    dispatch(getUserLinkRequest)

    axios
        .get(`${process.env.REACT_APP_BASE_URL}/user`)
        .then((response) => {
            console.log(response.data)

            const user = response.data
            if (!user || user.id === undefined) {
                dispatch(getUserLinkFailure())
            } else {
                console.log(user)
                dispatch(getUserLinkSuccess(user))
            }
        })
        .catch((err) => {
            dispatch(
                getUserLinkFailure(
                    (err.response && err.response.data) ||
                        'Error fetching users'
                )
            )
        })
}
