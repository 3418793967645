import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TextField, Autocomplete, Button } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { matchSorter } from 'match-sorter'
import Search from '@mui/icons-material/Search'
import JobsTable from './JobsTable'
import { jobSearch } from '../../redux'

function JobSearch({ data, search }) {
    const dispatch = useDispatch()
    const jobsData = useSelector((state) => state.jobData)

    const filterOptions = (options, { inputValue }) =>
        matchSorter(options, inputValue, { keys: ['name'] })

    const [jobLocation, setJobLocation] = useState('')
    const [jobCountry, setJobCountry] = useState('us')
    const [key, setKey] = useState(0)

    const handleOnClick = () => {
        if (jobLocation !== '') {
            dispatch(jobSearch(search, jobLocation, jobCountry))
        }
    }
    useEffect(() => {
        setKey((prevKey) => prevKey + 1)
    }, [jobsData])

    const onCountryChange = (event, value) => {
        setJobCountry(value.code)
    }

    const onLocationChange = (updatedLocation) => {
        setJobLocation(updatedLocation)
    }

    return (
        <div style={{ width: '100%', display: 'block', textAlign: 'center' }}>
            <div style={{ display: 'flex' }}>
                <div
                    style={{
                        display: 'inline-block',
                        maxWidth: '500px',
                        width: '100%',
                        marginLeft: '185px',
                        marginRight: '10px',
                    }}
                >
                    <TextField
                        style={{ marginBottom: '30px' }}
                        // disabled={achievingTargetGrade}
                        // error={!!errorData.target}
                        fullWidth
                        // helperText={errorData.target}
                        id="jobLocation"
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        label="Location"
                        placeholder="City, State or Zip Code"
                        onChange={(e) => onLocationChange(e.target.value)}
                        onWheel={(e) => e.target.blur()}
                        type="text"
                        value={jobLocation}
                    />
                </div>
                <div style={{ display: 'inline-block', width: '185px' }}>
                    <Autocomplete
                        autoHighlight
                        filterOptions={filterOptions}
                        getOptionLabel={(option) => option.name}
                        id="combo-box-demo"
                        onChange={onCountryChange}
                        options={data}
                        defaultValue={data[data.length - 1]}
                        renderInput={(params) => (
                            /* eslint-disable react/jsx-props-no-spreading */
                            <TextField
                                {...params}
                                label="Country"
                                value={jobCountry}
                            />
                            /* eslint-enable react/jsx-props-no-spreading */
                        )}
                    />
                </div>
                <div style={{ display: 'inline-block', paddingTop: '5px' }}>
                    <Button
                        size="md"
                        onClick={handleOnClick}
                        style={{ height: '50px' }}
                    >
                        <Search />
                    </Button>
                </div>
            </div>

            <div style={{ display: 'inline-block' }}>
                {jobsData !== null && jobsData.jobs.length !== 0 ? (
                    <div>
                        <h3 style={{ textAlign: 'center' }}>
                            Current Job Openings
                        </h3>
                        <JobsTable data={jobsData.jobs.results} key={key} />
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}

JobSearch.propTypes = {
    // eslint-disable-next-line no-undef
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    search: PropTypes.string,
}

JobSearch.defaultProps = {
    search: '',
}

export default JobSearch
