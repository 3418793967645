/* eslint-disable */

import React, { useState, useEffect } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import {
    Grid,
    TextField,
    Button,
    Typography,
    Divider,
    List,
    ListItem,
    Stack,
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { updateCanvasAccessData } from '../../redux'

// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//         padding: theme.spacing(2),
//         marginBottom: 500,
//     },
//     videoColumn: {
//         flexBasis: '70%',
//         padding: theme.spacing(2),
//     },
//     formColumn: {
//         flexBasis: '20%',
//         padding: theme.spacing(2),
//     },
// }))

const CanvasRequestAccess = (userId) => {
    // const classes = useStyles()
    const [accessToken, setAccessToken] = useState('')
    const [isTokenValid, setIsTokenValid] = useState(true)
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const canvasAccessData = useSelector((state) => state.canvasAccessData)

    const handleTokenChange = (event) => {
        const token = event.target.value
        setAccessToken(token)
        // console.log(token.trim() !== '')
        // Basic validation: non-empty token is considered valid
    }

    const handleSubmit = () => {
        setIsTokenValid(accessToken.trim() !== '')
        if (isTokenValid) {
            dispatch(updateCanvasAccessData(accessToken, userData.user.id))
            if (canvasAccessData.fetchSuccess) {
                // console.log(canvasAccessData)
                window.location.href = '/courses'
                setAccessToken('')
            }
        } else {
            console.error('Invalid Access Token. Please enter a valid token.')
        }
    }
    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }
    if (userData.user.role !== 'Instructor') {
        return (
            <div className="container main-container">
                <h4 className="alert alert-danger mt-4">
                    {
                        'To view this page, the user must log in as an instructor.'
                    }
                </h4>
            </div>
        )
    }
    return (
        <Stack mt={'3rem'}>
            <Typography variant="h4" fontWeight={'bold'} paddingLeft={'8rem'}>
                Request Canvas Access
            </Typography>
            <Divider />
            <br></br>
            <br></br>
            <br></br>
            <Stack padding={6} display={'flex'}>
                <Grid container spacing={2}>
                    {/* Video Column */}
                    <Grid width={'70%'} padding={2}>
                        {/* Your video component */}
                        <iframe
                            title="Video"
                            width="100%"
                            height="315"
                            src="https://www.youtube.com/embed/cZ5cn8stjM0"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>

                        {/* Bottom text content */}
                        <Typography variant="body1" fontWeight={'bold'}>
                            Instructions in text
                        </Typography>
                        <List
                            sx={{
                                listStyle: 'decimal',
                                pl: 4,
                            }}
                        >
                            <ListItem sx={{ display: 'list-item' }}>
                                Log into Canvas and, on the left, click Account.
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                In the resulting Account menu, click Settings.
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                To manually add an access token, click the Add
                                New Access Token button.
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                Enter a description for your access token in the
                                Purpose field. You can also select an expiration
                                date by clicking the Calendar icon . To generate
                                a token with no expiration, leave the Expires
                                field empty.
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                To generate a new access token, click the
                                Generate Token button.
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                Verify if the access token is generated.
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                Copy the access token and paste in the text box
                                shown on the right side and click submit.
                            </ListItem>
                        </List>
                    </Grid>
                    <Divider orientation="vertical" flexItem />

                    {/* Form Column */}
                    <Grid item>
                        <Typography variant="h6" gutterBottom>
                            Enter Access Token
                        </Typography>

                        {/* Input field */}
                        <TextField
                            label="Access Token"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={accessToken}
                            onChange={handleTokenChange}
                            error={!isTokenValid}
                            helperText={
                                !isTokenValid
                                    ? 'Access Token cannot be empty'
                                    : ''
                            }
                        />

                        {/* Button */}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Stack>
        </Stack>
    )
}

export default CanvasRequestAccess
