/* eslint-disable */
import { React } from 'react'
import './courses/CoursesHome/Tabs/styletab.css'
import Avatar from '@mui/material/Avatar';
import StudentDetailsModal from './courses/CoursesHome/Tabs/StudentDetailsModal'
import RequestModal from './RequestModal';
import StudentMenu from './courses/CoursesHome/Tabs/StudentMenu'
// import Stack from '@mui/material/Stack';
import { useSelector, useDispatch } from 'react-redux'
import { deepOrange, deepPurple } from '@mui/material/colors';
import Button from '@mui/material/Button';
import { upgradeRequest, deleteRequest} from '../redux/Admin/AdminActions';
import LandingPage from './LandingPage';
import { getRequests } from '../redux/Admin/AdminActions';
import AddStudentModal from './courses/CoursesHome/Tabs/AddStudentModal';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { sendData } from '../redux/search/searchActions';
import { enableUpgrade } from '../redux';

const upgradeClick = async (id, dispatch) => {
    dispatch(upgradeRequest(id));
    window.location.reload();
}

const deleteClick = async (id, dispatch) => {
    dispatch(deleteRequest(id));
    dispatch(enableUpgrade(id));
    window.location.reload();
}

export default function Requests({ users }) {
    // console.log("The length is" + users.length);
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData);
    const user = userData && userData.user;
    const people = users;
    // console.log("The length is " + people.length);
    return (
        <section
            className='sp'
            id=''
            style={{
                textAlign: 'left',
                // display: 'flex',
                flexDirection: 'column',
                minHeight: '70vh',
                overflow: scroll,
            }}
        >
            
            <div>
                <table>
                    <tr>
                        <th></th>
                        <th
                            style={{ width: '70%' }}>
                            Name
                        </th>
                        {/* <th>
            Role
          </th> */}
                        <th>
                            {/* <AddStudentModal/> */}
                        </th>
                        <th>

                        </th>
                    </tr>

                    {people ? people.map((i) => {
                        return (
                            <>
                                <tr>
                                    {i.role === 'Instructor' ?
                                        <td>
                                            <Avatar sx={{ bgcolor: deepOrange[500] }}>{i.name[0]}</Avatar>
                                        </td> :
                                        <td>
                                            <Avatar sx={{ bgcolor: deepPurple[500] }}>{i.name[0]}</Avatar>
                                        </td>
                                    }

                                    <td>
                                        <div key={i.id} className="item">
                                            <RequestModal student={i} />
                                            {/* {i.name} */}
                                        </div>
                                    </td>
                                    <td>
                                        {/* <StudentMenu/> */}
                                        <Button variant="contained" style={{ backgroundColor: "green" }} onClick={() => upgradeClick(i.id, dispatch)}>Accept</Button>
                                    </td>
                                    <td>
                                        {/* <StudentMenu/> */}
                                        <Button variant="contained" style={{ backgroundColor: "red" }} onClick={() => deleteClick(i.id, dispatch)}>Reject</Button>
                                    </td>
                                    {/* // Functionality not yet implemented
                                    <td>
                                        <StudentMenu />
                                    </td> */}
                                </tr>
                            </>
                        );
                    }) : null}
                </table>
            </div>
        </section >
    )
}

/* eslint-disable */