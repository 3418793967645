export const defaultCategories = [
    {
        name: 'Assignments',
        categoryType: 'STANDARD',
        weight: 25,
        items: [
            {
                name: 'Assignment 1',
                description: '',
                weight: 6.25,
                knownScores: false,
                grade: 0,
                maximumPoints: 25,
            },
            {
                name: 'Assignment 2',
                description: '',
                weight: 6.25,
                knownScores: false,
                grade: 0,
                maximumPoints: 25,
            },
            {
                name: 'Assignment 3',
                description: '',
                weight: 6.25,
                knownScores: false,
                grade: 0,
                maximumPoints: 25,
            },
            {
                name: 'Assignment 4',
                description: '',
                weight: 6.25,
                knownScores: false,
                grade: 0,
                maximumPoints: 25,
            },
        ],
    },
    {
        name: 'Exams',
        categoryType: 'STANDARD',
        weight: 75,
        items: [
            {
                name: 'Exam 1',
                description: '',
                weight: 25,
                knownScores: false,
                grade: 0,
                maximumPoints: 100,
            },
            {
                name: 'Exam 2',
                description: '',
                weight: 25,
                knownScores: false,
                grade: 0,
                maximumPoints: 100,
            },
            {
                name: 'Final Exam',
                description: '',
                weight: 25,
                knownScores: false,
                grade: 0,
                maximumPoints: 100,
            },
        ],
    },
]

export const defaultCategoryItem = {
    name: 'Item Name',
    description: '',
    knownScores: false,
    grade: 0,
    maximumPoints: 0,
    weight: 0,
}

export const defaultCategory = {
    name: '######',
    categoryType: 'STANDARD',
    weight: 0,
    items: [{ ...defaultCategoryItem }],
}

export const hasCategoryError = (allCategoriesErrors) => {
    for (let i = 0; i < allCategoriesErrors.length; i += 1) {
        const categoryErrors = allCategoriesErrors[i]
        if (categoryErrors) {
            const errorKeys = Object.keys(categoryErrors)
            for (let j = 0; j < errorKeys.length; j += 1) {
                const errorKey = errorKeys[j]
                if (errorKey === 'items') {
                    for (let k = 0; k < categoryErrors.items.length; k += 1) {
                        if (categoryErrors.items[k]) {
                            const errorItemKeys = Object.keys(
                                categoryErrors.items[k]
                            )
                            for (let l = 0; l < errorItemKeys.length; l += 1) {
                                if (categoryErrors.items[k][errorItemKeys[l]]) {
                                    return true
                                }
                            }
                        }
                    }
                } else if (categoryErrors[errorKey]) {
                    return true
                }
            }
        }
    }
    return false
}
