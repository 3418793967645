/* eslint-disable */

import {
    ADD_QUIZ_CATEGORY_REQUEST,
    ADD_QUIZ_CATEGORY_SUCCESS,
    ADD_QUIZ_CATEGORY_FAILURE,
    EDIT_QUIZ_CATEGORY_REQUEST,
    EDIT_QUIZ_CATEGORY_SUCCESS,
    EDIT_QUIZ_CATEGORY_FAILURE,
    DELETE_QUIZ_CATEGORY_REQUEST,
    DELETE_QUIZ_CATEGORY_SUCCESS,
    DELETE_QUIZ_CATEGORY_FAILURE,
    GET_QUIZ_CATEGORIES_REQUEST,
    GET_QUIZ_CATEGORIES_SUCCESS,
    GET_QUIZ_CATEGORIES_FAILURE,
} from './categoryTypes'

const initialState = {
    loading: false,
    loadingMessage: '',
    fetchSuccess: false,
    categoryData: [],
    message: '',
    error: '',
}

const categoryReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case ADD_QUIZ_CATEGORY_REQUEST:
        case EDIT_QUIZ_CATEGORY_REQUEST:
        case DELETE_QUIZ_CATEGORY_REQUEST:
        case GET_QUIZ_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true,
                loadingMessage: 'Processing...',
                fetchSuccess: false,
                categoryData: [],
                message: '',
                error: '',
            }

        case ADD_QUIZ_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                fetchSuccess: true,
                categoryData: [...state.categoryData, action.payload],
                message: '',
                error: '',
            }

        case EDIT_QUIZ_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                fetchSuccess: true,
                categoryData: state.categoryData.map((category) =>
                    category._id === action.payload._id
                        ? action.payload
                        : category
                ),
                message: '',
                error: '',
            }

        case DELETE_QUIZ_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                fetchSuccess: true,
                categoryData: state.categoryData.filter(
                    (category) => category._id !== action.payload
                ),
                message: '',
                error: '',
            }

        case GET_QUIZ_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                fetchSuccess: true,
                categoryData: action.payload,
                message: '',
                error: '',
            }

        case ADD_QUIZ_CATEGORY_FAILURE:
        case EDIT_QUIZ_CATEGORY_FAILURE:
        case DELETE_QUIZ_CATEGORY_FAILURE:
        case GET_QUIZ_CATEGORIES_FAILURE:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                fetchSuccess: false,
                categoryData: [],
                message: '',
                error: action.payload,
            }

        default:
            return state
    }
}

export default categoryReducer
