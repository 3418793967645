/* eslint-disable */

import {
    VIRTUAL_TA_ASK_QUESTION_REQUEST,
    VIRTUAL_TA_ASK_QUESTION_SUCCESS,
    VIRTUAL_TA_ASK_QUESTION_FAILURE,
    VIRTUAL_TA_GET_ALL_QUESTIONS_REQUEST,
    VIRTUAL_TA_GET_ALL_QUESTIONS_SUCCESS,
    VIRTUAL_TA_GET_ALL_QUESTIONS_FAILURE,
    SET_QUESTION,
    SET_CONVERSATION_HISTORY,
    SET_WEAK_CONCEPTS, // Add the new action type
} from './virtualTAActionTypes'

const initialState = {
    askingQuestion: false,
    askingQuestionError: null,
    questions: [],
    conversationHistory: [],
    fetchingQuestions: false,
    fetchingQuestionsError: null,
    weakConcepts: [], // Initial empty array for weak concepts
}

const virtualTAReducer = (state = initialState, action) => {
    switch (action.type) {
        case VIRTUAL_TA_ASK_QUESTION_REQUEST:
            return {
                ...state,
                askingQuestion: true,
                askingQuestionError: null,
            }
        case VIRTUAL_TA_ASK_QUESTION_SUCCESS:
            return {
                ...state,
                askingQuestion: false,
                askingQuestionError: null,
                questions: [...state.questions, action.payload],
                conversationHistory: [
                    ...state.conversationHistory,
                    action.payload,
                ],
            }
        case VIRTUAL_TA_ASK_QUESTION_FAILURE:
            return {
                ...state,
                askingQuestion: false,
                askingQuestionError: action.payload,
            }
        case VIRTUAL_TA_GET_ALL_QUESTIONS_REQUEST:
            return {
                ...state,
                fetchingQuestions: true,
                fetchingQuestionsError: null,
            }
        case VIRTUAL_TA_GET_ALL_QUESTIONS_SUCCESS:
            return {
                ...state,
                fetchingQuestions: false,
                fetchingQuestionsError: null,
                questions: action.payload,
            }
        case VIRTUAL_TA_GET_ALL_QUESTIONS_FAILURE:
            return {
                ...state,
                fetchingQuestions: false,
                fetchingQuestionsError: action.payload,
            }
        case SET_QUESTION:
            return {
                ...state,
                questions: state.questions.map((question) =>
                    question.id === action.payload.id
                        ? { ...question, question: action.payload.question }
                        : question
                ),
            }
        case SET_CONVERSATION_HISTORY:
            return {
                ...state,
                conversationHistory: action.payload,
            }
        case SET_WEAK_CONCEPTS:
            return {
                ...state,
                weakConcepts: action.payload,
            }
        default:
            return state
    }
}

export default virtualTAReducer
