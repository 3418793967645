import {
    CANVAS_ASSIGNMENT_GROUP_META_REQUEST,
    CANVAS_ASSIGNMENT_GROUP_META_SUCCESS,
    CANVAS_ASSIGNMENT_GROUP_META_FAILURE,
} from './canvasAssignmentGroupTypes'

const initialState = {
    loading: false,
    loadingMessage: '',
    fetchSuccess: false,
    assignmentGroupData: [],
    message: '',
    error: '',
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CANVAS_ASSIGNMENT_GROUP_META_REQUEST:
            return {
                ...state,
                loading: true,
                loadingMessage: 'Fetching assignment group data...',
                fetchSuccess: false,
                assignmentGroupData: {},
                message: '',
                error: '',
            }
        case CANVAS_ASSIGNMENT_GROUP_META_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                fetchSuccess: true,
                assignmentGroupData: action.payload,
                message: '',
                error: '',
            }
        case CANVAS_ASSIGNMENT_GROUP_META_FAILURE:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                fetchSuccess: false,
                assignmentGroupData: {},
                message: '',
                error: action.payload,
            }

        default:
            return state
    }
}

export default reducer
