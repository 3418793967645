import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import TopLoader from './commons/TopLoader'
import ContactUsForm from './forms/ContactUsForm'

function ContactUs() {
    const contactUsData = useSelector((state) => state.contactUsData)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            {contactUsData.loading ? (
                <TopLoader message={contactUsData.loadingMessage} />
            ) : null}
            <div className="container main-container mt-4 mb-5">
                <div className="card">
                    <div className="card-body">
                        <ContactUsForm />
                    </div>
                </div>
                {contactUsData.error ? (
                    <h4 className="alert alert-danger mt-4">
                        {contactUsData.error}
                    </h4>
                ) : null}
                {contactUsData.message ? (
                    <h4 className="alert alert-success mt-4">
                        {contactUsData.message}
                    </h4>
                ) : null}
            </div>
        </>
    )
}

export default ContactUs
