import {
    CANVAS_META_FAILURE,
    CANVAS_META_REQUEST,
    CANVAS_META_SUCCESS,
} from './canvasCategoryTypes'

const initialState = {
    loading: false,
    loadingMessage: '',
    fetchSuccess: false,
    canvasData: [],
    message: '',
    error: '',
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CANVAS_META_REQUEST:
            return {
                ...state,
                loading: true,
                loadingMessage: 'Fetching canvas data...',
                fetchSuccess: false,
                canvasData: {},
                message: '',
                error: '',
            }
        case CANVAS_META_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                fetchSuccess: true,
                canvasData: action.payload,
                message: '',
                error: '',
            }
        case CANVAS_META_FAILURE:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                fetchSuccess: false,
                canvasData: {},
                message: '',
                error: action.payload,
            }
        default:
            return state
    }
}

export default reducer
