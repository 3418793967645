import {
    GET_USER_LINKS_FAILURE,
    GET_USER_LINKS_REQUEST,
    GET_USER_LINKS_SUCCESS,
} from './userLinkTypes'

const initialState = {
    loading: false,
    loadingMessage: '',
    linkData: null,
    message: '',
    error: '',
    registered: false,
    userFetchCompleted: false, // Fetch user done, irrespective of success or failure
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_USER_LINKS_REQUEST:
            return {
                ...state,
                loading: true,
                loadingMessage: 'Fetching user details...',
                error: '',
                userFetchCompleted: false,
            }
        case GET_USER_LINKS_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                linkData: action.payload,
                error: '',
                userFetchCompleted: true,
            }
        case GET_USER_LINKS_FAILURE:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                message: '',
                userFetchCompleted: true,
            }

        default:
            return state
    }
}

export default reducer
