import {
    CLEAR_ERROR_FOR_COURSE_CATEGORY_INDEX,
    CLEAR_ERROR_FOR_COURSE_CATEGORY_ITEM_INDEX,
    CLEAR_ERROR_FOR_KEY,
    SET_ERROR_FOR_COURSE_CATEGORY_ITEM_KEY_AT_INDEX,
    SET_ERROR_FOR_COURSE_CATEGORY_KEY_AT_INDEX,
    SET_ERROR_FOR_KEY,
} from './errorTypes'

export const setErrorForKey = (key, errorMessage) => ({
    type: SET_ERROR_FOR_KEY,
    payload: [key, errorMessage],
})

export const clearErrorForKey = (key) => ({
    type: CLEAR_ERROR_FOR_KEY,
    payload: key,
})

export const setErrorForCourseCategoryKeyAtIndex = (
    categoryIndex,
    key,
    errorMessage
) => ({
    type: SET_ERROR_FOR_COURSE_CATEGORY_KEY_AT_INDEX,
    payload: [categoryIndex, key, errorMessage],
})

export const clearErrorForCourseCategoryIndex = (categoryIndex) => ({
    type: CLEAR_ERROR_FOR_COURSE_CATEGORY_INDEX,
    payload: categoryIndex,
})

export const setErrorForCourseCategoryItemKeyAtIndex = (
    categoryIndex,
    itemIndex,
    key,
    errorMessage
) => ({
    type: SET_ERROR_FOR_COURSE_CATEGORY_ITEM_KEY_AT_INDEX,
    payload: [categoryIndex, itemIndex, key, errorMessage],
})

export const clearErrorForCourseCategoryItemIndex = (
    categoryIndex,
    itemIndex
) => ({
    type: CLEAR_ERROR_FOR_COURSE_CATEGORY_ITEM_INDEX,
    payload: [categoryIndex, itemIndex],
})
