/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, Fab, Paper, Skeleton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import CreateCourse from './CreateCourse';
import globalStyles from '../../css/Page.module.css';
import styles from './Courses.module.css';

const Courses = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isCreateCourseModalOpen, setIsCreateCourseModalOpen] = useState(false);
	const [courses, setCourses] = useState([{},{},{},{},{},{},{},{},{}]);
	const [isBackdropOpen, setIsBackdropOpen] = useState(false);
	
	const openCreateCourseModal = () => {
		setIsCreateCourseModalOpen(true);
	}

	const openBackdrop = () => {
		setIsBackdropOpen(true);
	}

	const closeBackdrop = () => {
		setIsBackdropOpen(false);
	}

	const getCourses = () => {
		// GET API Call
		setTimeout(() => {
			const res = [
				{id: 1, isPublished: true, name: "Web Development"},
				{id: 2, isPublished: true, name: "DBMS"},
				{id: 3, isPublished: true, name: "DSA"},
				{id: 4, isPublished: true, name: "Machine Learning"},
				{id: 5, isPublished: true, name: "Computer Architecture"},
				{id: 6, isPublished: true, name: "OOPs"},
				{id: 7, isPublished: true, name: "Computer Organisation"},
				{id: 8, isPublished: true, name: "Internet Technology"},
				{id: 9, isPublished: true, name: "Computer Networks"},
				{id: 10, isPublished: true, name: "Mathematics"},
				{id: 11, isPublished: true, name: "Demo"},
			];
			setCourses(res);
			setIsLoading(false);
		}, 5000);
	}

	const createCourse = (name, startDate, endDate, isPublished) => {
		// POST API Call
		openBackdrop();
		setTimeout(() => {
			setCourses(prevCourses => (
				[...prevCourses, {id: prevCourses.length + 1, name, startDate, endDate, isPublished}]
			));
			closeBackdrop();
		}, 5000);
	}

	useEffect(() => {
		// Get Courses from server
		getCourses();
		
	}, []);

	useEffect(() => {console.log(courses)}, [courses]);

	return (
		<div className={`${globalStyles['main-container']} ${styles['main-container']}`}>
			<div className={`${globalStyles['main-heading']}`}>
				Courses
				<Tooltip title="Create Course">
					<Fab aria-label="add" color="primary" onClick={openCreateCourseModal} disabled={isLoading}>
							<AddIcon />
					</Fab>
				</Tooltip>
			</div>
			<Divider />
			<div className={`${styles['courses-list-container']}`}>
				{
					courses.map((course) => isLoading ? (
						<Skeleton height={'125px'} className='skeleton-card' variant='rounded' />
					) : (
						<Link href={`/test/course/${course.id}`} underline="none" rel="noerror noreffer">
							<div className={`${styles['course']}`}>{course.name}</div>
						</Link>
					))
				}
			</div>
			<CreateCourse 
				open={isCreateCourseModalOpen} 
				setOpen={setIsCreateCourseModalOpen}
				createCourse={createCourse} 
			/>
			<Backdrop
				sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
				open={isBackdropOpen}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	)
}

export default Courses