import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import GaugeChart from 'react-gauge-chart'
import { Range } from 'rc-slider'
import 'rc-slider/assets/index.css'
import { updateMeterDivisions } from '../../redux'

function Meters({
    projectedPercent,
    targetPercent = 0.85,
    divisions,
    animate = true,
    showRangeSelector = true,
    showRangeValues = true,
    meter1Label = 'Predicted',
    meter2Label = 'Target',
}) {
    const dispatch = useDispatch()
    const rangeValues = [0, ...divisions, 100]
    const arcsLength = [divisions[0]]
    for (let i = 1; i < divisions.length; i += 1) {
        arcsLength.push(divisions[i] - divisions[i - 1])
    }
    arcsLength.push(100 - divisions[divisions.length - 1])
    const updateRangeValues = (updatedRangeValues) => {
        const updatedDivisions = updatedRangeValues.slice(1).slice(0, -1)
        dispatch(updateMeterDivisions(updatedDivisions))
    }
    return (
        <>
            <div className="row meter-container">
                <div>
                    <GaugeChart
                        animate={animate}
                        arcPadding={0.02}
                        arcsLength={arcsLength}
                        colors={['#fc1e14', '#f7c82f', '#24ab24']}
                        cornerRadius={3}
                        id="gauge-chart1"
                        needleBaseColor="#aaa"
                        needleColor="#aaa"
                        percent={projectedPercent}
                        style={{ height: 150, fontWeight: 'bold' }}
                        textColor="#484848"
                    />
                    <h4 style={{ textAlign: 'center', marginTop: '-35px' }}>
                        {meter1Label}
                    </h4>
                </div>
                <div>
                    <GaugeChart
                        animate={animate}
                        arcPadding={0.02}
                        arcsLength={arcsLength}
                        colors={['#fc1e14', '#f7c82f', '#24ab24']}
                        cornerRadius={3}
                        id="gauge-chart1"
                        needleBaseColor="#aaa"
                        needleColor="#aaa"
                        percent={targetPercent}
                        style={{ height: 150, fontWeight: 'bold' }}
                        textColor="#484848"
                    />
                    <h4 style={{ textAlign: 'center', marginTop: '-35px' }}>
                        {meter2Label}
                    </h4>
                </div>
            </div>
            <div
                className="row"
                hidden={!showRangeSelector}
                style={{ width: '50%', margin: '0 auto' }}
            >
                <Range
                    allowCross={false}
                    count={3}
                    max={100}
                    min={0}
                    onChange={updateRangeValues}
                    trackStyle={[
                        {
                            backgroundColor: '#fc1e14',
                        },
                        {
                            backgroundColor: '#f7c82f',
                        },
                        {
                            backgroundColor: '#24ab24',
                        },
                    ]}
                    value={rangeValues}
                />
            </div>
            <div
                className="row"
                hidden={!showRangeValues}
                style={{
                    width: '50%',
                    margin: '0 auto',
                    justifyContent: 'space-around',
                }}
            >
                <span className="m-2 meter-division-value bg-red white-text">
                    <b>
                        {`${rangeValues[0]}-${rangeValues[1]}`}
                        <br />
                        Needs Attention
                    </b>
                </span>
                <span className="m-2 meter-division-value bg-yellow">
                    <b>
                        {`${rangeValues[1]}-${rangeValues[2]}`}
                        <br />
                        Warning
                    </b>
                </span>
                <span className="m-2 meter-division-value bg-green">
                    <b>
                        {`${rangeValues[2]}-${rangeValues[3]}`}
                        <br />
                        On Track
                    </b>
                </span>
            </div>
        </>
    )
}

Meters.propTypes = {
    projectedPercent: PropTypes.number.isRequired,
    targetPercent: PropTypes.number.isRequired,
    divisions: PropTypes.arrayOf(PropTypes.number).isRequired,
    animate: PropTypes.bool,
    showRangeSelector: PropTypes.bool,
    showRangeValues: PropTypes.bool,
    meter1Label: PropTypes.string.isRequired,
    meter2Label: PropTypes.string.isRequired,
}

Meters.defaultProps = {
    animate: true,
    showRangeSelector: true,
    showRangeValues: true,
}

export default Meters
