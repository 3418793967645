export const CANVAS_QUIZ_META_REQUEST = 'CANVAS_QUIZ_META_REQUEST'
export const CANVAS_QUIZ_META_SUCCESS = 'CANVAS_QUIZ_META_SUCCESS'
export const CANVAS_QUIZ_META_FAILURE = 'CANVAS_QUIZ_META_FAILURE'

// export const CANVAS_MODULE_ITEMS_META_REQUEST =
//     'CANVAS_MODULE_ITEMS_META_REQUEST'
// export const CANVAS_MODULE_ITEMS_META_SUCCESS =
//     'CANVAS_MODULE_ITEMS_META_SUCCESS'
// export const CANVAS_MODULE_ITEMS_META_FAILURE =
//     'CANVAS_MODULE_ITEMS_META_FAILURE'
