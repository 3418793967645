import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import TopLoader from './commons/TopLoader'
import GenerateResetPasswordForm from './forms/GenerateResetPasswordForm'

function GenerateResetPasswordEmail() {
    const userData = useSelector((state) => state.userData)
    const resetPasswordData = useSelector((state) => state.resetPasswordData)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    if (
        userData.userFetchCompleted &&
        userData.user &&
        userData.user.id !== undefined
    ) {
        return <Redirect to="/" />
    }
    let loadingMessage = null
    if (userData.loading) {
        loadingMessage = userData.loadingMessage
    } else if (resetPasswordData.loading) {
        loadingMessage = resetPasswordData.loadingMessage
    }
    return (
        <>
            {loadingMessage && <TopLoader message={loadingMessage} />}

            <div className="container main-container mt-4 mb-5">
                <div className="card">
                    <div className="card-body">
                        <GenerateResetPasswordForm />
                    </div>
                </div>
                {resetPasswordData.error ? (
                    <h4 className="alert alert-danger mt-4">
                        {resetPasswordData.error}
                    </h4>
                ) : null}
                {resetPasswordData.message ? (
                    <h4 className="alert alert-success mt-4">
                        {resetPasswordData.message}
                    </h4>
                ) : null}
            </div>
        </>
    )
}

export default GenerateResetPasswordEmail
